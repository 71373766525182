import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Row, Col, OverlayTrigger, Tooltip, Button, Form } from 'react-bootstrap';
import AdminApi from '../../api/adminApi'
import { useTranslation } from 'react-i18next';
import CustomDataTable from '../../components/customDataTable';
import Helpers from "../../components/helpers";
import { Link, useLocation, useParams } from 'react-router-dom';
import AlertModal from '../../components/AlertModel';
import Loader from "../../components/loader";
import { AdminQrModal } from "../../components/adminQrModal";

const UnpaidUsers = () => {
    const [servicesAndPaymentsData, setServicesAndPaymentsData] = useState([])
    const [searchKey, setSearchKey] = useState(""); //for searching
    const [show1, setShow1] = useState(false)
    const [userValues, setUserValues] = useState([])
    const [dataLoader, setDataLoader] = useState(false)
    const [fiterValue, setFiterValue] = useState("notPaid")
    const adminApi = new AdminApi();
    const { t } = useTranslation()
    let { userId } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [qrData, setQrData] = useState([])

    useEffect(() => {
        allServices()
    }, [])

    const handleModalShow = ((row) => {
        setQrData(row)
        setModalShow(true)
    })

    // const getUnpaidUsers = async (searchKeyword = null) => {
    //     let d = {}
    //     if (searchKeyword !== "") {
    //         d.searchKeyword = searchKeyword;
    //     } else {
    //         d.searchKeyword = '';
    //     }
    //     setDataLoader(true)
    //     await adminApi.getUnpaidUsers(d).then((response) => {
    //         if (response.data) {
    //             setServicesAndPaymentsData(response.data);
    //             setDataLoader(false)
    //         }
    //     }).catch((error) => {
    //         setDataLoader(false)
    //         toast.error(error.toString());
    //     });
    // }

    // const servicesAndPayments = async (searchKeyword = null) => {
    //     let d = {}
    //     if (searchKeyword !== "") {
    //         d.searchKeyword = searchKeyword;
    //     } else {
    //         d.searchKeyword = '';
    //     }
    //     setDataLoader(true)
    //     await adminApi.servicesAndPayments(d).then((response) => {
    //         if (response.data) {
    //             setDataLoader(false)
    //             setServicesAndPaymentsData(response.data);
    //         }
    //     }).catch((error) => {
    //         setDataLoader(false)
    //         toast.error(error.toString());
    //     });
    // }

    const allServices = async (searchKeyword = null) => {
        let d = {}
        if (searchKeyword !== "") {
            d.searchKeyword = searchKeyword;
        } else {
            d.searchKeyword = '';
        }
        setDataLoader(true)
        await adminApi.allServices(d).then((response) => {
            if (response.data) {
                setServicesAndPaymentsData(response.data);
                setDataLoader(false)
            }
        }).catch((error) => {
            setDataLoader(false)
            toast.error(error.toString());
        });
    }

    const SearchData = (event) => {
        const key = event.target.value;
        if (key !== "") {
            allServices(key)
            // if (fiterValue === "allServices") {
            //     allServices(key);
            // }
            // else if (fiterValue === "paid") {
            //     servicesAndPayments(key)
            // }
            // else {
            //     getUnpaidUsers(key);
            // }
        } else {
            setSearchKey("");
            allServices(key)
            // if (fiterValue === "allServices") {
            //     allServices();
            // }
            // else if (fiterValue === "paid") {
            //     servicesAndPayments()
            // }
            // else {
            //     getUnpaidUsers();
            // }
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );

    const handleSearchFor = (e) => {
        let { value } = e.target
        setFiterValue(e.target.value)
        allServices()
        // if (value === "allServices") {
        //     allServices()
        // }
        // else if (value === "paid") {
        //     servicesAndPayments()
        // }
        // else {
        //     getUnpaidUsers()
        // }
    }

    const changeServiceStatus = (id, value) => {
        adminApi.changeServiceStatus({
            id: id,
            value: value,
        })
            .then((res) => {
                if (value === "Inactive") {
                    toast.success(`Deactivated Successfully!`);
                } else {
                    toast.success(`Activated Successfully!`);
                }
                allServices()
                // if (fiterValue === "allServices") {
                //     allServices();
                // }
                // else if (fiterValue === "paid") {
                //     servicesAndPayments()
                // }
                // else {
                //     getUnpaidUsers();
                // }
            })
            .catch((error) => {
                toast.error(error);
            });
    };

    const columns = [
        {
            name: 'Service',
            selector: row => row.service,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.service)}
                >
                    <span> {row.service && row.service !== null ? row.service : "---"}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "120px",
            style: {
                cursor: "text"
            },
        },
        {
            name: 'User Name',
            selector: row => row.first_name,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(`${row.first_name}` + ' ' + `${row.last_name}`)}
                >
                    <Link to={`/user-profile/uid/${row.user_id}`}>
                        <span> {row.first_name && row.first_name !== null ? row.first_name : "---"} {row.last_name && row.last_name !== null ? row.last_name : ""}</span>
                    </Link>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "150px",
            style: {
                cursor: "text"
            },
        },
        {
            name: 'Company Name',
            selector: row => row.companyName,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.companyName)}
                >
                    <span> {row.companyName && row.companyName !== null ? row.companyName : "---"}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "150px",
            style: {
                cursor: "text"
            },
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.email)}
                >
                    <span> {row.email && row.email !== null ? row.email : "---"}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "220px",
            style: {
                cursor: "text"
            },
        },
        {
            name: 'Language',
            selector: row => row.language,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.language)}
                >
                    <span> {row.language && row.language !== null ? row.language : "---"}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "120px",
            style: {
                cursor: "text"
            },
        },
        {
            name: 'Vendor Name',
            selector: row => row.vendor_name,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.vendor_name)}
                >
                    <span> {row.vendor_name && row.vendor_name !== null ? row.vendor_name : "---"}</span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "120px",
            style: {
                cursor: "text"
            },
        },
        {
            id: "City",
            name: "City",
            selector: row => row.city_name,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.city_name)}
                >
                    <span>
                        {row.city_name}
                    </span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "100px",
            style: {
                cursor: "text"
            },
        },
        {
            id: "Country",
            name: "Country",
            selector: row => row.country_name,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.country_name)}
                >
                    <span>
                        {row.country_name}
                    </span>
                </OverlayTrigger>
            ),
            sortable: true,
            width: "100px",
            style: {
                cursor: "text"
            },
        },
        {
            id: "create",
            name: "Created On",
            selector: row => row.createdAt,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a"))}
                >
                    <span>{Helpers.convertUtcToTimezone(row.createdAt, 'MM-DD-YYYY')}</span>
                </OverlayTrigger>
            ),
            reorder: true,
            sortable: true,
            width: "150px",
            style: {
                cursor: "text"
            },
        },
        {
            name: 'Link',
            selector: row => row.scan_code,
            cell: (row) => (
                <i className="fa fa-link" style={{ fontSize: "20px" }} onClick={() => handleModalShow(row)}> </i>
            ),
            sortable: true,
            width: "80px",
        },
        {
            name: t("preview"),
            selector: row => row.preview,
            cell: row => (
                <>
                    {
                        row.menu_id && row.menu_id !== null ?
                            <a target="_blank" href={`/menu/${row.vendor_name}-${row.menu_id}`}>
                                <i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
                            </a>
                            :
                            <a target="_blank" href={`/product/${row.vendor_name}-${row.list_id}`}>
                                <i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
                            </a>
                    }
                </>
            ),
            width: "90px"
        },
        {
            id: "create",
            name: "Changed Status Date",
            selector: row => row.active_date ? Helpers.convertUtcToTimezone(row.active_date, 'MM-DD-YYYY') : "---",
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.active_date ? Helpers.convertUtcToTimezone(row.active_date, "MM-DD-YYYY hh:mm:a") : null)}
                >
                    <span>{row.active_date ? Helpers.convertUtcToTimezone(row.active_date, 'MM-DD-YYYY') : "---"}</span>
                </OverlayTrigger>
            ),
            reorder: true,
            sortable: true,
            width: "150px",
            style: {
                cursor: "text"
            },
        },
        {
            name: t("payment_table_col_invoice_valid_date"),
            selector: row => row.validDate,
            cell: (row) => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(Helpers.convertUtcToTimezone(row.validDate, "MM-DD-YYYY hh:mm:a"))}
                >
                    <span> {row.validDate && row.validDate !== null ? Helpers.convertUtcToTimezone(row.validDate, 'MM-DD-YYYY') : "---"}</span>
                </OverlayTrigger>
            ),
            reorder: true,
            sortable: true,
            width: "10%",
            style: {
                cursor: "text"
            },
        },
        {
            id: 'Paid/Not-paid',
            name: "Paid/Not-paid",
            selector: row => row.invoiceId && row.invoiceId !== null ? "Paid" : "Not-Paid",
            cell: row => (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(row.invoiceId && row.invoiceId !== null ? "Paid" : "Not-Paid")}
                >
                    <span> {row.invoiceId && row.invoiceId !== null ? "Paid" : "Not-Paid"}</span>
                </OverlayTrigger>
            ),
            reorder: true,
            sortable: true,
            width: "130px",
        },
        {
            id: 'status',
            name: "Status",
            selector: row => row.service_active.charAt(0).toUpperCase() + row.service_active.slice(1),
            cell: row => (
                <Form.Select size="sm" value={row.service_active} onChange={(e) => changeServiceStatus(row.userServiceId, e.target.value)}>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </Form.Select>
            ),
            reorder: true,
            sortable: true,
            width: "130px",
        },
    ];

    return (
        <div className="cont-bg register-page">
            <div className="container">
                <Row>
                    <Col lg={12}>
                        <h2>Products</h2>
                        <p>
                            View list of Products not paid and paid by clients. You can activate or inactive these products and end-user can view these E-Menu & E-Products.
                        </p>
                    </Col>
                </Row>
                {/* <div className="register-page">
                    <div className="container">
                        <div className="card mb-3">
                            <div className="card-body">
                                <Row>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Check
                                            type={'radio'}
                                            id={`retreadRad`}
                                            checked={fiterValue == "notPaid" ? fiterValue : null}
                                            onChange={(e) => {
                                                handleSearchFor(e);
                                            }}
                                            value={"notPaid"}
                                            label={`Not-Paid`}
                                            name='searchFor'
                                            className="space_lef1"
                                        />
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Check
                                            type={'radio'}
                                            id={`coupleRad`}
                                            // checked={searchQuery.searchFor === 'couple'}
                                            onChange={(e) => {
                                                handleSearchFor(e);
                                            }}
                                            value={"paid"}
                                            label={`Paid`}
                                            className="space_lef1"
                                            name='searchFor'
                                        />
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Check
                                            type={'radio'}
                                            id={`coupleRad1`}
                                            // checked={searchQuery.searchFor === 'couple'}
                                            onChange={(e) => {
                                                handleSearchFor(e);
                                            }}
                                            value={"allServices"}
                                            label={`All Services`}
                                            className="space_lef1"
                                            name='searchFor'
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="register-page">
                    <div className="container">
                        <div className="card mb-3">
                            <div className="card-body">
                                <Row>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <label className="me-3 mb-0 searchSet">
                                            <input type="search" onChange={SearchData} className="form-control" placeholder="Search by Keyword" aria-controls="order-listing" />
                                        </label>
                                    </Col>
                                    <Col md={12} lg={12} sm={12} xs={12} className="mt-3">
                                        {dataLoader ? <div className='text-center'>
                                            <Loader />
                                        </div> :
                                            <CustomDataTable
                                                data={servicesAndPaymentsData}
                                                pagination={servicesAndPaymentsData.length > 30 ? true : false}
                                                columns={columns}
                                                pointerOnHover={true}
                                                highlightOnHover={true}
                                                fixedHeader={true}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                {modalShow ?
                    <AdminQrModal
                        modalShow={modalShow}
                        qrData={qrData}
                        onHide={() => setModalShow(false)}
                    />
                    :
                    null
                }
            </div></div>

    );
}

export default UnpaidUsers;
