import React from "react";
import { Col, Row } from "react-bootstrap";

const dashboard = () => {
  return <div className="cont-bg register-page">
     <div className="container">
     <Row>
          <Col lg={12}>
            <h2>Dashboard</h2>
            <p>
              Admin can view all registrations, can activate/ deactivate the
              registered users.
            </p>
          </Col>
        </Row>
     </div>
  </div>;
};

export default dashboard;
