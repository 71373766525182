import React, { useState, useEffect } from 'react';
import CommonApi from '../api/commonApi';
// import { Img } from 'react-image';

const Base64Img = (props) => {
  const commonApi = new CommonApi()
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        await commonApi.getBase64Image({ imageUrl: props.imageUrl }).then(res => {
          if (res?.data) {
            // console.log(res.data)
            setImageSrc(res.data)
          }
        })
      } catch (error) {
        console.error(error);
      }
    };
    loadImage();
  }, [props.imageUrl]);


  return <img
    src={imageSrc}
    alt={props.alt}
    className={props.className ? props.className : ''}
    style={props.style ? props.style : {}}
  />;
};

export default Base64Img;
