import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Cookies, useCookies } from 'react-cookie'
import restDummyLogo from "../../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../../assets/images/itemDummyImage.png";
import Template1 from "../../../assets/images/template1.png";
import Template2 from "../../../assets/images/template2.png";
import Template3 from "../../../assets/images/template3.png";
import DataTable from "react-data-table-component";
import DeleteModel from "../../../components/deleteModel";
import Preview from './../preview';
import EMenuApi from '../../../api/eMenuApi'
import UserApi from '../../../api/userApi'
import EProductApi from '../../../api/eProductApi'
import PaymentApi from '../../../api/paymentApi'
import moment from 'moment';
import Helpers from '../../../components/helpers';
import QrComponent from '../../../components/qrComponent';
import Loader from "../../../components/loader";
import PayPal from '../../../components/payPal';
import { vat } from '../../../config'
import AuthApi from '../../../api/authApi';

const CreateEProduct = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [validated1, setValidated1] = useState(false);
  const [isError, setIsError] = useState(false);
  const [savePreview, setSavePreview] = useState(false);
  const [productId, setItemId] = useState("")
  const [listPreview, setListPreview] = useState([])
  const [categoryUnits, setCategoryUnits] = useState([])
  const [selectedTemplete, setSelectedTemplete] = useState([])
  const [result, setResult] = useState([])
  let [searchParams, setSearchParams] = useSearchParams();
  let serviceId = searchParams.get("serviceId");
  let cookies = new Cookies()
  let userData = cookies.get('AllMenuUser') || null;
  const [cookie, setCookie] = useCookies(['AllMenuUser']);
  const eMenuApi = new EMenuApi();
  const eProductApi = new EProductApi();
  const paymentApi = new PaymentApi();
  const userApi = new UserApi();
  let authApi = new AuthApi()

  const [data, setData] = useState({
    id: "",
    userServiceId: "",
    logoUrl: "",
    supplierName: "",
    phone: "",
    email: "",
    currencyName: "", //store country table id according to currency
    website: "",
    address: "",
    category_id: '',
    background_id: "",
    template_id: '1',
    list_id: '',
    language_code: '',
    country: "",
    city_id: "",
    otherCity: ""
  });
  const [categoryBackground, setCategoryBackground] = useState([]);
  const [isTempUpload, setIsTempUpload] = useState(false)
  const [isTempUpload1, setIsTempUpload1] = useState(false)
  const [categoryItem, setCategoryItem] = useState([])
  const [show, setShow] = useState(false);
  const [itemData, setItemData] = useState([])
  const [supplierError, setSupplierError] = useState(false);
  const [userRestData, setUserRestData] = useState([])
  const [isEmailError, setIsEmailError] = useState(false);
  const [isWebsiteError, setIsWebsiteError] = useState(false);
  const [showPayPalComp, setShowPayPalComp] = useState(false);
  const [eMenuPrice, setEMenuPrice] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [userEu, setUserEu] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0)
  const [showPayPal, setShowPayPal] = useState(false)
  const selected = localStorage.getItem("i18nextLng");
  const [listId, setListId] = useState("");
  const [allCurrency, setAllCurrency] = useState([])
  const [exchangeRate, setExchangeRate] = useState(null)
  const [loadingCity, setLoadingCity] = useState(false);
  const [cityList, setCityList] = useState([])
  const [countriesList, setcountriesList] = useState([])
  useEffect(() => {
    if (serviceId) {
      let sendObj = {
        language_code: selected,
        id: userData.id,
        userServiceId: serviceId,
      }
      getUserProduct(sendObj)
    } else {
      getUserCurrency()
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getAllCurrency()
  }, []);

  useEffect(() => {
    getCategoryBackground()
    getCategoryUnits()
    if (listId) {
      let sendObj = {
        userId: userData.id,
        list_id: listId,
        language_code: selected
      }
      getProducts(sendObj)
      getProductPreview(sendObj)
    }
    if (serviceId) {
      let sendObj1 = {
        userServiceId: serviceId,
        language_code: selected
      }
      eProductApi.saveRestBgTempId(sendObj1)
        .then(res => {
          if (res.success) {
          } else {
            toast.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [selected])

  useEffect(() => {
    getServicePrice(userData);
    getAllCountries()
  }, [])

  useEffect(() => {
    getAllCities(data.country)
  }, [data])

  const getAllCities = async (country) => {
    await authApi.getAllCities({ country_id: country }).then((response) => {
      setCityList(response.data);
      setLoadingCity(false)
    }).catch((error) => {
      setLoadingCity(false)
      toast.error(error.toString());
    });
  }

  const [section2, setSection2] = useState({
    bgId: '',
    templateId: '1',
    userServiceId: '',
    list_id: '',
    language_code: "",
  })

  const [categoryProduct, setCategoryProduct] = useState({
    productId: '',
    quantity: '',
    categoryType: "",
    code: "",
    productName: '',
    unit_id: "",
    productPrice: '',
    productDescription: '',
    productPicUrl: '',
    list_id: ''
  })

  const handleClose1 = () => setShowPayPal(false);

  const getCurrencyExchangeRate = async () => {
    const exchange_rate = await Helpers.fetchCurrencyExchangeRate();
    setExchangeRate(exchange_rate);
  }
  const getServicePrice = async (data) => {
    await eProductApi.getServicePrice({ userId: data.id })
      .then(res => {
        if (res.success) {
          // console.log("res.datataaaa",res.data);
          // setEMenuPrice(res.data.price)
          if (res.isUserFromEurope) {
            setUserEu(true)
            let senObj = {
              price: res.data.price,
              isVAT: res.isUserFromEurope
            }
            let VAT = Helpers.calculateVAT(senObj)

            setEMenuPrice(VAT)
          } else {
            setEMenuPrice(res.data.price)
          }
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleClose = () => setShow(false);

  const getUserCurrency = async () => {
    await userApi.getUserCurrency({ id: userData.id })
      .then(res => {
        if (res.success) {
          let d = { ...data }
          d.currencyName = res.data.id
          setData(d)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAllCurrency = async () => {
    await userApi.getAllCurrency()
      .then(res => {
        if (res.success) {
          setAllCurrency(res.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserProduct = async (id) => {
    await eProductApi.getUserProduct(id)
      .then(res => {
        if (res.success) {
          setUserRestData(res.data)
          let d = { ...data }
          d.supplierName = res.data.vendor_name
          d.email = res.data.email
          d.website = res.data.website
          d.phone = res.data.phone
          d.address = res.data.address
          d.logoUrl = res.data.logo_url
          d.currencyName = res.data.country_id
          d.country = res.data.country
          d.city_id = res.data.city_id
          d.userServiceId = res.data.id
          d.background_id = res.data.background_id
          d.template_id = res.data.template_id == null ? '1' : res.data.template_id
          d.list_id = res.data.listId
          setData(d)
          let d1 = { ...section2 }
          d1.templateId = res.data.template_id == null ? '1' : res.data.template_id
          d1.bgId = res.data.background_id
          d1.list_id = res.data.listId
          d1.userServiceId = res.data.id
          setSection2(d1)
          let sendObj = {
            userId: userData.id,
            list_id: res.data.listId,
            language_code: selected
          }
          getProducts(sendObj)
          setListId(res.data.listId)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCategoryBackground = async () => {
    await eProductApi.getCategoryBackground({ language_code: selected })
      .then(res => {
        if (res.success) {
          setCategoryBackground(res.data)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAllCountries = async () => {
    await authApi.getAllCountries().then((response) => {
      setcountriesList(response.data);
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getCategoryUnits = async () => {
    await eProductApi.getCategoryUnits({ language_code: selected })
      .then(res => {
        if (res.success) {
          setCategoryUnits(res.data)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChanges = async (e) => {
    let { name, value } = e.target
    let d = { ...data }
    d[name] = value;
    if (name == 'logoUrl') {
      d.logoUrl = e.target.files[0]
    }
    if (name == "supplierName") {
      // let regex = /[!@#\$%\^&\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
      let regex = /[^a-zA-Z\d\s]/g
      const foundSpecialChar = value.match(regex);
      regex = /[~,\,<, ,]/g;
      // const isSpace = value.match(regex);
      if (
        foundSpecialChar?.length
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      if (value.length <= 50) {
        setSupplierError(false)
      } else {
        setSupplierError(true)
      }
    }

    if (name == "email") {
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const emailPatren = value.match(regex);
      if (
        emailPatren?.length
      ) {
        setIsEmailError(false);
      } else {
        setIsEmailError(true);
      }
    }

    if (name == 'website') {
      const regex = /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/i;
      const websitPattern = value.match(regex)
      if (websitPattern?.length) {
        setIsWebsiteError(false)
      } else {
        setIsWebsiteError(true)
      }

    }

    if (e.target.name === "country") {
      setLoadingCity(true);
      authApi.getAllCities({ country_id: e.target.value }).then((response) => {
        setCityList(response.data);
        let d = { ...data }
        d.country = e.target.value;
        setLoadingCity(false)
      }).catch((error) => {
        setLoadingCity(false)
        toast.error(error.toString());
      });
    }

    setData(d)
  }

  const upload = () => {
    document.getElementById("restaurantLogoUpload").click();
    setIsTempUpload(true)
  };

  const upload1 = () => {
    document.getElementById("ProductCategoryListUpload").click();
    setIsTempUpload1(true)
  };

  const remove = () => {
    let d = { ...data }
    d.logoUrl = ''
    setData(d);
    setIsTempUpload(false)
  }

  const remove1 = () => {
    let d = { ...categoryProduct }
    d.productPicUrl = ''
    setCategoryProduct(d);
    setIsTempUpload1(false)
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() == true && isError == false && supplierError == false) {
      setLoading(true);
      let data1 = new FormData()
      data1.append('file', data.logoUrl)
      let stateRef = { ...data }
      stateRef.id = userData.id
      stateRef.language_code = selected
      stateRef.country_id = data.currencyName
      stateRef.userServiceId = data && data.userServiceId ? data.userServiceId : ''
      // console.log("stateRef", stateRef);
      data1.append('formData', JSON.stringify(stateRef));
      eProductApi.saveUserProduct(data1)
        .then((res) => {
          if (res.success) {
            toast.success(t('emenu_product_info_saved'));
            let d = { ...data }
            d.supplierName = res.data.vendor_name
            d.email = res.data.email
            d.website = res.data.website
            d.phone = res.data.phone
            d.address = res.data.address
            d.logoUrl = res.data.logo_url
            d.userServiceId = res.data.id
            d.id = res.data.user_id
            d.language_code = res.data.language_code
            d.currencyName = res.data.country_id
            d.country = res.data.country
            d.city_id = res.data.city_id
            setData(d)
            setIsTempUpload(false)
            let sendObj = {
              userId: userData.id,
              list_id: listId,
              language_code: selected
            }
            getProducts(sendObj)
          } else if (res.error) {
            toast.error(res.msg);
          }
          setLoading(false)
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false)
        });
    }

    setValidated(true);
  }

  const getResTmenuBg = (id, idFor) => {
    const obj = { ...section2 };
    if (idFor == 'bg') {
      obj["bgId"] = id
      obj["userServiceId"] = data.userServiceId
      obj["language_code"] = selected
      obj["scan_code"] = `/e-products/${listPreview &&
        listPreview[0] &&
        listPreview[0].vendor_name.split(" ").join("")
        }-${listPreview &&
        listPreview[0] &&
        listPreview[0].id
        }`
      setSection2(prevState => ({
        ...prevState,
        bgId: id,
        userServiceId: data.userServiceId
      }));
    } else if (idFor == 'temp') {
      obj["templateId"] = id
      obj["userServiceId"] = data.userServiceId
      obj["language_code"] = selected
      obj["scan_code"] = `/e-products/${listPreview &&
        listPreview[0] &&
        listPreview[0].vendor_name.split(" ").join("")
        }-${listPreview &&
        listPreview[0] &&
        listPreview[0].id
        }`
      setSection2(prevState => ({
        ...prevState,
        templateId: id,
        userServiceId: data.userServiceId
      }));
    }
    if (obj.bgId !== '' && obj.templateId !== '') {
      obj['currency_id'] = data.currencyName
      if (data.userServiceId && data.userServiceId !== null) {
        eProductApi.saveRestBgTempId(obj)
          .then(res => {
            if (res.success) {
              toast.success(t('create_product_bgAndTemp_save_msg'))
              let d = { ...categoryProduct }
              d.list_id = res.data.id
              setCategoryProduct(d)
              let d3 = { ...section2 }
              d3.bgId = res.data.background_id
              d3.list_id = res.data.id
              d3.templateId = res.data.template_id
              setSection2(d3)
              // console.log("data in select tempolete", data);
              let sendObj = {
                id: userData.id,
                userServiceId: data.userServiceId,
                language_code: selected
              }
              getUserProduct(sendObj)
              let sendObj1 = {
                userId: userData.id,
                list_id: res.data.id,
                language_code: selected
              }
              getProductPreview(sendObj1)



            } else {
              toast.error(res.msg)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
      else {
        toast.error(t("first_supplier_details"))
      }
    }
  }

  const saveQrUrl = () => {
    const obj = { ...section2 };
    obj['currency_id'] = data.currencyName
    obj["language_code"] = selected
    obj["scan_code"] = `/e-products/${listPreview &&
      listPreview[0] &&
      listPreview[0].vendor_name.split(" ").join("")
      }-${listPreview &&
      listPreview[0] &&
      listPreview[0].id
      }`
    eProductApi.saveRestBgTempId(obj)
      .then(res => {
        if (res.success) {
          toast.success(t('link_genrated'))
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChanges1 = async (e) => {
    // setValidated1(true)
    let { name, value } = e.target
    let d = { ...categoryProduct }
    d[name] = value;
    if (name == 'productPicUrl') {
      d.productPicUrl = e.target.files[0]
    }
    setCategoryProduct(d)
  }

  const handleSubmit2 = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() == true) {
      setLoading(true)
      if (categoryProduct.productId !== '') {
        // edit part
        // console.log("loading", loading);
        let data1 = new FormData()
        data1.append('file', categoryProduct.productPicUrl)
        let stateRef = { ...categoryProduct }
        stateRef.user_id = userData.id
        stateRef.list_id = data.list_id
        delete stateRef.productPicUrl
        stateRef.user_service_id = data.userServiceId
        stateRef.supplierName = data.supplierName
        data1.append('formData', JSON.stringify(stateRef));
        eProductApi.saveProduct(data1)
          .then((res) => {
            if (res.success) {
              let d = { ...categoryProduct }
              d.productId = ''
              d.quantity = ''
              d.productName = ''
              d.categoryType = ""
              d.productPrice = ''
              d.productDescription = ''
              d.productPicUrl = ''
              d.code = ''
              d.unit_id = ''
              setCategoryProduct(d)
              let sendObj = {
                userId: userData.id,
                list_id: res.data.list_id,
                language_code: selected
              }
              // console.log("sendObj1111111111111111", sendObj);
              getProducts(sendObj)
              setValidated1(false)
              setLoading(false)
            } else if (res.error) {
              setLoading(false)
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            // console.log("catch error", error);
            setLoading(false)
            toast.error(error);
          });
      } else {
        // add part
        let data1 = new FormData()
        data1.append('file', categoryProduct.productPicUrl)
        let stateRef = { ...categoryProduct }
        stateRef.user_id = userData.id
        stateRef.list_id = data.list_id
        delete stateRef.productPicUrl
        stateRef.display_order = displayOrder
        stateRef.user_service_id = data.userServiceId
        stateRef.supplierName = data.supplierName
        data1.append('formData', JSON.stringify(stateRef));
        eProductApi.saveProduct(data1)
          .then((res) => {
            if (res.success) {
              toast.success(t('create_product_Add_product_msg'));
              setCategoryItem(res.data)
              setValidated1(false)
              let d = { ...categoryProduct }
              d.quantity = ''
              d.productName = ''
              d.productPrice = ''
              d.categoryType = ''
              d.productDescription = ''
              d.productPicUrl = ''
              d.code = ''
              d.unit_id = ''
              setCategoryProduct(d)
              let sendObj = {
                userId: userData.id,
                list_id: res.data.list_id,
                language_code: selected
              }
              // console.log("sendObj", sendObj);
              getProducts(sendObj)
              setValidated1(false)
            } else if (res.error) {
              toast.error(res.msg);
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            toast.error(error);
          });
      }

    }
    setValidated1(true);
  }

  const changeOrder = (row, value) => {
    eProductApi.changeOrder({
      row: row,
      value: value,
    })
      .then((res) => {
        let sendObj = {
          userId: userData.id,
          list_id: row.list_id,
          language_code: selected
        }
        getProducts(sendObj)
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#f8f9fb',
        fontSize: '0.82rem !important',
        fontWeight: '700',
        innerHeight: "20px",
        height: "38px",
      }
    },
    head: {
      style: {
        height: "38px",
      }
    },
    rows: {
      style: {
        height: "auto !important",
        minHeight: "38px !important",
      }
    },
    cells: {
      style: {
        height: "auto !important",
        minHeight: "38px !important",
        verticalAlign: "middle",
        fontSize: "0.82rem",
        lineHeight: "1.5",
        // whiteSpace: "nowrap",
        fontWeight: "500",
      }
    },
    table: {
      style: {
        paddingBottom: "0",
      }
    }
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    {
      id: "code",
      name: t("code"),
      selector: row => row.code,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.code)}
        >
          <span>
            {row.code}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "product_type",
      name: t("category"),
      selector: row => row.type_name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.type_name)}
        >
          <span>
            {row.type_name}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "product_name",
      name: t("product"),
      selector: row => row.name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.name)}
        >
          <span>
            {row.name}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "product_price",
      name: t('create_emenu_item_grid_column_price'),
      selector: row => row.price,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.currency_symbol + ' ' + row.price)}
        >
          <span>
            {row.currency_symbol + ' ' + row.price}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "product_quantity",
      name: t("quantity"),
      selector: row => row.quantity,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(`${row.quantity}` + " " + `${row.unit_name}`)}
        >
          <span>
            {row.quantity} {row.unit_name}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "item_description",
      name: t('create_emenu_item_grid_column_description'),
      selector: row => row.description,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.description)}
        >
          <span>
            {row.description && row.description.slice(0, 20) + (row.description.length > 20 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "item_url",
      name: t('create_emenu_item_grid_column_picture'),
      selector: row => row.image_url,
      cell: (row) => (
        row.image_url && row.image_url !== null ? <img style={{ width: "50px", height: 'auto', margin: '5px 0' }} src={row.image_url} /> :
          <img style={{ width: "50px", height: 'auto', margin: '5px 0' }} src={restDummyItemImage} />
      ),
      // sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: 'item_action',
      name: t('create_emenu_item_grid_column_action'),
      button: true,
      cell: row => (
        <>
          <div className="d-flex">

            <span onClick={() => changeOrder(row, "moveUp")}><i class="fa fa-caret-up"></i></span>
            <span onClick={() => changeOrder(row, "moveDown")}><i class="fa fa-caret-down"></i></span>
            <span onClick={() => editItem(row)}><i className="fa fa-pencil-square" ></i></span>

            <span onClick={() => handleShow(row)}><i className="fa fa-times-circle"></i></span>
          </div>
        </>
      ),
      width: "10%",
    },
  ];

  const editItem = (item) => {
    let d = { ...categoryProduct }
    d.productId = item.id
    d.code = item.code
    d.categoryType = item.category_id
    d.quantity = item.quantity
    d.unit_id = item.unit_id
    d.productName = item.name
    d.productPrice = item.price
    d.productDescription = item.description
    d.productPicUrl = item.image_url
    d.list_id = item.list_id
    setCategoryProduct(d)
    setIsTempUpload1(false)
  }

  const handleShow = (rowValue) => {
    setItemData(rowValue)
    setShow(true);
  }

  const deleteData = () => {
    eProductApi.deleteProductFromList(itemData)
      .then(res => {
        if (res.success) {
          setShow(false)
          let sendObj = {
            userId: userData.id,
            list_id: res.data.list_id,
            language_code: selected
          }
          getProducts(sendObj)
        } else {
          toast.error(res.msg)
        }
      })
      .catch(error => {
        toast.error(error)
      })
  }

  const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const getProducts = async (sendObj) => {
    await eProductApi.getProducts(sendObj)
      .then(res => {
        if (res.success) {
          let result = groupBy(res.data, 'id')
          setResult(result)
          setCategoryItem(res.data)
          setDisplayOrder(res.display_order)
          getProductPreview(sendObj)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getProductPreview = async (sendObj) => {
    await eProductApi.getProductPreview(sendObj)
      .then(res => {
        if (res.success) {
          setListPreview(res.data)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const savePaymentData = async (data) => {
    await paymentApi.savePaymentData(data)
      .then(res => {
        // console.log("res.dattaaaaaaaaaa222222",res.data);
        if (res.success) {
          toast.success(t('create_emenu_payment_done'))
          if (data.transaction_id) {
            navigate('/my-payment', {
              state: data
            });
          }

        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: eMenuPrice,
          },
        },
      ],
    })
  }

  const onApprove = (data1, actions) => {
    return actions.order.capture().then(function (res) {
      // save transaction data in db
      let invoice_no = moment(new Date).format('DD/MM/YYYY')
      invoice_no = invoice_no + '/ALLM'
      invoice_no = invoice_no + (userEu ? '/EU' : '/OE')
      let full_name = userData.first_name + (userData.last_name ? ' ' + userData.last_name : '')
      if (res.status == 'COMPLETED') {
        let saveObj = {
          user_service_id: data.userServiceId,
          user_name: full_name,
          invoice_no: invoice_no,
          transaction_id: res.purchase_units[0].payments.captures[0].id,
          invoice_date: moment().format('YYYY-MM-DD HH:mm:ss '),
          amount: eMenuPrice,
          vat: userEu ? vat.VAT : 0,
          exchange_rate: exchangeRate,
          language_code: userData.language_code,
          invoice_status: 'PAID',
          user_email: userData.email,
          service_for: 'E-Product'
        }
        savePaymentData(saveObj)
      }
    });
  }

  const onError = (err) => {
    toast.error(t('error_in_payment.'))
  }

  return (
    <>
      <div className="register-page">
        <div className="container-fluid">
          <div className="row d-flex m-0">
            <div className="col-lg-12 pr-0 pl-0 page-header page-product">
              <header className="entry-header">
                <h1>{t('e_product')}</h1>
              </header>
            </div>
          </div>
        </div>
      </div>

      <div className="cont-bg register-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>
                {t('user_generate_eproduct_h2')}
              </h2>
              <p>{t('user_generate_eproduct_p')}</p>
            </div>
          </div>

          {/* <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('supplier_information')}
                  </h4>
                </div>
              </div>
              {/* </Accordion.Header>
                  <Accordion.Body> */}
              <Form
                onSubmit={handleSubmit}
                noValidate
                validated={validated}>
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-3">
                    <div className="input-group col-xs-12">
                      <div className="text-center col">
                        <Form.Group >
                          {data.logoUrl == "" || data.logoUrl == undefined ? (
                            <div className="edit-profile-img mb-2 text-center">
                              <img src={restDummyLogo} />
                            </div>
                          ) : (
                            <div className="edit-profile-img mb-2 text-center">
                              {
                                isTempUpload ?
                                  <><img src={URL.createObjectURL(data?.logoUrl)} /></>
                                  :
                                  <><img src={data?.logoUrl} /></>
                              }
                            </div>
                          )}
                          {" "}
                          <div className="text-center mt-3">
                            {data.logoUrl == "" || data.logoUrl == undefined ?
                              <>
                                <input name="logoUrl" className="pt-1" type="file" accept="image/*" style={{ display: "none" }} id="restaurantLogoUpload" onChange={(e) => handleChanges(e)}
                                />
                                <Button variant="primary" className="mb-1 me-3 btn btn-sm" onClick={(e) => { upload(); }}>{t('user_generate_upload_logo_image')}</Button>
                                {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                              </>
                              :
                              <Button variant="primary" className="mb-1 me-3 btn btn-sm" onClick={(e) => { remove(); }}>{t('createEmenu_removeCompanyLogo_btn')}</Button>}
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text" id="supplierName"
                        required
                        isInvalid={isError ? isError : supplierError}
                        placeholder={t('supplier_name')}
                        name="supplierName"
                        value={data.supplierName}
                        onChange={(e) => handleChanges(e)} />
                      <Form.Control.Feedback type="invalid">
                        {data.supplierName == "" ? (
                          t('supplier_required')
                        ) : isError ? <>
                          {t('createEmenu_specialChar_error')}
                        </> : supplierError ? t('character_length') : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text" id="website"
                        // required
                        placeholder={t('createEMenu_placeHolder_website')}
                        name="website"
                        value={data.website}
                        isInvalid={isWebsiteError}
                        onChange={(e) => handleChanges(e)} />
                      <Form.Control.Feedback type="invalid">
                        {/* {data.website == ' ' ? (
                                t('register_website_error')
                              ) : */}
                        {t('register_website_valid_error')}
                        {/* } */}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="phone" aria-describedby="phone" placeholder={t('register_phone_label')}
                        name="phone"
                        value={data.phone}
                        onChange={(e) => handleChanges(e)} />
                    </Form.Group>
                  </div>


                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="e-mail" id="email" aria-describedby="e-mail"
                        // required
                        placeholder={t('register_email_label')}
                        value={data.email}
                        name="email"
                        onChange={(e) => handleChanges(e)}
                        isInvalid={isEmailError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {/* {" "}
                              {data.email == "" ? (
                                t('register_email_error')
                              ) : (
                                <> */}
                        {t('regiser_email_error_valid')}
                        {/* </>
                              )} */}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Select className="form-select selection"
                        id="currencyName"
                        aria-describedby="currencyName"
                        placeholder={t('createEmenu_placeHolder_currency')}
                        name="currencyName"
                        value={data.currencyName}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">{t('select_currency')}</option>
                        {
                          allCurrency && allCurrency.map(item => (
                            <option
                              key={item.id}
                              value={item.id}
                            >
                              {item.name + ' - ' + item.currency_name + ' (' + item.currency_symbol + ')'}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="Street"
                        aria-describedby="address" placeholder={t('createEmenu_placeHolder_address')}
                        name="address"
                        value={data.address}
                        onChange={(e) => handleChanges(e)} />
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control
                        required
                        as="select"
                        aria-label="Default select example" name="country"
                        id="country"
                        placeholder={t('register_Country_drop')}
                        value={data.country}
                        onChange={(e) => handleChanges(e)} >
                        <option value="">{t('register_Country_drop')}</option>
                        {
                          countriesList &&
                            countriesList.length ? countriesList.map(item => {
                              return (
                                <option key={`country-${item.id}`} value={item.id}>{item.name}</option>
                              )
                            }) : " "
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {t('register_country_error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control
                        aria-label="Default select example"
                        required={data.otherCity !== "" ? false : true}
                        name="city_id"
                        as="select"
                        id="city"
                        placeholder={t('register_City_drop')}
                        value={data.city_id}
                        onChange={(e) => handleChanges(e)} >
                        <option value="">{t('register_City_drop')}</option>
                        {
                          cityList && cityList.length ? cityList.map(item => {
                            return (
                              <option key={`city-${item.id}`} value={item.id}>{item.name}</option>
                            )
                          }) : " "
                        }
                      </Form.Control>
                      {loadingCity ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : null}
                      <Form.Control.Feedback type="invalid">
                        {t('register_city_error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        disabled={data && data.city_id && data.city_id.length && data.city_id.length !== "" ? true : false}
                        id="otherCity"
                        name="otherCity"
                        aria-describedby="otherCity" placeholder={t('register_Other_city')}
                        value={data.otherCity}
                        onChange={(e) => handleChanges(e)} />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-secondary">{t('createEmenu_save_btn')}</button>
                  </div>
                </div>
              </Form>
              {/* </Accordion.Body> */}
            </div>
          </div>
          {/* </Accordion.Item> */}

          {/* select background image part */}
          {/* <Accordion.Item eventKey="1"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('createEmenu_chooseBg')}
                  </h4>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12 col-xs-12">
                  <div className="row justify-content-center text-center cuisine d-flex">
                    {
                      categoryBackground && categoryBackground.map(item => {
                        return (
                          <div className="col" key={item.id} onClick={() => getResTmenuBg(item.id, 'bg')}>
                            <div className={`${section2.bgId === item.id ? 'card border-card' : 'card'}`}>
                              <img src={item.image_url} className="card-img" alt={item.name} />
                              <div className="card-body">
                                <p className="card-text">{item.name}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="col-md-6 col-lg-8 col-sm-12 col-xs-12">
                  <div className="card showbackPreview ">
                    {listPreview && listPreview[0] && listPreview[0].background_img ? <img className="bgImage" src={`${listPreview && listPreview[0] && listPreview[0].background_img}`} alt="" srcset="" /> : <h4 className='select_cuisine1'>{t("select_category_img")}</h4>}
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* </Accordion.Item> */}

          {/* Template choose part */}
          {/* <Accordion.Item eventKey="2"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('createEmenu_chooseDesign')}
                  </h4>
                </div>
              </div>
              {/* </Accordion.Header>
                  <Accordion.Body> */}
              <Form>
                <Form.Group controlId='tempId'>
                  <div className="row justify-content-center text-center design d-flex">
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                      <div className="card">
                        <img src={Template1} alt="..." />
                        <div className="card-body">
                          <ul>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_frame')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_picture')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_title')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_description')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_price')}</span></li>
                          </ul>
                          <div className="form-check">
                            <Form.Check
                              type="radio"
                              id={`default-${1}`}
                              value="1"
                              name="templateId"
                              checked={section2.templateId == 1 ? true : false}
                              onChange={(e) => getResTmenuBg(e.target.value, 'temp')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                      <div className="card">
                        <img src={Template2} alt="..." />
                        <div className="card-body">
                          <ul>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_frame')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_picture')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_title')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_price')}</span></li>
                          </ul>
                          <div className="form-check">
                            <Form.Check
                              type="radio"
                              id={`default-${2}`}
                              value="2"
                              name="templateId"
                              checked={section2.templateId == 2 ? true : false}
                              onChange={(e) => getResTmenuBg(e.target.value, 'temp')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                      <div className="card">
                        <img src={Template3} alt="..." />
                        <div className="card-body">
                          <ul>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_title')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_description')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_price')}</span></li>
                          </ul>
                          <div className="form-check">
                            <Form.Check
                              type="radio"
                              id={`default-${3}`}
                              value="3"
                              checked={section2.templateId == 3 ? true : false}
                              name="templateId"
                              onChange={(e) => getResTmenuBg(e.target.value, 'temp')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
              {/* </Accordion.Body> */}
            </div>
          </div>
          {/* </Accordion.Item> */}

          {/* Add item part */}
          {/* <Accordion.Item eventKey="3"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('add_products')}
                  </h4>
                </div>
              </div>
              {/* </Accordion.Header>
                  <Accordion.Body> */}
              <Form
                onSubmit={handleSubmit2}
                noValidate
                validated={validated1}
              >
                <div className="row ml-0 mr-0">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="code"
                        placeholder={t("code")}
                        name="code"
                        required
                        value={categoryProduct.code}
                        onChange={(e) => handleChanges1(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('code_required')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-3 mb-3 col-sm-12 col-xs-12">
                    <Form.Group>
                      <Form.Select className="form-select selection"
                        aria-label="Default select example"
                        name="categoryType"
                        required
                        onChange={(e) => handleChanges1(e)}
                        value={categoryProduct.categoryType}
                      >
                        <option value="">{t('categories')}</option>
                        {
                          categoryBackground && categoryBackground.map(item => (
                            <option value={item.id} key={`category_Background${item.id}`}>{item.name}</option>
                          ))
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {categoryProduct.categoryType == '' ?
                          t('product_type_required') : ''
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-3 col-sm-12 col-12xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="productName"
                        placeholder={t("product_name")}
                        name="productName"
                        required
                        value={categoryProduct.productName}
                        onChange={(e) => handleChanges1(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('Product_required')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-3 col-sm-12 col-12xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="productPrice"
                        aria-describedby="productPrice"
                        placeholder={t('create_emenu_item_price')}
                        name="productPrice"
                        required
                        value={categoryProduct.productPrice}
                        onChange={(e) => handleChanges1(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('product_price_required')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="quantity"
                        placeholder={t("quantity")}
                        name="quantity"
                        required
                        value={categoryProduct.quantity}
                        onChange={(e) => handleChanges1(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('product_quantity_required')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-4 mb-3 col-sm-12 col-xs-12">
                    <Form.Group >
                      <Form.Control as="select" className="form-select selection"
                        aria-label="Default select example"
                        name="unit_id"
                        required
                        onChange={(e) => handleChanges1(e)}
                        value={categoryProduct.unit_id}
                      >
                        <option value="">{t('select_unit')}</option>
                        {
                          categoryUnits && categoryUnits.map(item => (
                            <option value={item.id} key={`unit_list${item.id}`}>{item.name}</option>
                          ))
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {categoryProduct.quantity == '' ?
                          t('unit_is_required') : ''
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="productDescription"
                        aria-describedby="productDescription"
                        placeholder={t('create_emenu_item_description')}
                        name="productDescription"
                        value={categoryProduct.productDescription}
                        onChange={(e) => handleChanges1(e)}
                      />
                    </Form.Group>
                  </div>
                  {/* Item image upload */}
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <Form.Group >
                      {categoryProduct.productPicUrl == "" || categoryProduct.productPicUrl == undefined ? (
                        <div className="edit-profile-img mb-2 text-center">
                          <img src={restDummyItemImage} />
                        </div>
                      ) : (
                        <div className="edit-profile-img mb-2 text-center">
                          {
                            isTempUpload1 ?
                              <><img src={URL.createObjectURL(categoryProduct?.productPicUrl)} /></>
                              :
                              <><img src={categoryProduct?.productPicUrl} /></>
                          }
                        </div>
                      )}
                      {" "}
                      <div className="text-center mt-3">
                        {categoryProduct.productPicUrl == "" || categoryProduct.productPicUrl == undefined ?
                          <>
                            <input name="productPicUrl" className="pt-1" type="file" accept="image/*" style={{ display: "none" }} id="ProductCategoryListUpload" onChange={(e) => handleChanges1(e)}
                            />
                            <Button variant="primary" className="mb-1 btn btn-sm" onClick={(e) => { upload1(); }}>{t('create_emenu_item_upload_button')}</Button>
                            {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                          </>
                          :
                          <Button variant="primary" className="mb-1 me-3 btn btn-sm" onClick={(e) => { remove1(); }}>{t('createEmenu_removeCompanyLogo_btn')}</Button>}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-secondary">
                      {categoryProduct.productId === '' || categoryProduct.productId === null ? t('add_product') : t('create_update_product')}
                    </button>
                  </div>
                </div>
              </Form>
              <div className="table-responsive">
                {categoryItem && categoryItem.length ?
                  <DataTable
                    data={categoryItem}
                    pagination={true}
                    columns={columns}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    customStyles={customStyles}
                    noDataComponent={t(`no_record_msg`)}
                  />
                  :
                  null
                }

              </div>
              {/* </Accordion.Body> */}
            </div>
          </div>
          {/* </Accordion.Item>
          </Accordion> */}

          {/* Preview part */}
          {categoryItem && categoryItem.length ?
            <div className="card mb-3">
              <div className="card-body">
                <div className="row ">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <h4 className="form-heading accordion-button">
                      {t("product_preview")}
                    </h4>
                  </div>
                </div>
                <div className="row ml-0 mr-0">
                  <div className="col-md-6 col-sm-12 col-xs-12 justify-content-end d-flex">
                    <div className="form-group" style={{ width: "300px" }}>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => getResTmenuBg(e.target.value, 'temp')}>
                        <option selected={section2.templateId === 1 ? true : false} value="1">{t("template_1")}</option>
                        <option selected={section2.templateId === 2 ? true : false} value="2">{t("template_2")}</option>
                        <option selected={section2.templateId === 3 ? true : false} value="3">{t("template_3")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 justify-content-start d-flex">
                    <div className="form-group" style={{ width: "300px" }}>
                      <div className="form-group">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => getResTmenuBg(e.target.value, 'bg')}>
                          {
                            categoryBackground && categoryBackground.map(item => (
                              <option
                                key={item.id}
                                selected={data.background_id === item.id ? data.background_id : ''}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center menu-card">
                  <div className="row justify-content-center d-flex">
                    <div className="col-md-12">
                      <div className="container">
                        <Preview result={result} previewKey="listPreview" listPreview={listPreview} selectedTemplete={section2.templateId} />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button type="submit" onClick={() => {
                      getCurrencyExchangeRate()
                      setSavePreview(true)
                      saveQrUrl()
                    }} className="btn btn-danger mt-5">{t("save_product")}</button>
                  </div>
                  {savePreview === true ?
                    <>
                      <QrComponent previewKey="listPreview" listPreview={listPreview} categoryItem={categoryItem} />
                    </>
                    :
                    ""
                  }
                </div>
              </div>
            </div>
            : null}
          {savePreview && userRestData && userRestData.valid_till === null ? (
            <div className="text-center justify-content-center d-flex">
              <button type="button" onClick={() => setShowPayPal(true)} className="btn btn-secondary payment-btn">{t('create_emenu_make_payment')}</button></div>) : null}
          <div className="row ">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <Link to="/my-services"> <span>
                <i className="fa fa-angle-double-left"></i>
                {t("back")}
              </span> </Link>
            </div>
          </div>
        </div>
      </div>
      {show ? <DeleteModel show={show} handleClose={handleClose} DeleteData={deleteData}></DeleteModel> : null}
      {
        showPayPal ? <PayPal showPaypal={showPayPal} handleClose={handleClose1} createOrder={createOrder} onApprove={onApprove} onError={onError} otherData={{ eMenuPrice: eMenuPrice, serviceFor: 'E-Product' }}></PayPal> : null
      }
      {loading ? <Loader /> : <></>}
    </>

  )
}

export default CreateEProduct;
