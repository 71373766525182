import React, { useState } from "react";
import AdminRouter from './adminRouter'
import UserRouter from './userRouter'
import AuthRouter from './authRouter'
import { Cookies } from "react-cookie";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
const IndexRouter = () => {
  const cookies = new Cookies()
  const [userRole, setUserRole] = useState("");
  const navigate = new useNavigate();
  useEffect(() => { }, [userRole])

  const checkFunction = () => {
    if (cookies.get('AllMenuUser')) {
      let data = cookies.get('AllMenuUser')
      if (data.user_role) {
        return data.user_role
      } else {
        return ""
      }
    } else {
      return false;
    }
  }
  const updateuser = (data) => {
    setUserRole(data); // change role of user Admin or User
  };

  const logoutUser = async () => {
    // console.log('in logoutUser');
    setUserRole("");
    cookies.remove('AllMenuToken', { path: '/' });
    cookies.remove('AllMenuUser', { path: '/' });
    // console.log('After logout check cookie',cookies.get('AllMenuUser'))
    navigate('/');
  }

  return (
    <>
      {
        (checkFunction() === "ADMIN") ? (
          <AdminRouter logoutUser={logoutUser} />
        ) : (checkFunction() === 'COMPANY') ?
          <UserRouter logoutUser={logoutUser} /> : 
          <AuthRouter />
      }      
    </>
  )
}

export default IndexRouter;