import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import AdminApi from '../../api/adminApi'
import { useTranslation } from 'react-i18next';
import CustomDataTable from '../../components/customDataTable';
import Helpers from "../../components/helpers";
import { AdminQrModal } from "../../components/adminQrModal";
import { Link, useLocation, useParams } from 'react-router-dom';
const QR_URL = process.env.REACT_APP_QR_URL;

const ServicesPayments = () => {
  const [servicesAndPaymentsData, setServicesAndPaymentsData] = useState([])
  const [searchKey, setSearchKey] = useState(""); //for searching
  const adminApi = new AdminApi();
  const { t } = useTranslation()
  let { userId } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [qrData, setQrData] = useState([])

  useEffect(() => {
    if (userId && userId != null && userId != "") {
      servicesAndPayments(userId)
    } else {
      servicesAndPayments()
    }
  }, [])

  const handleModalShow = ((row) => {
    setQrData(row)
    setModalShow(true)
  })

  const servicesAndPayments = async (userId = null, searchKeyword = null) => {
    let d = {}
    if (searchKeyword !== "") {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = '';
    }
    if (userId !== "") {
      d.userId = userId;
    } else {
      d.userId = '';
    }
    await adminApi.servicesAndPayments(d).then((response) => {
      if (response.data) {
        setServicesAndPaymentsData(response.data);
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      servicesAndPayments(null, key);
    } else {
      setSearchKey("");
      servicesAndPayments(null, key);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    {
      name: 'Service',
      selector: row => row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span> {row.service && row.service !== null ? row.service : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "120px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Invoice ID',
      selector: row => row.invoiceId,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.invoiceId)}
        >
          <span> {row.invoiceId && row.invoiceId !== null ? row.invoiceId : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "170px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Payment',
      selector: row => row.payment,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.payment)}
        >
          <span> {row.payment && row.payment !== null ? row.payment : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "120px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Invoice Date',
      selector: row => row.invoiceDate,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(Helpers.convertUtcToTimezone(row.invoiceDate, "MM-DD-YYYY hh:mm:a"))}
        >
          <span> {row.invoiceDate && row.invoiceDate !== null ? Helpers.convertUtcToTimezone(row.invoiceDate, 'MM-DD-YYYY') : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "120px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Valid Date',
      selector: row => row.validDate,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(Helpers.convertUtcToTimezone(row.validDate, "MM-DD-YYYY hh:mm:a"))}
        >
          <span> {row.validDate && row.validDate !== null ? Helpers.convertUtcToTimezone(row.validDate, 'MM-DD-YYYY') : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "120px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'User Name',
      selector: row => row.first_name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(`${row.first_name}` + ' ' + `${row.last_name}`)}
        >
          <Link to={`/user-profile/uid/${row.userId}`}>
            <span> {row.first_name && row.first_name !== null ? row.first_name : "---"} {row.last_name && row.last_name !== null ? row.last_name : ""}</span>
          </Link>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "150px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Company Name',
      selector: row => row.companyName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.companyName)}
        >
          <span> {row.companyName && row.companyName !== null ? row.companyName : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "150px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Email',
      selector: row => row.email,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.email)}
        >
          <span> {row.email && row.email !== null ? row.email : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "220px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Language',
      selector: row => row.language,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.language)}
        >
          <span> {row.language && row.language !== null ? row.language : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "120px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Country',
      selector: row => row.country,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.country)}
        >
          <span> {row.country && row.country !== null ? row.country : "---"}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "120px",
      style: {
        cursor: "text"
      },
    },
    {
      name: 'Link',
      selector: row => row.scan_code,
      cell: (row) => (
        <i className="fa fa-link" style={{ fontSize: "20px" }} onClick={() => handleModalShow(row)}> </i>
      ),
      sortable: true,
      width: "80px",
    },

    {
      name: t("preview"),
      selector: row => row.preview,
      cell: row => (
        <>
          {
            row.menu_id && row.menu_id !== null ?
              <a target="_blank" href={`/menu/${row.vendor_name}-${row.menu_id}`}>
                <i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
              </a>
              :
              <a target="_blank" href={`/product/${row.vendor_name}-${row.list_id}`}>
                <i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
              </a>
          }
        </>
      ),
      width: "90px"
    },
    {
      name: t('payment_table_col_invoice_pdf'),
      cell: row => (
        <>
          <Link to={`/my-payment/${row.transaction_id}`} target="_blank"
          ><i className="fa fa-file-pdf-o" style={{ fontSize: "24px" }}></i></Link>
          {/* </a> */}
        </>
      ),
      width: "120px"
    }
  ];
  return (
    <div className="cont-bg register-page">
      <div className="container">
        <Row>
          <Col lg={12}>
            <h2>View Users Payments</h2>
            <p>
              {t("admin_payments_desc_p")}
            </p>
          </Col>
        </Row>
        <div className="cont-bg register-page">
          <div className="container">
            <div className="card mb-3">
              <div className="card-body">
                <Row>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <label className="me-3 mb-0 searchSet">
                      <input type="search" onChange={SearchData} className="form-control" placeholder="Search by Keyword" aria-controls="order-listing" />
                      <Button variant="link" onClick={() => servicesAndPayments()}>View All</Button>
                    </label>
                  </Col>
                  <Col md={12} lg={12} sm={12} xs={12} className="mt-3">
                    <CustomDataTable
                      data={servicesAndPaymentsData}
                      pagination={servicesAndPaymentsData.length > 30 ? true : false}
                      columns={columns}
                      pointerOnHover={true}
                      highlightOnHover={true}
                      fixedHeader={true}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShow ?
        <AdminQrModal
          modalShow={modalShow}
          qrData={qrData}
          onHide={() => setModalShow(false)}
        />
        :
        null
      }
    </div>
  );
}

export default ServicesPayments;
