import React, { useEffect } from 'react';
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";

const XmlGenerator = () => {
  const navigate = useNavigate();
  useEffect(() => {
    handleSaveXML()
  }, [])

  const handleSaveXML = async () => {
    let data;
    try {
      const response = await fetch('https://backend.allmenu.eu/sitemap.xml');
      if (response.ok) {
        data = await response.text();
        console.log(data);
      } else {
        console.error('Failed to fetch data:', response.statusText);
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }

    const blob = new Blob([data], { type: 'text/xml;charset=utf-8' });
    saveAs(blob, 'sitemap.xml');
    navigate("/home")

  };

  return (
    <div>
      <button onClick={() => handleSaveXML()} hidden>Save XML</button>
    </div>
  );
}

export default XmlGenerator

// import React, { useEffect,useState } from 'react';
// import { saveAs } from 'file-saver';
// import { useNavigate } from "react-router-dom";
// import XMLParser from "react-xml-parser";
// import XMLViewer from "react-xml-viewer";

// const customTheme = {
//   attributeKeyColor: "#0074D9",
//   attributeValueColor: "#2ECC40"
// };
// const XmlGenerator = () => {
//   const  [state,setState] = useState(null)

//   const navigate = useNavigate();
//   useEffect(() => {
//     handleSaveXML()
//   },[])

//   // let xmlDoc;
//   const handleSaveXML = async () => {
//     let data;
//     try {
//       const response = await fetch('https://backend.allmenu.eu/sitemap.xml');
//       if (response.ok) {
//         data = await response.text();
//         // console.log(data);
//         var parser = new DOMParser();
//         var xmlDoc = parser.parseFromString([data], "application/xml");
//         // var xmlDoc = parser.parseFromString([data], { type: 'text/xml;charset=utf-8' });
//         console.log('xmlDoc',xmlDoc.documentElement)
//         // document.write(xmlDoc.documentElement)
//         setState(xmlDoc.documentElement)
//         const blob = new Blob([data], { type: 'text/xml;charset=utf-8' });
//         // console.log('blob',blob)

//         var XMLParser = require("react-xml-parser");
//         var NewXml = new XMLParser().parseFromString(
//           new XMLSerializer().serializeToString(xmlDoc.documentElement)
//         ); // Assume xmlText contains the example XML
//         console.log("newxml", NewXml);
//         setState(data)

//         // var xmlDoc1=new XML();
//         // xmlDoc1.load(xmlDoc);
//         // console.log('xmlDoc1',xmlDoc1)
//         // document.write(data)
//         // var node = xmlDoc.createElement("Plan");
//         setTimeout(() => {
//           var elem = document.getElementById("xmld");
//           // elem.textContent = NewXml
//           // elem.append(NewXml)
//           // elem.appendChild(blob);
//         }, 2000, NewXml);
//       } else {
//         console.error('Failed to fetch data:', response.statusText);
//       }
//     } catch (error) {
//       console.error('Error while fetching data:', error);
//     }

//     // const blob = new Blob([data], { type: 'text/xml;charset=utf-8' });
//     // saveAs(blob, 'sitemap.xml');
//     // navigate("/home")

//   };

//   return (
//     <>
//       {/* <div>
//         <button onClick={() => handleSaveXML()}>Save XML</button>
//       </div> */}
//       {/* <div id="xmld"></div> */}
//       {/* {state && <span dangerouslySetInnerHTML={{ __html: state }}/>} */}
//       {/* <p>{state}</p>
//        */}
//        <XMLViewer xml={state} theme={customTheme} collapsible />
//        {/* <div>hello</div> */}
// {/* {console.log("state",state && state.children)} */}
//     </>
//   );
// }

// export default XmlGenerator