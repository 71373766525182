import React, {  } from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

  const PaymentPage = () => {
    const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: window.amount,
                //tax: 2 // add the VAT or tax amount here
              },
            },
          ],
        })
      }
    
      const onApprove = (data1, actions) => {
        return actions.order.capture().then(function (res) {
          // save transaction data in db
         window.ReactNativeWebView.postMessage(JSON.stringify({type: 'payment', data: res}))
        });
      }

      const onError = (err) => {
        window.ReactNativeWebView.postMessage(JSON.stringify({type: 'payment', data: "Error"}))
      }
  
    return (
      <div>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "EUR", }}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </PayPalScriptProvider>
      </div>
    );
  };
  
  export default PaymentPage;

