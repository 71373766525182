import React from "react";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-widgets"></div>
    </footer>
  );
};

export default Footer;
