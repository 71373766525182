import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const [selected, setSelected] = useState(localStorage.getItem("i18nextLng"));
  const { t } = useTranslation();
  return (
    <footer className="site-footer">
      <div className="footer-widgets">
        <Container>
          <h2>{t("footer_h2_label")}</h2>
          <Row className="menu-container">
            <Col lg={12}>
              <Row className="mb-5">
                <Col lg={3} md={3} sm={6} xs={12}>
                  <ul>
                    <li>
                      <Link to={`/home`}>{t("footer_link_home")}</Link>
                    </li>
                    <li>
                      <Link to={`/prices`}>{t("footer_link_prices")}</Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={3} md={3} sm={6} xs={12}>
                  <ul>
                    <li>
                      <Link to={`/about-us`}>{t("footer_link_aboutUs")}</Link>
                    </li>
                    <li>
                      <Link to={`/about-our-services`}>
                        {t("footer_link_aboutOurService")}
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={3} md={3} sm={6} xs={12}>
                  <ul>
                    <li>
                      <Link to={`/regulations`}>
                        {t("footer_link_regulation")}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/contact-us`}>{t("footer_link_contactUs")}</Link>
                    </li>
                  </ul>
                </Col>
                <Col
                  lg={3} md={3} sm={12} xs={12}
                  className="text-right contact-icons d-flex justify-content-between ancertags"
                >
                  <a href={`mailto:info@allmenu.eu`} rel="noopener noreferrer">
                    <i className="fa fa-envelope-open p-2"></i>
                  </a>
                  <a href="https://www.pinterest.com/Allmenu_eu" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-pinterest p-2"></i>
                  </a>
                  <a href="https://www.facebook.com/AllMenuQR" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-facebook-f p-2"></i>
                  </a>
                  <a href="https://www.instagram.com/allmenu_qr" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-instagram p-2"></i>
                  </a>
                  <a href="https://www.twitter.com/allmenu_eu" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-twitter p-2"></i>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
