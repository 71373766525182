import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { loadmenu } from "../../../redux/reducer/menuReducer";
import { useDispatch, useSelector } from "react-redux";
import EMenuApi from '../../../api/eMenuApi'
import { toast } from 'react-toastify';
import { Cookies, useCookies } from 'react-cookie';
import GanetatePdf from "../../../components/generatePDF";
import PdfAlert from "../../../components/pdfAlert";
import restDummyLogo from "../../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../../assets/images/no-image.png";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import Base64Img from "../../../components/base64Img";
import CommonApi from '../../../api/commonApi';
import Loader from "../../../components/loader";
import FixedViewport from '../../../components/FixedViewport';

const EMenuPreview = () => {
    const commonApi = new CommonApi();
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    let cookies = new Cookies()
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );
    const { t } = useTranslation();
    let userData = cookies.get('AllMenuUser') || null;
    const [cookie, setCookie] = useCookies(['AllMenuUser']);
    const eMenuApi = new EMenuApi()
    const [result, setResult] = useState([])
    let { menuId } = useParams();
    let previewId = menuId.split('-')[1];
    const dispatch = useDispatch();
    const menuData = useSelector((state) => state && state.menuReducer.menuList && state.menuReducer.menuList.data);
    const [loading, setLoading] = useState(false);
    const [loadingGeneratePdf, setLoadingGeneratePdf] = useState(false);
    useEffect(() => {
        let objData = {
            previewId: previewId,
            login: "login"
        }
        dispatch(loadmenu(objData));
    }, [dispatch]);

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        if (menuData && menuData.length) {
            setShow(true)
            let result = groupBy(menuData, 'section_id')
            setResult(result);
            loadBgImage(menuData[0].background_img);
            setTimeout(() => {
                setLoadingGeneratePdf(false)
            }, 2500);
        }
        else {
            setLoadingGeneratePdf(true)
        }
    }, [menuData]);

    const handlePdfFile = async (blob, name) => {
        // Create a File object from the PDF blob
        const file = new File([blob], name, { type: "application/pdf" });
        // Create a FormData object and append the file
        let menu_id = previewId;
        const formData = new FormData();
        formData.append("menu_id", menu_id);
        formData.append("file", file);
        try {
            await eMenuApi.savePdf(formData);
            console.log("PDF saved on the server successfully");
        } catch (error) {
            console.error("Error saving PDF on the server:", error);
        }
    }

    const loadBgImage = async (url) => {
        try {
            const response = await commonApi.getBase64Image({ imageUrl: url });
            if (response.success && response.data) {
                setBackgroundImageUrl(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const mapData = () => {
        const itemArray = menuData && [...menuData].sort((a, b) => a.section_id - b.section_id);
        const rowsPerSection = menuData[0].template_id === 3 ? 10 : 3; // if temp id is 3 then set rows/page and items/row as required
        const itemsPerRow = menuData[0].template_id === 3 ? 1 : 3;

        const pages = [];
        let currentPage = [];
        let currentSectionId = null;
        let currentRow = [];

        for (let i = 0; i < itemArray.length; i++) {
            const currentItem = { ...itemArray[i] };
            const sectionId = currentItem.section_id;

            // Check if the current section is different from the previous one
            if (sectionId !== currentSectionId) {
                // If the current row is not empty, add it to the current page
                if (currentRow.length > 0) {
                    currentPage.push(currentRow);
                    currentRow = [];
                }

                // Check if the current page is full
                if (currentPage.length === rowsPerSection) {
                    pages.push(currentPage);
                    currentPage = [];
                }

                currentSectionId = sectionId;
                // Add the section heading to the first item of the section
                currentItem.section_heading = currentItem.section_name;
            }

            // Check if the current row is full
            if (currentRow.length === itemsPerRow) {
                currentPage.push(currentRow);
                currentRow = [];
            }

            // Check if the current page is full
            if (currentPage.length === rowsPerSection) {
                pages.push(currentPage);
                currentPage = [];
            }

            // Add the current item to the current row
            currentRow.push(currentItem);
        }

        // Add the last row and page to the pages array if they're not empty
        if (currentRow.length > 0) {
            currentPage.push(currentRow);
        }
        if (currentPage.length > 0) {
            pages.push(currentPage);
        }


        return pages.map((page, pageIndex) => (
            <div className="card page-wrapper" key={pageIndex} style={{ width: "98vw", height: "calc(98vw * 1.414)" }}>
                <div className="card-img-overlay american_cuisine" style={{
                    backgroundImage: `url("${backgroundImageUrl}"`, width: "98vw", height: "calc(98vw * 1.414)"
                }}>
                    <div className="d-block justify-content-center mb-3">
                        {
                            menuData[0].logo_url ?
                                <Base64Img imageUrl={menuData[0].logo_url} alt="Logo" className="logo" />
                                :
                                <img src={restDummyLogo} alt="Logo" className="logo" />
                        }
                        <h2>{menuData[0].vendor_name}
                        </h2>
                    </div>
                    <div className="row url d-flex">
                        <div className="col-md-6">{menuData[0].cousine_name}</div>
                        <div className="col-md-6 ancertags"><a href={`https://${menuData[0].website}`} target="_blank">{menuData[0].website}</a>     </div>

                    </div>
                    {
                        page.map((row, rowIndex) => (
                            <div className="row" key={rowIndex}>
                                {menuData[0].template_id !== 3 && <div className="mb-0" style={{ height: '45px' }}>
                                    {row.length && row[0].section_heading && row[0].section_heading !== "No sections" && <p className="card-text mt-1">{row[0].section_name}</p>}
                                </div>}
                                { // If template id is 3 then render respective ui else other ui
                                    menuData[0].template_id == 3 ? row.map((item, itemIndex) => (
                                        <div className="col-md-12 col-sm-12 col-xs-12 templete3 pb-0">
                                            <div className="mb-0" style={{ height: '45px' }}>
                                                {itemIndex == 0 && item.section_heading && item.section_heading !== "No sections" && <p className="card-text mt-1">{item.section_name}</p>}
                                            </div>
                                            {/* Rendering each item here */}
                                            <div className="container shadow">
                                                <h4>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltip(item.name)}
                                                    >
                                                        <b>{(item.name && item.name.slice(0, 40) + (item.name.length > 40 ? "..." : ""))}</b>
                                                    </OverlayTrigger>
                                                </h4>
                                                <span> {item.code ? "Code:" : null}{item.code}</span>
                                                <div><span>{item.quantity} </span><span>{item.unit_name}</span></div>
                                                {menuData[0].template_id == "2" ? null :
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltip(item.description)}
                                                    >
                                                        <span className="desc" >{item.description && item.description.slice(0, 80) + (item.description.length > 80 ? "..." : "")}</span>
                                                    </OverlayTrigger>
                                                }
                                                <span className="price">{item.currency_symbol + ' ' + item.price}</span>
                                            </div>
                                        </div>
                                    )) :
                                        row.map((item) => (

                                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 justify-content-center d-flex mb-2" key={item.id}>
                                                {/* Rendering each item here */}
                                                <div className={`${menuData[0].template_id === "3" ? "card temp-3" : "card"}`}>
                                                    <div className='image_height'>
                                                        {menuData[0].template_id === "3" ? null : item.image_url ? (<Base64Img imageUrl={item.image_url} alt="Item" />) : <img src={restDummyItemImage} alt="Item" className="logo" />}
                                                    </div>
                                                    <div className="container-fluid">
                                                        <h4>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip(item.name)}
                                                            >
                                                                <b>{(item.name && item.name.slice(0, 15) + (item.name.length > 15 ? "..." : ""))}</b>
                                                            </OverlayTrigger>
                                                        </h4>
                                                        <span className="price">{item.currency_symbol + ' ' + item.price}</span>
                                                        <span> {item.code ? "Code:" : null}{item.code}</span>
                                                        <div><span>{item.quantity} </span><span>{item.unit_name}</span></div>
                                                        {menuData[0].template_id === "2" ? null :
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip(item.description)}
                                                            >
                                                                <span className="desc mt-0">{item.description && item.description.slice(0, 35) + (item.description.length > 35 ? "..." : "")}</span>
                                                            </OverlayTrigger>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        ))}
                    {menuData && menuData.length > 0 ? (
                        <div className="row address">
                            <div className="col-md-6 col-sm-12 col-xs-12 text-left">{menuData[0].address ? `${menuData[0].address}, ` : null}
                                {menuData[0].city_name}, {menuData[0].country_name}
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 mobile_set text-right ">{menuData[0].phone}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        ));
    }
    const pdfHTML = () => {
        return (
            <>
                {
                    Object.keys(result).length ?
                        (<div className="container-fluid preview_main">
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="text-center menu-card">
                                        <div className="row justify-content-center d-flex">
                                            <div className="col-md-12 p-0">
                                                <div className="container-fluid">
                                                    <div className="card">
                                                        {menuData && menuData.length > 0 ? (
                                                            mapData()
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        :
                        null
                }
            </>
        )
    }
    return (
        <>
            <hr style={{
                marginTop: "0"
            }} />
            <div>
            <FixedViewport width={1280} />
                <div className="container">
                    <div className="card border-0">
                        <div className="card-body pb-0">
                            <div className="text-center menu-card">
                                <div className="row justify-content-center d-flex">
                                    <div className="col-md-12 p-0">
                                        <div className="container-fluid pdf_button mb-0">
                                            <button style={{ margin: "-16px 0 0 0" }} className="btn btn-primary downloadPdf" onClick={() => GanetatePdf(`E-Menu_${menuData && menuData[0].vendor_name.replaceAll(' ', '') + '_' + menuData[0].menu_id}.pdf`, document.getElementById("previewPdf"), handlePdfFile)}>
                                                {loadingGeneratePdf ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : t("gen_pdf")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="previewPdf">
                {pdfHTML()}
            </div>
            {loading ? <Loader /> : <></>}
            {show ? <PdfAlert show={show} handleClose={handleClose}></PdfAlert> : null}
        </>
    )
}

export default EMenuPreview;