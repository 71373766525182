import React, { useEffect, useState } from 'react'
import { Form, Container, Row, Col } from 'react-bootstrap';
import AuthApi from '../../api/authApi';
import { toast } from 'react-toastify';
import headerImg from "../../assets/images/header.png"
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next"

const ChangePassword = () => {
  const { t } = useTranslation();
  let history = new useNavigate();
  const location = useLocation();
  const [isError, setIsError] = useState(false);
  const [isSpaceOrSyb, setIsSpaceOrSyb] = useState(false)
  const [validated, setValidated] = useState(false);
  let api = new AuthApi();
  const [data, setData] = useState({
    email: "",
    otp: "",
    newpassword: "",
    re_pass: "",
  })
  const [cp, setCp] = useState(false);

  useEffect(() => {
    const email = location.state.emailaddress;
    if (email) {
      let d = { ...data };
      d.email = email;
      setData(d);
    } else {
      history('/forgetPassword')
    }
  }, [])

  const handleChanges = async (e) => {
    let { name, value } = e.target;
    let d = { ...data }
    d[name] = value;
    if (name === 'newpassword') {
      let regex = /[!@#\\$%\\^&\\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
      const foundSpecialChar = value.match(regex)
      regex = /[0-9]/g;
      const foundNum = value.match(regex)
      regex = /[A-Z]/g;
      const foundUppercase = value.match(regex)
      regex = /[a-z]/g;
      const lowerCase = value.match(regex)
      let regex2 = /[~,\\,<, ,]/g
      const isSpace = value.match(regex2)
      if (isSpace?.length) {
        setIsSpaceOrSyb(true)
      } else {
        setIsSpaceOrSyb(false)
      }
      if (
        value?.length > 8 &&
        foundUppercase?.length &&
        foundSpecialChar?.length &&
        foundNum?.length &&
        lowerCase?.length &&
        !isSpace?.length
      ) {
        setIsError(false)
      } else {
        setIsError(true)
      }
      d.password = value;
    }
    if (name === 're_pass') {
      if (d.re_pass !== d.newpassword) {
        setCp(true)
      } else {
        setCp(false)
      }
    } else if (d.re_pass) {
      if (d.re_pass !== d.newpassword) {
        setCp(true)
      } else {
        setCp(false)
      }
    }
    setData(d);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault()
    event.stopPropagation();
    if (form.checkValidity()) {
      api.resetPassWord(data).then((res) => {
        if (res.success) {
          toast.success(t("password_changed_successfully"))
          history('/login')
        } else {
          toast.error(t("invalid_Otp"))
        }
      }).catch((error) => {
        toast.error(error)
      })
    }
    setValidated(true);
  }

  return (
    <>
      <div className="register-page">
        <Container fluid>
          <Row d-flex m-0>
            <Col lg={12} className="page-header pr-0 pl-0">
              <header className="entry-header">
              <h1>{t("reset_password1")}</h1>
              </header>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cont-bg register-page">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2>
                {t("reset_password")}
              </h2>
              <p>{t("otp_content")}</p>
            </Col>
          </Row>
          <Form noValidate validated={validated} className="pt-3" onSubmit={handleSubmit}>
            <div className="justify-content-center d-flex login">
              <div className="card mb-3">
                <div className="card-body">
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <h4 className="form-heading">
                        Enter OTP:
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} lg={4} sm={8} xs={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          required
                          type="text"
                          placeholder={t("Enter_OTP")}
                          name="otp"
                          value={data.otp} onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("OTP_requried")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          required
                          type="password"
                          placeholder={t("enter_new_password")}
                          name="newpassword"
                          value={data.newpassword}
                          isInvalid={isError}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {
                            data.password === '' ? (
                              t("password_required")
                            ) : isSpaceOrSyb ? (
                              t('not_use_special_character')
                            ) : (
                              <>
                                {t("register_passLength_error")}
                              </>
                            )
                          }
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          required
                          type="password"
                          placeholder={t("re_enter_password")}
                          name="re_pass"
                          value={data.re_pass}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={cp}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("password_not_matching")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} className="justify-content-center d-flex">
                <button type="submit" className="btn btn-danger" disabled={cp ? true : false}>{t("reset_password")}</button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}

export default ChangePassword;








