import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import eMenu from "../../assets/images/e-menu.png"
import myProduct from "../../assets/images/my-products.png"
import AuthApi from '../../api/authApi'
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from 'react-cookie'
import {Helmet} from "react-helmet-async";
import LanguageContext from "../../context/languageContext"
const Index = () => {
  const { t } = useTranslation();
  const authState = useContext(LanguageContext);
  const [price, setPrice] = useState('');
  const [home, sethome] = useState({});
  let cookies = new Cookies()
  let userData = cookies.get('AllMenuUser') || null;
  const authApi = new AuthApi();
  let langCode = localStorage.getItem("i18nextLng");
  const location = useLocation()
  let sp = (location.pathname).split("/")
  let lang = sp[1]

  useEffect(() => {
    fetchData(langCode);
    getLanguage();
  }, [langCode]);

  const getLanguage = (async () => {
    await authApi.getAllLanguages().then((response) => {

      if (lang) {
        let checkLanguage_code = response && response && response.data && response.data.find((item) => item && item.language_code == lang)
        if (checkLanguage_code) {
          // console.log("in lajakkak checkLanguage_code", checkLanguage_code);
          authState.updateLanguage(checkLanguage_code.language_code)
          // navigate(`${checkLanguage_code && checkLanguage_code.language_code}/prices`)
          fetchData(lang);
        }
      }
      else {
        fetchData(langCode);
      }
    })
  })


  useEffect(() => {
    getAllServicePices()
  }, [])

  const getAllServicePices = async () => {
    await authApi.getAllServicePices()
      .then(res => {
        if (res.success) {
          setPrice(res)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }



  const fetchData = () => {
    try {
      authApi
        .getSinglePage({ pid: 6, langCode })
        .then((res) => {
          if (res.success) {
            sethome(res.data);
          } else {
            //toast.error(res.msg)
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) { }
  };

  return (
    <>
      <div className="d-flex">
        <Container fluid>
          <Row className="d-flex m-0">
            <Col lg={6} md={6} sm={12} className="pr-0 pl-0">
              <div className="e-menu-img" style={{
                backgroundImage: `url("${eMenu}")`
              }}>
                <Link to="/emenu"> <button className="btn-menu"> {t('home_EMenu_label')}</button></Link>
              </div>
              <div className="seemore"><a href='/emenu' className='WhiteTxt'>{t('see_more')}</a></div>
              <div className="justify-content-center flex mt-5 mb-5">
                <div className="columns">
                  <div className="plan">
                    <ul className="price">
                      <li className=""><h2>{t('home_EMenu_label')}</h2></li>
                      <li className="grey">{price && price.menu_price && price.menu_price[0] && price.menu_price[0].price} &euro; {t('home_perMonth_label')}</li>
                      <li>{t('home_emenu_plan_validity')}</li>
                      <li>{t('home_emenu_plan_registration')}</li>
                      <li>{t('home_emenu_plan_1EMenu')}</li>
                      <li>{t('home_emenu_plan_EditUpdateGenerate')}</li>
                      <li>{t('home_emenu_PdfPrint')}</li>
                      <li>{t('home_QR_For_Menu')}</li>
                      <li>{t('home_SelectITemToBasket')}</li>
                    </ul>
                    <div className="justify-content-center flex mt-2 mb-2">
                      {userData ? <Link to='/modify-emenu' className="button" >{t('home_selectService_btn')}</Link> :
                        <Link to='/login' className="button" >{t('home_selectService_btn')}</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="pr-0 pl-1">
              <div className="e-products-img" style={{
                backgroundImage: `url("${myProduct}")`
              }}>
                <Link to="/eproduct"> <button className="btn-products">{t('home_MyProduct_btn')}</button></Link>
              </div>
              <div className="seemore"><a href='/eproduct' className='WhiteTxt'>{t('see_more')}</a></div>
              <div className="justify-content-center flex mt-5 mb-5">
                <div className="columns">
                  <div className="plan">
                    <ul className="price">
                      <li className=""><h2>{t('home_ListOFProduct_label')}</h2></li>
                      <li className="grey">{price && price.product_price && price.product_price[0] && price.product_price[0].price}  &euro; {t('home_perMonth_label')}</li>
                      <li>{t("days_product")}</li>
                      <li>{t('home_emenu_plan_registration')}</li>
                      <li>{t('home_product_plan_1Product')}</li>
                      <li>{t('home_emenu_plan_EditUpdateGenerate')}</li>
                      <li>{t('home_product_plan_pdfPrint')}.</li>
                      <li>{t('home_QR_For_Product')}</li>
                      <li>{t('home_SelectITemToBasket')}</li>
                    </ul>
                    <div className="justify-content-center flex mt-2 mb-2">
                      {userData ? <Link to='/modify-eproduct' className="button">{t('home_selectService_btn')}</Link> :
                        <Link to='/login' className="button" >{t('home_selectService_btn')}</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            {home.length > 0 && (
              <>
                <Helmet>
                  <title>{home[0].meta_title}</title>
                  <meta
                    name="description"
                    content={home[0].meta_description}
                  />
                  <meta
                    name="Keywords"
                    content={home[0].meta_keywords}
                  />
                </Helmet>
                {/* <h2>{home[0].title}</h2> */}
                <p
                  dangerouslySetInnerHTML={{ __html: home[0].content }}
                ></p>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>

  )
}
export default Index;