import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../action/menuPreviewAction";

const slice = createSlice({
    name: "menu",
    initialState: {
        menuList: [],
        loading: false,
    },

    reducers: {
        menuRequested: (menu, action) => {
            menu.loading = true;
        },
        menuReceived: (menu, action) => {
            menu.menuList = action.payload;
            menu.loading = false;
        },

        menuRequestFailed: (menu, action) => {
            menu.loading = false;
        },
    },
});

export default slice.reducer;

const { menuRequested, menuReceived, menuRequestFailed } = slice.actions;

export const loadmenu = (data) => (dispatch) => {
    // console.log("data",data);
    if(data.login == "login"){
        return dispatch(
            apiCallBegan({
                url:"/emenu/getAllMenuPreview",
                method:"post",
                data:data,
                onStart: menuRequested.type,
                onSuccess: menuReceived.type,
                onError: menuRequestFailed.type,
            })
        );
    }
    else{
        return dispatch(
            apiCallBegan({
                url:"/enduser/getAllMenuView",
                method:"post",
                data:data,
                onStart: menuRequested.type,
                onSuccess: menuReceived.type,
                onError: menuRequestFailed.type,
            })
        );
    }
};


