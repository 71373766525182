import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SrviceApi from '../../api/serviceApi'
import EMenuApi from '../../api/eMenuApi'
import EProductApi from '../../api/eProductApi'
import { Cookies, useCookies } from 'react-cookie'
import { toast } from 'react-toastify';
import Helpers from '../../components/helpers';

const DashBoard = () => {
  const { t } = useTranslation();
  let [count, setCount] = useState([])
  let [isUserFromEurope, setIsUserFromEurope] = useState(false)
  const [eMenuPrice, setEMenuPrice] = useState('');
  const [eProductPrice, setProductPrice] = useState('');
  const eProductApi = new EProductApi();
  const eMenuApi = new EMenuApi();
  let cookies = new Cookies()
  let userData = cookies.get('AllMenuUser') || null;
  const serviceApi = new SrviceApi();

  useEffect(() => {
    getServicesCount()
    getMenuPrice(userData)
    getProductPrice(userData)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const getServicesCount = async () => {
    await serviceApi.getServicesCount({ user_id: userData.id })
      .then(res => {
        if (res.success) {
          setCount(res)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getMenuPrice = async (data) => {
    await eMenuApi.getServicePrice({ userId: data.id })
      .then(res => {
        if (res.success) {
          if (res.isUserFromEurope) {
            let senObj = {
              price: res.data.price,
              isVAT: res.isUserFromEurope
            }
            let VAT = Helpers.calculateVAT(senObj)

            setEMenuPrice(VAT)
          } else {
            setEMenuPrice(res.data.price)
          }
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getProductPrice = async (data) => {
    await eProductApi.getServicePrice({ userId: data.id })
      .then(res => {
        if (res.success) {
          if (res.isUserFromEurope) {
            let senObj = {
              price: res.data.price,
              isVAT: res.isUserFromEurope
            }
            let VAT = Helpers.calculateVAT(senObj)
            setProductPrice(VAT)
            setIsUserFromEurope(true)
          } else {
            setProductPrice(res.data.price)
            setIsUserFromEurope(false)
          }
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="register-page">
        <div className="container-fluid">
          <div className="row d-flex m-0">
            <div className="col-lg-12 pr-0 pl-0 page-header inner-header {
">
              <header className="entry-header">
                <h1>{t("dashboard")}</h1>
              </header>
            </div>
          </div>
        </div>
      </div>
      <div className="cont-bg register-page">
        <div className="container dashboard">
          <div className="card-columns">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{t("my_menu")}<span className="pull-right"><i className="fa fa-bars" aria-hidden="true"></i></span></h4>
                <p className="card-text">{t("published")}{count && count.menuCount && count.menuCount[0] && count.menuCount[0].menuTotal}</p>
                <p className="card-text">{t("draft")}{count && count.menuDraftCount && count.menuDraftCount[0] && count.menuDraftCount[0].menuDraftTotal}</p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-orange">{t("my_product")} <span className="pull-right"><i className="fa fa-bars" aria-hidden="true"></i></span></h4>
                <p className="card-text">{t("published")}{count && count.productCount && count.productCount[0] && count.productCount[0].productTotal}</p>
                <p className="card-text">{t("draft")}{count && count.productDraftCount && count.productDraftCount[0] && count.productDraftCount[0].productDraftTotal}</p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-black">{t("purchased_services")} <span className="pull-right"><i className="fa fa-bars" aria-hidden="true"></i></span></h4>
                <p className="card-text">{t("total")}{count && count.totalPublishedServices && count.totalPublishedServices[0] && count.totalPublishedServices[0].publishedTotal}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>
                {t("home_selectService_btn")}
              </h2>
              <p>{t("p_service_select")}</p>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <div className="justify-content-center flex mt-3 mb-3 service">
                    <div className="columns">
                      <div className="plan">
                        <ul className="price">
                          <li className=""><h2>{t('home_EMenu_label')}</h2></li>
                          <li className="grey">{eMenuPrice} &euro; {t('home_perMonth_label')}
                            {isUserFromEurope ? t("gross") : null}
                          </li>
                          <li>{t('home_emenu_plan_validity')}</li>
                          <li>{t('home_emenu_plan_registration')}</li>
                          <li>{t('home_emenu_plan_1EMenu')}</li>
                          <li>{t('home_emenu_plan_EditUpdateGenerate')}</li>
                          <li>{t('home_emenu_PdfPrint')}</li>
                          <li>{t('home_QR_For_Menu')}</li>
                          <li>{t('home_SelectITemToBasket')}</li>
                        </ul>
                        <div className="justify-content-center flex mt-2 mb-2">
                          {/* state={{ isNewRest: true }} */}
                          <Link to='/modify-emenu' className="button" >{t('home_selectService_btn')}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <div className="justify-content-center flex mt-3 mb-3 service">
                    <div className="columns">
                      <div className="plan">
                        <ul className="price">
                          <li className=""><h2>{t('home_ListOFProduct_label')}</h2></li>
                          <li className="grey">{eProductPrice} &euro; {t('home_perMonth_label')}                {isUserFromEurope ? t("gross") : null}</li>
                          <li>{t("days_product")}</li>
                          <li>{t('home_emenu_plan_registration')}</li>
                          <li>{t('home_product_plan_1Product')}</li>
                          <li>{t('home_emenu_plan_EditUpdateGenerate')}</li>
                          <li>{t('home_product_plan_pdfPrint')}.</li>
                          <li>{t('home_QR_For_Product')}</li>
                          <li>{t('home_SelectITemToBasket')}</li>
                        </ul>
                        <div className="justify-content-center flex mt-2 mb-2">
                          <Link to='/modify-eproduct' className="button">{t('home_selectService_btn')}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoard;