import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import CustomDataTable from "../../components/customDataTable";
import AdminApi from "../../api/adminApi";
import userApi from "../../api/userApi";
import { toast } from "react-toastify";
import Helpers from "../../components/helpers";
import { Link } from "react-router-dom";
import DeleteModel from "../../components/deleteModel";
// import SideBar from "../../layouts/admin/sideBar";

const ManageRegistrations = () => {
  const [userServiceData, setUserServiceData] = useState([]);
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState(''); //ServiceId
  const handleClose = () => setShow(false);
  const handleShow = (userId) => {
    setUserId(userId);
    setShow(true);
  };

  let adminApi = new AdminApi();
  let UserApi = new userApi();

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async (searchKeyword = null) => {

    let d = {}
		if (searchKeyword !== "") {
			d.searchKeyword = searchKeyword;
		} else {
			d.searchKeyword = '';
		}

    await adminApi
      .getAllUsersList(d)
      .then((response) => {
        if (response.data) {
          setUserServiceData(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      getAllUsers(key);
    } else {
      getAllUsers();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const changeStatus = (id, value) => {
     adminApi.changeUserStatus({
      id: id,
      value: value,
    })
      .then((res) => {
        // console.log("res", res);
        if (value === "INACTIVE") {
          toast.success(`Deactivated Successfully!`);
        } else {
          toast.success(`Activated Successfully!`);
        }
        getAllUsers();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const DeleteData = () => {
    adminApi.deleteAccount({ userId })
      .then((res) => {
        if (res.success) {
          setShow(false);
          toast.success(res.msg);
          getAllUsers();
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const columns = [
    {
      id: "user_name",
      name: "User Name",
      selector: (row) => row && `${row.first_name} ${row.last_name}`,
      cell: (row) => (
        <>
          <div className="d-flex">
            <Link to={`/user-profile/uid/${row.id}`}>
              <span>{`${row.first_name} ${row.last_name}`}</span>
            </Link>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      id: "company_name",
      name: "Company Name",
      selector: (row) => row && row.company_name,
      cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.company_name)}
				>
					<span>
						{row.company_name}
					</span>
				</OverlayTrigger>
			),
      sortable: true,
      width: "170px",
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row && row.email,
      cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.email)}
				>
					<span>
						{row.email}
					</span>
				</OverlayTrigger>
			),
      sortable: true,
      width: "170px",
    },
    {
      id: "language",
      name: "Language",
      selector: (row) => row && row.language,
      sortable: true,
    },
    {
      id: "country",
      name: "Country",
      selector: (row) => row && row.countryName,
      cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.countryName)}
				>
					<span>
						{row.countryName}
					</span>
				</OverlayTrigger>
			),
      sortable: true,
    },
    {
      id: "services_purchased",
      name: "#Services Purchased",
      selector: (row) => row && row.count,
      cell: row => (
        <div className="d-flex">
          <Link to={`/view-services-and-payments/${row.id}`}>
            <span>{row && row.count}</span>
          </Link>
        </div>
      ),
      sortable: true,
     
    },
    {
      id: "registered_on",
      name: "Registered on",
      selector: (row) => row && row.createdAt,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "DD-MM-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row && row.account_status,
      cell: (row) => (
        <Form.Select
          size="sm"
          value={row.account_status}
          onChange={(e) => changeStatus(row.id, e.target.value)}
        >
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </Form.Select>
      ),
      width: "130px",
      sortable: true,
    },
    {
      id: "delete",
      name: "Action",
      cell: (row) => (
        <>
          <div className="d-flex">
            <span onClick={() => handleShow(row && row.id)}>
              <i className="fa fa-times-circle"></i>
            </span>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="cont-bg register-page">
      <div className="container">
      {/* <SideBar/> */}
        <Row>
          <Col lg={12}>
            <h2>Manage Registrations</h2>
            <p>
              Admin can view all registrations, can activate/ deactivate the
              registered users.
            </p>
          </Col>
        </Row>
        <div className="card mb-3">
          <div className="card-body">
            <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
									<label className="me-3 mb-0 searchSet">
										<input type="search" onChange={SearchData} className="form-control" placeholder="Search by Keyword" aria-controls="order-listing" />
									</label>
								</Col>
              <Col md={12} lg={12} sm={12} xs={12} className="mt-3">
                <CustomDataTable
                  data={userServiceData}
                  pagination={userServiceData.length > 30 ? true : false}
                  columns={columns}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  fixedHeader={true}
                />
              </Col>
            </Row>
            {show ? (
              <DeleteModel
                show={show}
                handleClose={handleClose}
                DeleteData={DeleteData}
              ></DeleteModel>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageRegistrations;
