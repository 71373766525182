import React, { useState, useRef, useEffect } from 'react'
import GanetatePdf from "../../components/generatePDF";
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaymentApi from '../../api/paymentApi';
import Helpers from '../../components/helpers';
import { seller, accountInformation, palceOfOrigin } from '../../config';
import menuLogo from "../../assets/images/All-Menu-Logo-HP.png";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import n2words from 'n2words';

const Payment = (props) => {
    const { t } = useTranslation();
    const paymentApi = new PaymentApi();
    const state1 = useLocation();
    let transactionId = state1.pathname.split('/my-payment/')[1]
    let state = state1.state
    const [payment, setPayment] = useState({
        user_name: '',
        invoice_no: '',
        transaction_id: '',
        invoice_date: '',
        amount: '',
        vat: '',
        invoice_status: '',
        valid_till: '',
        user_service_status: '',
        country_id: '',
        vendor_name: '',
        logo_url: '',
        company_name: '',
        user_service_email: '',
        user_service_website: '',
        user_service_phone: '',
        user_services_address: '',
        first_name: '',
        last_name: ' ',
        user_email: '',
        user_mobile: '',
        user_street: '',
        user_zipcode: '',
        user_city: '',
        user_state: '',
        user_website: '',
        user_house_no: '',
        currency_symbol: '',
        continent_code: '',
        service_price: ''
    });
    useEffect(() => {
        if ((state && state.transaction_id) || (transactionId !== null && transactionId !== '')) {
            let send = state && state.transaction_id !== null ? state.transaction_id : transactionId  !== null ? transactionId : null
            getPayment(send);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    const getPayment = async (send) => {
        await paymentApi.getPayment(send)
            .then(res => {
                if (res.success && res.data.length) {
                    toast.success(`Your payment has been ${res.data[0].invoice_status}`);
                    setPayment(res.data[0]);
                } else {
                    toast.error(res.msg)
                }
            })
            .catch(error => {
                toast.error(error)
            })
    }

    const showAmountInWords = (payment, language) => {
        let service_price = Number(payment.service_price);
        let exchange_rate = Number(payment.exchange_rate);
        let amount = language == 'pl' ? service_price * exchange_rate : service_price;
        if(payment.continent_code == "eu") {
            amount = Helpers.calculateVAT({ isVAT: true, price: amount });
        }
        //const amountWords = Helpers.currencyToWords(amount, language);
        const currencyNames = {
            en: 'euros',
            pl: 'złotych'
        };
        const [integerAmt, decimalAmt] = amount.toFixed(2).split('.');
        if(!isNaN(integerAmt)){
            const amountWords = decimalAmt ? n2words(integerAmt, {lang: language})+' '+currencyNames[language]+' '+decimalAmt.toString()+'/100' : n2words(integerAmt, {lang: language}+' '+currencyNames[language]);
            return amountWords;
        }
        
    }

    const handlePdfFile = async (blob, name) => {
        // Create a File object from the PDF blob
        const file = new File([blob], name, { type: "application/pdf" });
        // Create a FormData object and append the file
        let txn_id = transactionId;
        const formData = new FormData();
        formData.append("txn_id", txn_id);
        formData.append("file", file);
        try {
            await paymentApi.savePdf(formData);
            console.log("PDF saved on the server successfully");
        } catch (error) {
            console.error("Error saving PDF on the server:", error);
        }
    }
    
    const savePDF = () => {

    }
    const PDFhtml = () => {
        return (
          <>
            <div className="invoice-box">
                <table cellpadding="0" cellspacing="0">
                    <tr className="top">
                        <td>
                            <table>
                                <tr>
                                    <td className="title">
                                        {/*payment.logo_url?payment.logo_url*/}
                                        <img src={menuLogo} alt="Logo" className="pt-4" />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{
                                    "padding":"0 10px", "textAlign":"center", "fontSize": "17px"
                                    }}>
                                        <div style={{"fontWeight": "bold"}}>VAT Invoice No.: {payment.invoice_no} </div>
                                        <div style={{"fontWeight": "bold"}} className="sm-text">Faktura VAT nr </div>
                                        <div style={{"fontWeight": "bold"}}>ORIGINAL </div>
                                        <div style={{"fontWeight": "bold"}} className="sm-text">ORYGINAŁ </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr className="information">
                        <td>
                            <table>
                                <tr>
                                    <td>
                                        <div>Invoice date: <span style={{ paddingLeft: "16%" }}>{moment(payment.invoice_date).format("YYYY/MM/DD")}</span> </div>
                                        <div className='sm-text'>Data wystawienia </div>
                                        <div>Sell date: <span style={{ paddingLeft: "23%" }}>{moment(payment.invoice_date).format("YYYY/MM/DD")}</span> </div>
                                        <div className='sm-text'>Data sprzedaży </div>
                                        <div>Place of origin: <span>{palceOfOrigin}</span> </div>
                                        <div className='sm-text'>Miejsce wystawienia</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ fontWeight: "bold" }}>Seller: </div>
                                        <div className='sm-text' style={{ lineHeight: 1.5 }}>Sprzedawca </div>
                                        <div><b>{seller.name}</b> </div>
                                        <div>{seller.street} </div>
                                        <div>{seller.adrress} </div>
                                        <div>VAT-Nr: {seller.vatNo}</div>
                                    </td>
                                    <td>
                                        <div style={{ paddingLeft: "265px",fontWeight: "bold" }}>Cutomer: </div>
                                        <div className='sm-text' style={{ lineHeight: 1.5, paddingLeft: "265px" }}>Nabywca </div>
                                        <div style={{ paddingLeft: "265px" }}><b>{payment.first_name}{" "}{payment.last_name}</b> </div>
                                        <div style={{ paddingLeft: "265px" }}>{payment.company_name}</div>
                                        <div style={{ paddingLeft: "265px" }}><b>{payment.first_name}{" "}{payment.user_services_address}</b> </div>
                                        <div style={{ paddingLeft: "265px" }}>{payment.user_tax_number}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <table cellpadding="0" cellspacing="0">
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0">
                                <tr className="heading-blue">
                                    <td style={{ width: "5%" }}>
                                        No.
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Lp.</div>
                                    </td>
                                    <td style={{ width: "20%" }}>
                                        Description
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Nazwa towaru lub usługi</div>
                                    </td>
                                    <td style={{ width: "5%" }}>
                                        Qty
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Ilość</div>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        Price nett
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Cena netto</div>
                                    </td>
                                    <td style={{ width: "11%" }}>
                                        Total nett
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Wartość netto</div>
                                    </td>
                                    <td style={{ width: "9%" }}>
                                        VAT %
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Stawka VAT</div>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        VAT
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Kwota VAT</div>
                                    </td>
                                    <td style={{ width: "9%" }}>
                                        Total VAT
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Wartość VAT</div>
                                    </td>
                                    <td style={{ width: "11%" }}>
                                        Price gross
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Cena brutto</div>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        Total gross
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Wartość brutto</div>
                                    </td>
                                </tr>
                                <tr className="details">
                                    <td>
                                        1
                                    </td>
                                    <td>
                                        {payment.service_type}
                                    </td>
                                    <td>
                                        1
                                    </td>
                                    <td>
                                        {payment.service_price} €
                                    </td>
                                    <td>
                                        {payment.service_price} €
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? payment.vat : 0}
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? Helpers.calculateVATforValue(payment.service_price) : 0}

                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? Helpers.calculateVATforValue(payment.service_price) : 0 /*payment.service_price} {payment.currency_symbol*/}
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? Helpers.calculateVATforValue(payment.service_price) + payment.service_price : payment.service_price} €
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? Helpers.calculateVATforValue(payment.service_price) + payment.service_price : payment.service_price} €
                                    </td>
                                </tr>
                                <tr className="details ">
                                    <td colspan='10'>
                                    </td>
                                </tr>
                                <tr className="heading ">
                                    <td colspan='7'>
                                    </td>
                                    <td>
                                        Total nett
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Wartość netto</div>
                                    </td>
                                    <td>
                                        VAT
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Wartość VAT</div>
                                    </td>
                                    <td>
                                        Total gross
                                        <div className='inv-des' style={{ lineHeight: 1.5 }} >Wartość brutto	</div>
                                    </td>
                                </tr>
                                <tr className="heading">
                                    <td colspan='6'>
                                    </td>
                                    <td>
                                        Total:
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>(EUR)</div>
                                    </td>
                                    <td>
                                        {payment.service_price} €
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? Helpers.calculateVATforValue(payment.service_price) : 0} €
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? Helpers.calculateVAT({ isVAT: true, price: payment.service_price }) : payment.service_price} €
                                    </td>
                                </tr>
                                <tr className="heading">
                                    <td colspan='6'>
                                    </td>
                                    <td>
                                        Total:
                                        <div>(PLN)</div>
                                    </td>
                                    <td>
                                        {((Number(payment.service_price)) * Number(payment.exchange_rate)).toFixed(2)} zł
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? (Number(Helpers.calculateVATforValue(payment.service_price)*Number(payment.exchange_rate))).toFixed(2) : 0} zł
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? (Number(Helpers.calculateVAT({ isVAT: true, price: payment.service_price }))*Number(payment.exchange_rate)).toFixed(2) : (Number(payment.service_price)*payment.exchange_rate).toFixed(2)} zł
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                    </tr>
                    <tr className="information">
                        <td style={{ "padding": "0" }}>
                            <table style={{ "width": "40%" }}>
                                <tr>
                                    <td>
                                        <strong>Amount due:</strong>
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Do zapłaty </div>
                                    </td>
                                    <td>
                                        {payment.continent_code == "eu" ? (Helpers.calculateVAT({ isVAT: true, price: payment.service_price })).toFixed(2) : Number(payment.service_price).toFixed(2)}  €
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>{payment.continent_code == "eu" ? (Helpers.calculateVAT({ isVAT: true, price: payment.service_price })*payment.exchange_rate).toFixed(2) : (Number(payment.service_price)*payment.exchange_rate).toFixed(2)} zł</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>In words:</strong>
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Słownie </div>
                                    </td>
                                    <td>
                                        {showAmountInWords(payment, 'en')}
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>{showAmountInWords(payment, 'pl')}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Payment form:</strong>
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Sposób zapłaty </div>
                                    </td>
                                    <td>
                                        PAYPAL
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Exchange Rate:</strong>
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Kurs wymiany</div>
                                    </td>
                                    <td>
                                        1EUR = {payment.exchange_rate ? payment.exchange_rate : 4.7245}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Exchange rates ref:</strong>
                                        <div className='inv-des' style={{ lineHeight: 1.5 }}>Odniesienie do kursów walut</div>
                                    </td>
                                    <td>
                                    exchangerate-api.com
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr className="information">
                        <td>
                            <strong>Account Information:</strong><br />
                            {accountInformation.bank}<br />
                            {accountInformation.address}<br />
                            PLN : {accountInformation.PLN}<br />
                            EUR : {accountInformation.EUR}
                        </td>
                    </tr>
                </table>

                    <div className="footer-sm-text">
                        Zgodnie z ustawą o podatku od towarów i usług oraz wydanego na jej podstawie rozporządzenia w sprawie wystawiania faktur, nie ma wymogu umieszczania na wysłanych dokumentach pieczątki firmy, ani podpisu osoby wystawiającej. Podstawa prawna: Rozporządzenie
                        Ministra Finansów z dnia 28 listopada 2008 r. w sprawie zwrotu podatku niektórym podatnikom, wystawiania faktur, sposobu ich przechowywania oraz listy towarów i usług, do których nie mają zastosowania zwolnienia od podatków i usług (Dz. U. z dnia 1 grudnia 2008r.)
                        na podstawie art. 87 ust. 11 pkt 3, art. 106 ust 8 pkt 1, art. 113 ust. 14 pkt 2 ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług ( Dz. U. Nr 54, poz. 535, z późn. zm.2)<br /><br />
                        According to the Law on tax on goods and services rendered on the basis of its regulation on the invoices, there is no requirement to affix stamps on documents sent the company or person issuing the signature. Legal basis: Decree of the Minister of Finance dated 28
                        November 2008 on the tax refund to certain taxpayers, invoicing, how they store and the list of goods and services, which are not applicable exemption from taxes and services ( Official Journal of 1 December 2008.) on the basis of Article. 87. 11, point 3, Art. Article 106,
                        paragraph 8, paragraph 1, Article. Paragraph 113. 14 point 2 of the Act of 11 March 2004 on tax on goods and services (Journal of Laws No. 54, item. 535, as amended. Amended2 )
                    </div>
                    <hr />
                    <div>
                        <p style={{ "fontWeight": "bold", "textAlign": "center", "fontSize": "16px" }}>
                            ALLMENU Legnicka 28/82 53-673 Wrocław, Polska  <a href="https://all-menu.eu/home">www.allmenu.eu</a>
                            <br />Tel: +48 512 240 973</p>
                    </div>
                </div>
        <hr className="redlinethick m-auto"></hr>
          </>
        )
    }
    return (
        <><hr style={{
            marginTop: "0"
        }} />
            <div className='container pdf_button2 d-flex'>
                <button style={{ margin: "-16px 0 0 0" }} className="btn btn-primary downloadPdf" onClick={() => GanetatePdf(`Invoice_${payment.vendor_name.replaceAll(' ','')}_${payment.id}`, document.getElementById("invoicePdf"), handlePdfFile)}>{t("download_pdf")}</button>
                <div>
                    <Link to="/my-payments"> <span>
                        <i className="fa fa-angle-double-left"></i>
                        {t("back")}
                    </span> </Link>
                </div>
            </div>
            <div className='invoice-pdf mt-2 mb-4' id="invoicePdf">
                {PDFhtml()}
            </div>
        </>
    )
}
export default Payment;