import React, { useEffect, useState,useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthApi from "../../api/authApi";
import {Helmet} from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageContext from "../../context/languageContext"

const ContactUs = () => {
  const { t } = useTranslation();
  const authState = useContext(LanguageContext);
  const navigate = useNavigate();
  const [contactUs, setContactUs] = useState({});
  let authApi = new AuthApi();
  let langCode = localStorage.getItem("i18nextLng");

  const location = useLocation()
  let sp = (location.pathname).split("/")
  let lang = sp[1]

  useEffect(() => {
    fetchData(langCode);
    getLanguage();
  }, [langCode]);

  const getLanguage = (async () => {
    await authApi.getAllLanguages().then((response) => {
      if (lang) {
        let checkLanguage_code = response && response.data && response.data.find((item) => item && item.language_code == lang)
        if (checkLanguage_code) {
          // console.log("in lajakkak checkLanguage_code", checkLanguage_code);
          authState.updateLanguage(checkLanguage_code.language_code)
          // navigate(`${checkLanguage_code && checkLanguage_code.language_code}/prices`)
          fetchData(lang);
        }
      }
      else {
        fetchData(langCode);
      }
    })
  })

  const fetchData = () => {
    try {
      authApi
        .getSinglePage({ pid: 5, langCode })
        .then((res) => {
          if (res.success) {
            setContactUs(res.data);
          } else {
            //toast.error(res.msg)
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) { }
  };

  return (
    <>
      <div className="register-page">
        <Container fluid>
          <Row className="d-flex m-0">
            <Col lg={12} className="page-header pr-0 pl-0">
              <header className="entry-header">
                <h1>{t("footer_link_contactUs")}</h1>
              </header>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cont-bg register-page">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              {contactUs.length > 0 && (
                <>
                  <Helmet>
                    <title>{contactUs[0].meta_title}</title>
                    <meta
                      name="description"
                      content={contactUs[0].meta_description}
                    />
                    <meta
                      name="Keywords"
                      content={contactUs[0].meta_keywords}
                    />
                  </Helmet>

                  <h2>{contactUs[0].title}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: contactUs[0].content }}
                  ></p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
