import BaseApi from "./baseApi";

class EMenuApi extends BaseApi {

  async saveUserProduct(data) {
    return await this.API({
      url: "/eproduct/saveUserProduct",
      data: data,
      method: "post"
    })
  }

  async getCategoryBackground(data) {
    return await this.API({
      url: "/eproduct/getCategoryBackground",
      method: "post",
      data: data
    })
  }

  async getCategoryUnits(data) {
    return await this.API({
      url: "/eproduct/getCategoryUnits",
      method: "post",
      data: data
    })
  }
  

  async getUserProduct(id) {
    return await this.API({
      url: "/eproduct/getUserProduct",
      method: "post",
      data: id
    })    
  }

  async saveRestBgTempId(data) {
    return await this.API({
      url: "/eproduct/saveRestBgTempId",
      method: "post",
      data: data
    })
  }

  async saveProduct(data) {
    return await this.API({
      url: "/eproduct/saveProduct",
      data: data,
      method: "post"
    })
  }

  async getProducts(data) {
    return await this.API({
      url: "/eproduct/getProducts",
      data: data,
      method: 'post'
    })
  }

  async deleteProductFromList(data) {
    return this.API({
      url: '/eproduct/deleteProductFromList',
      data: data,
      method: 'post'
    })
  }

  async getProductPreview(data) {
    return this.API({
      url: '/eproduct/getProductPreview',
      data: data,
      method: 'post'
    })
  }


  async getServicePrice(data) {
    return this.API({
      url: '/eproduct/getServicePrice',
      method: 'post',
      data: data
    })
  }

  async savePaymentData(data) {
    return this.API({
      url: '/user/savePaymentData',
      data: data,
      method: 'post'
    })
  }

  async getMenuItem(data) {
    return this.API({
      url: '/user/getMenuItem',
      data: data,
      method: 'post'
    })
  }


  async changeOrder(data) {
    return this.API({
      url: '/eproduct/changeOrder',
      data: data,
      method: 'post'
    })
  }

  async savePdf(data) {
    return this.API({
      url: '/eproduct/savePdf',
      data: data,
      method: 'post'
    })
  }

  // async getListSection(data) {
  //   return this.API({
  //     url: '/eproduct/getListSection',
  //     data: data,
  //     method: 'post'
  //   })
  // }
}

export default EMenuApi;