import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Header from "../layouts/admin/header";
import Footer from "../layouts/admin/footer";
import DashBoard from "../pages/admin/dashboard";
import ChangePassword from "../pages/admin/changePassword";
import Cms from "../pages/admin/cms";
import ManageCuisinesCategories from "../pages/admin/manageCuisinesCategories";
import ManageRegistrations from "../pages/admin/manageRegistrations";
import PriceofServices from "../pages/admin/priceofServices";
import ServicesPayments from "../pages/admin/servicesPayments";
import MyProfile from "../pages/user/myProfile";
import EMenuPreview from "../pages/user/e-menu/eMenuPreview";
import EProductPreview from "../pages/user/e-product/eProductPreview";
import Payment from "../pages/user/payment";
import UnpaidUsers from "../pages/admin/unPaidUsers";
import EProductView from "../pages/common/eProductView";
import EMenuView from "../pages/common/eMenuView";
const AdminRouter = ({ logoutUser = null }) => {
  const route = useRoutes([
    { path: "/manage-registrations", element: <ManageRegistrations /> },
    { path: "/view-services-and-payments", element: <ServicesPayments /> },
    { path: "/view-unpaid-users", element: <UnpaidUsers /> },
    { path: "/view-services-and-payments/:userId", element: <ServicesPayments /> },
    { path: "/cms", element: <Cms /> },
    { path: "/update-price-of-services", element: <PriceofServices /> },
    {
      path: "/manage-cuisines-&-categories",
      element: <ManageCuisinesCategories />,
    },
    { path: "/change-password", element: <ChangePassword /> },
    { path: "/dashBoard", element: <DashBoard /> },
    { path: '/user-profile/uid/:userId', element: <MyProfile /> },
    { path: '/menu/:menuId', element: <EMenuPreview /> },
    { path: '/product/:productId', element: <EProductPreview /> },
    { path: '/my-payment', element: <Payment /> },
    { path: '/my-payment/:transactionId', element: <Payment /> },
    { path: '/e-menu/:menuId', element: <EMenuView /> },
    { path: '/e-product/:productId', element: <EProductView /> },
    { path: "/*", element: <Navigate to="/manage-registrations" /> },
  ]);
  let pathName = route
  if (pathName.props.match.route.path === "/menu/:menuId" || pathName.props.match.route.path === "/product/:productId" || pathName.props.match.route.path === "/e-menu/:menuId" || pathName.props.match.route.path === "/e-product/:productId") {
    return (
      <>
        {route}
      </>
    );
  }
  return (
    <>
      <Header logoutUser={logoutUser} />
      {route}
      <Footer />
    </>
  );
};

export default AdminRouter;
