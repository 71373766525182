import BaseApi from "./baseApi";
class UserApi extends BaseApi {
  // async resetPassWord(data) {
  //   return await this.API({
  //     url: "user/changePassword",
  //     data: data,
  //     method: 'post'
  //   })
  // }

  async editProfile(data) {
    return await this.API({
      url: "user/update-profile",
      data: data,
      method: "post",
    });
  }

  async getProfile(data) {
    return await this.API({
      url: "user/get-profile",
      data: data,
      method: "post",
    });
  }

  async getCity(data) {
    return await this.API({
      url: "user/get-city",
      data: data,
      method: "post",
    });
  }

  async passwordChangeAfterLogin(data) {
    return await this.API({
      url: "user/update-password",
      data: data,
      method: "post",
    });
  }


  async passwordChangeAdmin(data) {
    return await this.API({
      url: "user/passwordChangeAdmin",
      data: data,
      method: "post",
    });
  }



  async deleteAccount(data) {
    return await this.API({
      url: "user/delete-account",
      data: data,
      method: "post",
    });
  }

  async getUserCurrency(data) {
    return await this.API({
      url: "/user/getUserCurrency",
      data: data,
      method: "post",
    });
  }

  async getAllCurrency(data) {
    return await this.API({
      url: "/user/getAllCurrency",
      method: "post",
    });
  }
}

export default UserApi;
