
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { loadproduct } from "../../../redux/reducer/productReducer";
import { useDispatch, useSelector } from "react-redux";
import EProductApi from '../../../api/eProductApi'
import { toast } from 'react-toastify';
import { Cookies, useCookies } from 'react-cookie';
import GanetatePdf from "../../../components/generatePDF";
import restDummyLogo from "../../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../../assets/images/no-image.png";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import Base64Img from '../../../components/base64Img';
import CommonApi from '../../../api/commonApi';
import Loader from "../../../components/loader";
import PdfAlert from "../../../components/pdfAlert";
import FixedViewport from '../../../components/FixedViewport';
const EProductPreview = () => {
    const commonApi = new CommonApi();
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );
    let cookies = new Cookies()
    const { t } = useTranslation();
    let userData = cookies.get('AllMenuUser') || null;
    const [cookie, setCookie] = useCookies(['AllMenuUser']);
    const eProductApi = new EProductApi()
    const [resultList, setListResult] = useState([])
    const [loadingGeneratePdf, setLoadingGeneratePdf] = useState(false);
    let { productId } = useParams();
    let previewId = productId.split('-')[1];
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const listData = useSelector((state) => state && state.productReducer.productList && state.productReducer.productList.data);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        let objData = {
            previewId: previewId,
            login: "login"
        }
        dispatch(loadproduct(objData));
    }, [dispatch]);

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    useEffect(() => {
        if (listData && listData.length) {
            setShow(true)
            let result = groupBy(listData, 'id');
            setListResult(result);
            loadBgImage(listData[0].background_img);
            setTimeout(() => {
                setLoadingGeneratePdf(false)
            }, 2500);
        }
        else {
            setLoadingGeneratePdf(true)
        }
    }, [listData]);

    const handlePdfFile = async (blob, name) => {
        // Create a File object from the PDF blob
        const file = new File([blob], name, { type: "application/pdf" });
        // Create a FormData object and append the file
        let product_id = previewId;
        const formData = new FormData();
        formData.append("product_id", product_id);
        formData.append("file", file);
        try {
            await eProductApi.savePdf(formData);
            console.log("PDF saved on the server successfully");
        } catch (error) {
            console.error("Error saving PDF on the server:", error);
        }
    }

    const loadBgImage = async (url) => {
        try {
            const response = await commonApi.getBase64Image({ imageUrl: url });
            if (response.success && response.data) {
                setBackgroundImageUrl(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const mapListData = () => {
        const array = Object.keys(resultList).reduce((acc, key) => {
            return acc.concat(resultList[key]);
        }, []);

        const itemArray = [...array].sort((a, b) => a.id - b.id);
        const rowsPerSection = listData[0].template_id === "3" ? 8 : 3;
        const itemsPerRow = listData[0].template_id === "3" ? 1 : 3;

        const pages = [];
        let currentPage = [];

        for (let i = 0; i < itemArray.length; i++) {
            const currentItem = { ...itemArray[i] };

            // Check if the current page is full
            if (currentPage.length === rowsPerSection) {
                pages.push(currentPage);
                currentPage = [];
            }

            // Check if the current row is full
            if (currentPage.length < rowsPerSection) {
                let currentRow = [];

                // Add items to the current row until it's full
                while (currentRow.length < itemsPerRow && i < itemArray.length) {
                    currentRow.push(itemArray[i]);
                    i++;
                }

                // Add the current row to the current page
                currentPage.push(currentRow);
                i--;
            }
        }

        // Add the last page to the pages array if it's not empty
        if (currentPage.length > 0) {
            pages.push(currentPage);
        }

        return pages.map((page, pageIndex) => (
            <div className="card page-wrapper" key={pageIndex} style={{ width: "98vw", height: "calc(98vw * 1.414)" }}>
                <div className="card-img-overlay american_cuisine" style={{
                    backgroundImage: `url("${backgroundImageUrl}"`, width: "98vw", height: "calc(98vw * 1.414)"
                }}>
                    <div className="d-block justify-content-center mb-3">
                        {
                            listData[0].logo_url ?
                                <Base64Img imageUrl={listData[0].logo_url} alt="Logo" className="logo" />
                                :
                                <img src={restDummyLogo} alt="Logo" className="logo" />
                        }
                        <h2>{listData[0].vendor_name}
                        </h2>
                        <div className="d-block fix_height ancertags"><a href={`https://${listData[0].website}`} target="_blank">{listData[0].website}</a></div>
                    </div>
                    {
                        page.map((row, rowIndex) => (
                            <div className="row" key={rowIndex}>
                                {listData[0].template_id !== 3 && <div className="mb-0" style={{ height: '20px' }}></div>}
                                { // If template id is 3 then render respective ui else other ui
                                    listData[0].template_id == "3" ? row.map((item) => (
                                        <div className="col-md-12 col-sm-12 col-xs-12 templete3 pb-0">
                                            <div className="mb-2" style={{ height: '20px' }}>
                                                {/*row.length && row[0].section_heading && row[0].section_heading !== "No sections" && <p className="card-text">{row[0].section_name}</p>*/}
                                            </div>

                                            <div className="container shadow">
                                                <span className='text-sm-10'>{item.sec_name}</span>
                                                <h4>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltip(item.name)}
                                                    >
                                                        <b>{(item.name && item.name.slice(0, 40) + (item.name.length > 40 ? "..." : ""))}</b>
                                                    </OverlayTrigger>

                                                    <div className='text-sm-12'> {item.code ? "Code:" : null}{item.code}</div>
                                                </h4>
                                                {listData[0].template_id == "2" ? null :
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltip(item.description)}
                                                    >
                                                        <span className="desc">{(item.description && item.description.slice(0, 90) + (item.description.length > 90 ? "..." : ""))}</span>
                                                    </OverlayTrigger>

                                                }
                                                <div className='price_unit' >
                                                    <span className="price1">{item.currency_symbol + ' ' + item.price}</span>
                                                    <span className="quantity1"> {item.quantity} {item.unit_name} </span>
                                                </div>
                                            </div>
                                        </div>
                                    )) :
                                        row.map((item) => (
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 justify-content-center d-flex mb-2">
                                                <div className={`${listData[0].template_id == "3" ? "card temp-3" : "card cardHeight"}`}>
                                                    <div className='image_height'>
                                                        {listData[0].template_id == "3" ? null : item.image_url ? (<Base64Img imageUrl={item.image_url} alt="Item" />) : <img src={restDummyItemImage} alt="Item" className="logo" style={{ height: "auto" }} />}
                                                    </div>
                                                    <div className="container-fluid">

                                                        <div className='sec_code'>
                                                            <span className='text-sm-12 text-left'>{item.sec_name}</span>
                                                            <span className='text-sm-12 text-right'> {item.code ? "Code:" : null}{item.code}</span>
                                                        </div>
                                                        <h4 className='item-h4'>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip(item.name)}
                                                            >
                                                                <b>{(item.name && item.name.slice(0, 15) + (item.name.length > 15 ? "..." : ""))}</b>
                                                            </OverlayTrigger>
                                                        </h4>
                                                        <div className='justify-content-center d-flex text-center' ><span className="price1">{item.currency_symbol + ' ' + item.price}</span>
                                                            <span className="quantity"> {item.quantity} {item.unit_name} </span>
                                                        </div>

                                                        {listData[0].template_id == "2" ? null :
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip(item.description)}
                                                            >
                                                                <span className="desc">{item.description && item.description.slice(0, 35) + (item.description.length > 35 ? "..." : "")}</span>
                                                            </OverlayTrigger>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        ))}
                    {listData && listData.length > 0 ? (
                        <div className="row address">
                            <div className="col-md-6 col-sm-12 col-xs-12 text-left">
                                {listData[0].address ? `${listData[0].address}, ` : null}
                                {listData[0].city_name}, {listData[0].country_name}
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 mobile_set text-right ">{listData[0].phone}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        ));
    }

    const pdfHTML = () => {
        return (
            <>
                {
                    Object.keys(resultList).length ?
                        (<div className="container-fluid preview_main">
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="text-center menu-card">
                                        <div className="row justify-content-center d-flex">
                                            <div className="col-md-12 p-0">
                                                <div className="container-fluid">
                                                    <div className="card">
                                                        {listData && listData.length > 0 ? (
                                                            mapListData()
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        :
                        null
                }
            </>
        )
    }
    return (
        <>
            <hr style={{
                marginTop: "0"
            }} />
            <div>
            <FixedViewport width={1280} />
                <div className="container-fluid">
                    <div className="card border-0">
                        <div className="card-body pb-0">
                            <div className="text-center menu-card">
                                <div className="row justify-content-center d-flex">
                                    <div className="col-md-12 p-0">
                                        <div className="container-fluid pdf_button mb-0">
                                            <button style={{ margin: "-16px 0 0 0" }} className="btn btn-primary downloadPdf" onClick={() => GanetatePdf(`E-Product_${listData && listData[0].vendor_name.replaceAll(' ', '') + '_' + listData[0].list_id}.pdf`, document.getElementById("previewPdf"), handlePdfFile)}>   {loadingGeneratePdf ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : t("gen_pdf")} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="previewPdf">
                {pdfHTML()}
            </div>
            {loading ? <Loader /> : <></>}
            {show ? <PdfAlert show={show} handleClose={handleClose}></PdfAlert> : null}
        </>
    )
}

export default EProductPreview;

