
const languageMap = {
  en: { flag:"&#127468;&#127463;&emsp;",label: "ENGLISH", dir: "ltr", code: "US", active: true},
  de: { flag:"&#127465;&#127466;&emsp;", label: "GERMANY", dir: "ltr", code: "DE" },
  es: { flag:"&#127466;&#127462;&emsp;", label: "SPANISH", dir: "ltr", code: "ES" },
  tr: { flag:"&#127481;&#127479;&emsp;", label: "TURKISH", dir: "ltr", code: "TR" },
  fr: { flag:"&#127467;&#127479;&emsp;", label: "FRENCH", dir: "ltr", code: "FR" },
  ru: { flag:"&#127479;&#127482;&emsp;", label: "RUSSIAN", dir: "ltr", code: "RU" },
  pt: { flag:"&#127477;&#127481;&emsp;", label: "PORTUGESE", dir: "ltr", code: "PT" },
  ar: { flag:"&#127480;&#127462;&emsp;", label: "ARABIC", dir: "rtl", code: "SA" },
  pl: { flag:"&#127470;&#127465;&emsp;", label: "POLISH", dir: "ltr", code: "PL" },
};

const accountInformation = {
  bank:"SANTANDER BANK SA",
  address:"Rynek 9/11, 50-950 Wroclaw, poland",
  PLN:"68 1090 2516 0000 0001 3646 4125",
  EUR:"PL75 1090 2516 0000 0001 3646 5939"
}

const palceOfOrigin="Wrocław";

const seller = {
  name:"All T&M Sp. z o.o.",
  vatNo:"PL 8971786438",
  street:"Legnicka 28/82",
  adrress:"53-673 Wrocław, Polska"
}

const vat = {
  VAT: 23
}

module.exports = {
  languageMap,
  accountInformation,
  seller,
  palceOfOrigin,
  vat
}
