import React from "react";
import { Navigate, useRoutes } from 'react-router-dom'
import Header from '../layouts/users/header'
import Footer from '../layouts/users/footer'
import FrontEndUser from '../pages/auth/index'
import Registration from "../pages/auth/registration";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword";
import RegisEmailVerify from "../pages/auth/regisEmailVerify";
import ChangePassword from "../pages/auth/changePassword";
import Prices from "../pages/auth/prices";
import AboutUs from "../pages/auth/aboutUs";
import AboutOurServices from "../pages/auth/aboutOurServices";
import Regulations from "../pages/auth/regulations";
import ContactUs from "../pages/auth/contactUs";
import TermsAndCondition from "../pages/common/termsAndCondition";
import AllEMenu from "../pages/common/allemenu";
import AllEProduct from "../pages/common/alleproduct";
import ShoppingCart from "../pages/common/shoppingCart";
import EMenuView from "../pages/common/eMenuView";
import EProductView from "../pages/common/eProductView";
import NotFount from "../pages/common/notFount";
import DownloadPdf from "../pages/common/downloadPdf";
import XMLGenerator from "../pages/common/xmlGenerator";
import AppleAppSiteAssociation from "../pages/common/appleAppSiteAssociation";
import Payment from "../pages/auth/externalPayment";

const AuthRouter = () => {
  const route = useRoutes([
    { path: '/home', element: <FrontEndUser /> },
    { path: '/:lang/home', element: <FrontEndUser /> },
    { path: '/register', element: <Registration /> },
    { path: ':lang/register', element: <Registration /> },
    { path: '/login', element: <Login /> },
    { path: '/:lang/login', element: <Login /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/:lang/forgot-password', element: <ForgotPassword /> },
    { path: '/regis-emailverify', element: <RegisEmailVerify /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: "/:lang/prices", element: <Prices /> },
    { path: "/:lang/about-us", element: <AboutUs /> },
    { path: "/:lang/about-our-services", element: <AboutOurServices /> },
    { path: "/:lang/regulations", element: <Regulations /> },
    { path: "/:lang/contact-us", element: <ContactUs /> },
    { path: "/prices", element: <Prices /> },
    { path: "/about-us", element: <AboutUs /> },
    { path: "/about-our-services", element: <AboutOurServices /> },
    { path: "/regulations", element: <Regulations /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: '/.well-known/apple-app-site-association', element: <AppleAppSiteAssociation />},
    { path: '/*', element: <Navigate to="/home" /> },
    { path: '/termsAndCondition', element: <TermsAndCondition /> },
    { path: '/emenu', element: <AllEMenu /> },
    { path: '/emenu/:language', element: <AllEMenu /> },
    { path: '/emenu/:language/:country', element: <AllEMenu /> },
    { path: '/emenu/:language/:country/:city', element: <AllEMenu /> },
    { path: '/emenu/:language/:country/:city/:restname', element: <AllEMenu /> },
    { path: '/eproduct', element: <AllEProduct /> },
    { path: '/eproduct/:language', element: <AllEProduct /> },
    { path: '/eproduct/:language/:country', element: <AllEProduct /> },
    { path: '/eproduct/:language/:country/:city', element: <AllEProduct /> },
    { path: '/eproduct/:language/:country/:city/:restname', element: <AllEProduct /> },
    { path: '/shopping-cart', element: <ShoppingCart /> },
    { path: '/e-menu/:menuId', element: <EMenuView /> },
    { path: '/e-product/:productId', element: <EProductView /> },
    { path: '/not-found', element: <NotFount /> },
    { path: '/sitemap.xml', element: <XMLGenerator /> },
    { path: '/payment', element: <Payment />}
  ])
  let pathName = route
  if (pathName.props.match.route.path === "/e-menu/:menuId" || pathName.props.match.route.path === "/e-product/:productId" || pathName.props.match.route.path === "/my-payment" || pathName.props.match.route.path === "/shopping-cart" || pathName.props.match.route.path === "/sitemap.xml" || pathName.props.match.route.path === "/payment") {
    return (
      <>
        {route}
      </>
    );
  }
  return (
    <>
      <Header />
      {route}
      <Footer />
    </>
  );
};


export default AuthRouter