import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";
const QR_URL = process.env.REACT_APP_QR_URL;

const QrComponent = (props) => {
  const { t } = useTranslation();
  const qrRef = useRef();
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={props && props.previewKey == "listPreview" ? `${QR_URL}/e-product/${props[props.previewKey] &&
        props[props.previewKey][0] &&
        props[props.previewKey][0].vendor_name.split(" ").join("")
        }-${props[props.previewKey] &&
        props[props.previewKey][0] &&
        props[props.previewKey][0].id
        }` : `${QR_URL}/e-menu/${props[props.previewKey] &&
        props[props.previewKey][0] &&
        props[props.previewKey][0].vendor_name.split(" ").join("")
        }-${props[props.previewKey] &&
        props[props.previewKey][0] &&
        props[props.previewKey][0].id
      }`}
      size={256}
      style={{ height: "130px", width: "130px" }}
      level={"H"}
    />
  );
  return (
    <div className="row justify-content-center text-center qrcode d-flex mt-5  pl-5 pr-5">
      <div className="col-md-6 col-sm-12">
        <div className="card">
          <h3>QR Code:</h3>
          <div ref={qrRef}>{qrcode}</div>
          <form onSubmit={downloadQRCode}>
            <input type="text" hidden />
            <button
              disabled={!props[props.previewKey]}
              type="submit"
              className="btn btn-secondary"
            >
              {t("download_qr")}
            </button>
          </form>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="card p-3">
          <h3>{t("link")}</h3>
          <div>
            {
              props && props.previewKey == "listPreview" ? (<a target="_blank" href={`/product/${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name.split(" ").join("")}-${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].id}`}>
                {`${QR_URL}/product/${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name.split(" ").join("")}-${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].id}`}
              </a>) :
                <a target="_blank" href={`/menu/${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name.split(" ").join("")}-${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].id}`}>
                  {`${QR_URL}/menu/${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name.split(" ").join("")}-${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].id}`}</a>
            }
          </div>
          <p>{t("generate_pdf_instruction")}</p>
        </div>
      </div>
    </div>
  );
};

export default QrComponent;
