
import React from 'react'
import restDummyLogo from "../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../assets/images/no-image.png";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Preview = (props) => {
    // console.log("props", props);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );
    const mapData = () => {
        return Object.keys(props.result).map((key, type) => {
            let array = props.result[key];
            return array.map((item, i) => {
                return (
                    <>
                        {props && props.previewKey === "listPreview" ? null :
                            item.section_id == "1" ? null :
                                (!item.section_id ? null : i == 0 ? (<div><p>{item.type_name}</p></div>) : null)
                        }
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 justify-content-center d-flex mb-4">
                            <div className={`${props.selectedTemplete == "3" ? "card temp-3" : `${props && props.previewKey === "listPreview" ? "card cardHeight" : "card"}`}`}>
                                <div className='image_height'>
                                    {props.selectedTemplete == "3" ? null : item.image_url ? (<img src={item.image_url} alt="Item" />) : <img className="logo" src={restDummyItemImage} alt="Logo" style={{ height: "auto" }} />}
                                </div>
                                <div className="container">
                                    {/* {props && props.previewKey === "listPreview" ? <span className='text-sm-10'>{item.type_name}</span> : null
                                    } */}

                                    {props && props.previewKey === "listPreview" ?
                                        <div className='sec_code'>
                                            <span className='text-sm-12 text-left'>{item.type_name}</span>
                                            <span className='text-sm-12 text-right'> {item.code ? "Code:" : null}{item.code}</span>
                                        </div> : null}
                                    <h4><OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip(item.name)}
                                    >
                                        <b>{(item.name && item.name.slice(0, 15) + (item.name.length > 15 ? "..." : ""))}</b>
                                    </OverlayTrigger>
                                        {props && props.previewKey === "listPreview" ? null :
                                            <div className='text-sm-12'> {item.code ? "Code:" : null}{item.code}</div>}
                                    </h4>
                                    {props && props.previewKey === "listPreview" ?
                                        <div className='justify-content-center d-flex text-center' ><span className="price1">{item.currency_symbol + ' ' + item.price}</span>
                                            <span className="quantity"> {item.quantity} {item.unit_name} </span>
                                        </div>
                                        : null}

                                    {props && props.previewKey === "listPreview" ? null :
                                        <span className="price">{item.currency_symbol + ' ' + item.price}</span>}

                                    {props.selectedTemplete == "2" ? null :
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip(item.description)}
                                        >
                                            <span className="desc">{item.description && item.description.slice(0, 35) + (item.description.length > 35 ? "..." : "")}</span>
                                        </OverlayTrigger>
                                    }
                                    {/* <div className='justify-content'>
                                        {props && props.previewKey === "listPreview" ? <span className="quantity"> {item.quantity} {item.unit_name} </span> : null}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </>
                )
            })
        })
    }
    const mapData1 = () => {
        return Object.keys(props.result).map((key, type) => {
            let array = props.result[key];
            return array.map((item, i) => {
                return (
                    <>
                        {props && props.previewKey === "listPreview" ? null :
                            item.section_id == "1" ? null :
                                (!item.section_id ? null : i == 0 ? (<div><p>{item.type_name}</p></div>) : null)
                        }
                        <div className="col-md-12 col-sm-12 col-xs-12 templete3">
                            <div className="container shadow">
                                {props && props.previewKey === "listPreview" ? <span className='text-sm-10'>{item.type_name}</span> : null
                                }
                                <h4>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip(item.name)}
                                    >
                                        <b>{(item.name && item.name.slice(0, 40) + (item.name.length > 40 ? "..." : ""))}</b>
                                    </OverlayTrigger>
                                </h4>
                                <div className='text-sm-12'> {item.code ? "Code:" : null}{item.code}</div>
                                {props.selectedTemplete == "2" ? null :
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip(item.description)}
                                    >
                                        <span className="desc" >{item.description && item.description.slice(0, 80) + (item.description.length > 80 ? "..." : "")}</span>
                                    </OverlayTrigger>

                                }
                                {props && props.previewKey === "listPreview" ?
                                    (<div className='price_unit' >
                                        <span className="price1">{item.currency_symbol + ' ' + item.price}</span>
                                        {props && props.previewKey === "listPreview" ? <span className="quantity1"> {item.quantity} {item.unit_name} </span> : null}
                                    </div>) : (<div className='justify-content' ><span className="price">{item.currency_symbol + ' ' + item.price}</span>
                                        {props && props.previewKey === "listPreview" ? <span className="quantity"> {item.quantity} {item.unit_name} </span> : null}
                                    </div>)}
                            </div>
                        </div>
                    </>
                )
            })
        })

    }
    if (props.selectedTemplete == "3") {
        return (
            <>
                <div className="card caprops\.menuPreviewrd">
                    <div className="card-img-overlay american_cuisine" style={{
                        backgroundImage: `url("${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].background_img}")`
                    }}>
                        <div className="d-block justify-content-center mb-3">
                            {
                                props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].logo_url ?
                                    <img className="logo" src={props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].logo_url} alt="Logo" />
                                    :
                                    <img className="logo" src={restDummyLogo} alt="Logo" />
                            }
                            <h2>{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name}
                            </h2>
                        </div>
                        {props && props.previewKey === "listPreview" ? <div className="d-block fix_height ancertags"><a href={`https://${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}`} target="_blank">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}</a></div> : null
                        }
                        {props && props.previewKey === "listPreview" ? null :
                            <div className="row url d-flex">
                                <div className="col-md-6">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].name}</div>
                                <div className="col-md-6 ancertags"><a className='setAncer' href={`https://${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}`} target="_blank">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}</a></div>
                            </div>
                        }
                        <div className="row mb-5">
                            {mapData1()}
                        </div>
                        <div className="row address">
                            <div className="col-md-6 col-sm-12 col-xs-12 text-left">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].address ? `${props[props.previewKey][0].address}, ` : null}
                                {props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].city_name}, {props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].country_name}
                            </div>
                            <div className='col-md-6 col-sm-12 col-xs-12 mobile_set text-right'>{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].phone}</div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
    else if (props.selectedTemplete == "2") {
        return (
            <>
                <div className="card">
                    <div className="card-img-overlay american_cuisine" style={{
                        backgroundImage: `url("${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].background_img}")`
                    }}>
                        <div className="d-block justify-content-center mb-3">
                            {
                                props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].logo_url ?
                                    <img className="logo" src={props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].logo_url} alt="Logo" />
                                    :
                                    <img className="logo" src={restDummyLogo} alt="Logo" />
                            }
                            <h2>{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name}
                            </h2>
                        </div>
                        {props && props.previewKey === "listPreview" ? <div className="d-block fix_height ancertags">
                            <a href={`https://${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}`} target="_blank">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}</a>
                        </div> : null
                        }
                        {props && props.previewKey === "listPreview" ? null :
                            <div className="row url d-flex">
                                <div className="col-md-6">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].name}</div>
                                <div className="col-md-6 ancertags"><a href={`https://${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}`} target="_blank">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}</a></div>
                            </div>
                        }
                        <div className="row mb-5">
                            {mapData()}
                        </div>
                        <div className="row address">
                            <div className="col-md-6 col-sm-12 col-xs-12 text-left">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].address ? `${props[props.previewKey][0].address}, ` : null}
                                {props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].city_name}, {props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].country_name}</div>
                            <div className='col-md-6 col-sm-12 col-xs-12 mobile_set text-right '>{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].phone}</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div className="card">
                    <div className="card-img-overlay american_cuisine" style={{
                        backgroundImage: `url("${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].background_img}")`
                    }}>
                        <div className="d-block justify-content-center mb-3">
                            {
                                props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].logo_url ?
                                    <img className="logo" src={props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].logo_url} alt="Logo" />
                                    :
                                    <img className="logo" src={restDummyLogo} alt="Logo" />
                            }
                            <h2>{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].vendor_name}
                            </h2>
                        </div>
                        {props && props.previewKey === "listPreview" ? <div className="d-block fix_height ancertags"><a href={`https://${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}`} target="_blank">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}</a></div> : null
                        }
                        {props && props.previewKey === "listPreview" ? null :
                            <div className="row url d-flex">
                                <div className="col-md-6">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].name}</div>
                                <div className="col-md-6 d-block ancertags"><a href={`https://${props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}`} target="_blank">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].website}</a></div>
                            </div>
                        }
                        <div className="row mb-5">
                            {mapData()}
                        </div>
                        <div className="row address">
                            <div className="col-md-6 col-sm-12 col-xs-12 text-left">{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].address ? `${props[props.previewKey][0].address}, ` : null}
                                {props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].city_name}, {props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].country_name}</div>
                            <div className='col-md-6 col-sm-12 col-xs-12 mobile_set text-right'>{props[props.previewKey] && props[props.previewKey][0] && props[props.previewKey][0].phone}</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default Preview




