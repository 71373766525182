import axios from "axios";
import { Cookies } from 'react-cookie'
import * as actions from "../action/menuPreviewAction";
const API_URL = process.env.REACT_APP_API_URL;

const MenuPreview =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type !== actions.apiCallBegan.type) return next(action);
        const { url, method, data, onStart, onSuccess, onError } =
            action.payload;
        if (onStart) dispatch({ type: onStart });
        next(action);
        try {
            let cookies = new Cookies()
            let token = cookies.get('AllMenuToken') || null
            const response = await axios.request({
                headers: {'authorization': `Bearer ${token}`},
                baseURL: API_URL,
                url,
                method,
                data,
            });
            //General
            dispatch(actions.apiCallSuccess(response.data));
            //Specific
            if (onSuccess)
                dispatch({ type: onSuccess, payload: response.data });
        } catch (error) {
            //General
            dispatch(actions.apiCallFailed(error.message));
            //Specific
            if (onError) dispatch({ type: onError, payload: error.message });
        }
    };

export default MenuPreview;