import React, { useEffect, useState } from "react";
import CustomDataTable from "../../components/customDataTable";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Tab,
  Nav,
} from "react-bootstrap";
import AdminApi from "../../api/adminApi";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";
import restDummyItemImage from "../../assets/images/no-image.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DeleteModel from "../../components/deleteModel";

const EProduct = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState([])
  const [isError, setIsError] = useState(false);
  const [isRestauranError, setIsRestauranError] = useState(false);
  const [isTempIcon, setIsTempIcon] = useState(false);
  const [isTempBg, setIsTempBg] = useState(false);
  const [validated, setValidated] = useState(false);
  const [LanguagesList, setLanguagesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const { t } = useTranslation();
  const [isCuisinesActive, setIsCuisinesActive] = useState("");
  const [validated1, setValidated1] = useState(false);
  const [isLangActive, setIsLangActive] = useState("");
  const [categoryItem, setCategoryItem] = useState({
    itemId: "",
    itemName: "",
    itemIcon: "",
    itemBg: "",
  });

  const [cFormData, setCFormData] = useState({
    id: "",
    cId: "",
    lang: "",
    title: "",
  });

  const langFormClean = () => {
    let d = { ...cFormData };
    d.id = "";
    d.cId = "";
    d.lang = "";
    d.title = "";

    setCFormData(d);
  };

  const formReset = () => {
    let d = { ...categoryItem };
    d.itemId = "";
    d.itemName = "";
    d.itemIcon = "";
    d.itemBg = "";

    setCategoryItem(d);
  };

  let adminApi = new AdminApi();
  let authApi = new AuthApi();

  useEffect(() => {
    getAllCategory();
    getAllLanguages();
    getallCategory();
  }, []);

  const getAllLanguages = async () => {
    await authApi
      .getAllLanguages()
      .then((response) => {
        setLanguagesList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getallCategory = async () => {
    await authApi
      .getAllCategory()
      .then((response) => {
        setCategoriesList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "cId":
        setIsCuisinesActive(value);
        break;
      case "lang":
        setIsLangActive(value);
        break;
      default:
        break;
    }
    setCFormData({
      ...cFormData,
      [name]: value,
    });
  };

  const langHandleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated1(true);
    let d = { ...cFormData };
    setCFormData(d);
    if (form.checkValidity()) {
      if (cFormData.id) {
        adminApi
          .createCategoryLanguage(d)
          .then((res) => {
            if (res.success) {
              setValidated1(false);
              toast.success(t("Cateigory Language Update Successfully"));
              langFormClean();
              getAllCategory();
            } else {
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
          });
      } else {
        adminApi
          .createCategoryLanguage(d)
          .then((res) => {
            if (res.success) {
              setValidated1(false);
              toast.success(t("Category Language Create successfully"));
              langFormClean();
              getAllCategory();
            } else {
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
          });
      }
    }
  };

  useEffect(() => {
    if (
      isCuisinesActive &&
      isLangActive &&
      isCuisinesActive != null &&
      isLangActive != null
    ) {
      getcategoryLangContent(isCuisinesActive, isLangActive);
    }
  }, [isCuisinesActive, isLangActive]);

  const getcategoryLangContent = async (cid, lid) => {
    let data = { cid, lid };
    await adminApi
      .getCategoryLangContent(data)
      .then((res) => {
        if (res.success) {
          // console.log(res.data);
          let d = { ...cFormData };
          d.cId = res.data.category_id;
          d.lang = res.data.language_code;
          d.title = res.data.name;
          d.id = res.data.id;

          setCFormData(d);
          //setLanContent(res.data.content);
        } else {
          //langFormClean();
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const uploadIcon = () => {
    document.getElementById("restaurantMenuIcon1").click();
    setIsTempIcon(true);
  };

  const uploadBg = () => {
    document.getElementById("restaurantMenuBg1").click();
    setIsTempBg(true);
  };

  const removeIcon = () => {
    let d = { ...categoryItem };
    d.itemIcon = "";
    setCategoryItem(d);
    setIsTempIcon(false);
  };

  const removeBg = () => {
    let d = { ...categoryItem };
    d.itemBg = "";
    setCategoryItem(d);
    setIsTempBg(false);
  };

  const getAllCategory = async () => {
    await adminApi
      .getAllCategory()
      .then((response) => {
        if (response.data) {
          setCategoryList(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getsingleCategory = async (pid) => {
    setIsTempBg(false)
    setIsTempIcon(false);
    await adminApi
      .getSingleCategory({ pid })
      .then((response) => {
        if (response.data) {
          let d = { ...categoryItem };
          d.itemIcon = response.data.image_url;
          d.itemName = response.data.name;
          d.itemBg = response.data.background;
          d.itemId = response.data.id;
          setCategoryItem(d);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleChanges = async (e) => {
    let { name, value } = e.target;
    let d = { ...categoryItem };
    d[name] = value;
    if (name == "itemName") {
      let regex = /[^a-zA-Z\d\s]/g;
      const foundSpecialChar = value.match(regex);
      regex = /[~,\,<, ,]/g;
      if (foundSpecialChar?.length) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      if (value.length <= 50) {
        setIsRestauranError(false);
      } else {
        setIsRestauranError(true);
      }
    }
    if (name == "itemIcon") {
      d.itemIcon = e.target.files[0];
    }
    if (name == "itemBg") {
      d.itemBg = e.target.files[0];
    }
    setCategoryItem(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (
      form.checkValidity() == true &&
      isError == false &&
      isRestauranError == false
    ) {
      if (categoryItem.itemId) {
        let data = new FormData();
        data.append("Icon", categoryItem.itemIcon);
        data.append("Background", categoryItem.itemBg);
        data.append("formData", JSON.stringify(categoryItem));
        adminApi
          .saveCategory(data)
          .then((response) => {
            if (response.success) {
              toast.success("Update Successfully!");
              getAllCategory()
              getallCategory();
              formReset();
              setValidated(false);
            } else {
              toast.error("Not Updated!");
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        let data = new FormData();
        data.append("Icon", categoryItem.itemIcon);
        data.append("Background", categoryItem.itemBg);
        data.append("formData", JSON.stringify(categoryItem));
        adminApi
          .saveCategory(data)
          .then((response) => {
            if (response.success) {
              toast.success("create Successfully!");
              formReset();
              setValidated(false);
              getAllCategory()
              getallCategory();
            } else {
              toast.error("please Upload images.");
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }

    setValidated(true);
  };

  const handleShow = (rowValue) => {
    setCategory(rowValue)
    setShow(true);
  }

  const handleClose = () => setShow(false);

  const deleteData = () => {
    adminApi.deleteCategory(category)
      .then(res => {
        if (res.success) {
          toast.success(res.msg)
          setShow(false)
          getAllCategory();
          getallCategory();
        } else {
          toast.error(res.msg)
        }
      })
      .catch(error => {
        toast.error(error)
      })
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const columns = [
    {
      id: "img_url",
      name: "Category Logo",
      cell: (row) => row.image_url && row.image_url !== null ? (
        <img
          style={{ width: "40px", height: "40px" }}
          src={row.image_url}
          alt={row.name}
        />
      ) : (
        <img
          style={{ width: "50px", height: "70px" }}
          src={restDummyItemImage}
          alt={row.name}
        />
      ),
      // <img height="40px" width="40px" alt={row.name} src={row.image_url} />
      sortable: true,
      width: "20%",
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row && row.name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.name)}
        >
          <span>{row.name}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "20%",
    },
    {
      id: "type",
      name: "Type",
      selector: (row) => row && row.type,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.type)}
        >
          <span>{row.type}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "20%",
    },
    {
      id: "background_img",
      name: "background Image",
      selector: (row) => row && row.background_img,
      cell: (row) =>
        row.background_img && row.background_img !== null ? (
          <img
            style={{ width: "50px", height: "70px" }}
            src={row.background_img}
            alt={row.name}
          />
        ) : (
          <img
            style={{ width: "50px", height: "70px" }}
            src={restDummyItemImage}
            alt={row.name}
          />
        ),
      sortable: true,
      width: "20%",
    },
    {
      id: "action",
      name: t("create_emenu_item_grid_column_action"),
      button: true,
      cell: (row) => (
        <>
          <div className="d-flex">
            <a href="#eMenuGrid" onClick={(e) => getsingleCategory(row.id)}>
              {" "}
              <span>
                <i className="fa fa-pencil-square"></i>
              </span>{" "}
            </a>
            <span onClick={() => handleShow(row)}><i className="fa fa-times-circle"></i></span>

          </div>
        </>
      ),
      width: "20%",
    },
  ];

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <h4 className="form-heading">E-Menu Categories:</h4>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="ml-0 mr-0">
              <Col md={3} sm={12} className="mt-3">
                <Form.Group>
                  <Form.Label>Category Name:</Form.Label>
                  <Form.Control
                    id="itemName"
                    name="itemName"
                    placeholder="Enter Category Name"
                    type="text"
                    required
                    onChange={(e) => handleChanges(e)}
                    value={categoryItem.itemName}
                    isInvalid={isError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {categoryItem.itemName == "" ? (
                      <p>Category name required.</p>
                    ) : isError ? (
                      <>
                        <p>Spacial character not allowed.</p>
                      </>
                    ) : isRestauranError ? (
                      <p>50 character allowed.</p>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3} sm={6} xs={12} className="mt-3">
                <Form.Group className="text-center">
                  <Form.Label>Upload Icon Image:</Form.Label>
                  {categoryItem.itemIcon === "" ||
                    categoryItem.itemIcon === undefined ? (
                    <div className="edit-profile-img mb-2 text-center">
                      <img src={restDummyItemImage} alt="" />
                    </div>
                  ) : (
                    <div className="edit-profile-img mb-2 text-center">
                      {isTempIcon ? (
                        <>
                          <img
                            src={URL.createObjectURL(categoryItem?.itemIcon)}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img src={categoryItem?.itemIcon} alt="" />
                        </>
                      )}
                    </div>
                  )}{" "}
                  <div className="text-center mt-3">
                    {categoryItem.itemIcon === "" ||
                      categoryItem.itemIcon === undefined ? (
                      <>
                        <Form.Control
                          name="itemIcon"
                          className="pt-1"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          id="restaurantMenuIcon1"
                          onChange={(e) => handleChanges(e)}
                        //required
                        />
                        <Button
                          variant="primary"
                          className="mb-1 me-3 btn btn-sm"
                          onClick={(e) => {
                            uploadIcon();
                          }}
                        >
                          Upload Image
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        className="mb-1 me-3 btn btn-sm"
                        onClick={(e) => {
                          removeIcon();
                        }}
                      >
                        Remove Image
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={4} sm={6} xs={12} className="mt-3" >
                <Form.Group className="text-center">
                  <Form.Label>Upload Background Image:</Form.Label>
                  {categoryItem.itemBg === "" ||
                    categoryItem.itemBg === undefined ? (
                    <div className="edit-profile-img mb-2 text-center">
                      <img src={restDummyItemImage} alt="" />
                    </div>
                  ) : (
                    <div className="edit-profile-img mb-2 text-center">
                      {isTempBg ? (
                        <>
                          <img
                            src={URL.createObjectURL(categoryItem?.itemBg)}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img src={categoryItem?.itemBg} alt="" />
                        </>
                      )}
                    </div>
                  )}{" "}
                  <div className="text-center mt-3">
                    {categoryItem.itemBg === "" ||
                      categoryItem.itemBg === undefined ? (
                      <>
                        <Form.Control
                          name="itemBg"
                          className="pt-1"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          id="restaurantMenuBg1"
                          onChange={(e) => handleChanges(e)}
                        //required
                        />
                        <Button
                          variant="primary"
                          className="mb-1 me-3 btn btn-sm"
                          onClick={(e) => {
                            uploadBg();
                          }}
                        >
                          Upload Image
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        className="mb-1 me-3 btn btn-sm"
                        onClick={(e) => {
                          removeBg();
                        }}
                      >
                        Remove Image
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={2} sm={6} xs={12} className="mt-5 text-center" >
                <button type="submit" className="btn btn-secondary">
                  {categoryItem.itemId ? "Update" : "Save"}
                </button>
              </Col>
            </Row>
          </Form>
          <div>
            <Row className="mt-3">
              <Col md={12} lg={12} sm={12} xs={12}>
                <h4 className="form-heading">
                  Enter Categories In Different Language:
                </h4>
              </Col>
            </Row>
            <Form onSubmit={langHandleSubmit} noValidate validated={validated1}>
              <Row className="ml-0 mr-0">
                <Col md={4} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Select Categories:</Form.Label>
                    <Form.Select
                      required
                      aria-label="Default select example"
                      name="cId"
                      id="cId"
                      value={cFormData.cId}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">--- Select Categories ---</option>
                      {categoriesList && categoriesList.length
                        ? categoriesList.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                        : " "}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Select Language:</Form.Label>
                    <Form.Select
                      required
                      aria-label="Default select example"
                      name="lang"
                      id="lang"
                      value={cFormData.lang}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">--- Select Language ---</option>
                      {LanguagesList && LanguagesList.length
                        ? LanguagesList.map((item) => {
                          return (
                            <option
                              key={`language-${item.id}`}
                              value={item.language_code}
                            >{`${item.name} (${item.language_code})`}</option>
                          );
                        })
                        : " "}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Text:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="title"
                      placeholder="Enter Text"
                      value={cFormData.title}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    {/* cFormData.title == "" ? <span>Title is required</span> : "" */}
                  </Form.Control.Feedback>
                </Col>
                <div
                  className="col-md-2 col-sm-12 col xs-12 text-center"
                  style={{ top: 27 }}
                >
                  <button type="submit" className="btn btn-secondary">
                    Save
                  </button>
                </div>
              </Row>
            </Form>
          </div>
        </Card.Body>
      </Card>
      <div className="table-responsive mt-5">
        <Row>
          <Col md={12} lg={12} sm={12} xs={12} className="mt-3">
            <CustomDataTable
              data={categoryList}
              pagination={true}
              columns={columns}
              pointerOnHover={true}
              highlightOnHover={true}
            // fixedHeader={true}
            />
          </Col>
        </Row>
      </div>
      {show ? <DeleteModel show={show} handleClose={handleClose} DeleteData={deleteData}></DeleteModel> : null}
    </>
  );
};

export default EProduct;
