
import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { loadmenu } from "../../redux/reducer/menuReducer";
import { useDispatch, useSelector } from "react-redux";
import GanetatePdf from "../../components/generatePDF";
import restDummyLogo from "../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../assets/images/no-image.png";
import cartImg from "../../assets/images/show-my-cart.png";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Base64Img from '../../components/base64Img'
import circle from "../../assets/images/circle.png";
import CommonApi from '../../api/commonApi';

const EMenuView = () => {
    const commonApi = new CommonApi();
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );
    const { t } = useTranslation();
    const [result, setResult] = useState([])
    const [trueFalse, setTrueFalse] = useState(false)
    let { menuId } = useParams();
    let previewId = menuId.split('-')[1];
    const dispatch = useDispatch();
    const menuData = useSelector((state) => state && state.menuReducer.menuList && state.menuReducer.menuList.data);
    let menu_Data = localStorage.getItem('menuItems')
    let arrayMenu = JSON.parse(menu_Data)
    let [showCartButton, setShowCartButton] = useState(arrayMenu)
    const navigate = useNavigate();

    const addItemsToCart = (item) => {
        let itemsString = localStorage.getItem('menuItems')
        let arrayItems = JSON.parse(itemsString)
        if (arrayItems && arrayItems.length > 0 && arrayItems !== null && arrayItems !== undefined && arrayItems !== '') {
            let findItemId = arrayItems.find((s) => s.id === item.id)
            if (findItemId) {
                let headId = arrayItems.findIndex((s) => s.id === item.id)
                let data = arrayItems.splice(headId, 1)
                localStorage.setItem('menuItems', JSON.stringify(arrayItems))
                setShowCartButton(arrayItems)
            }
            else {
                let temp = { ...item, selectedItem: true }
                let items = []
                if (itemsString) {
                    items = JSON.parse(itemsString)
                }
                items.push(item)
                localStorage.setItem('menuItems', JSON.stringify(items))
                setShowCartButton(items)
            }
        }
        else {
            let temp = { ...item, selectedItem: true }
            let items = []
            items.push(temp)
            localStorage.setItem('menuItems', JSON.stringify(items))
            setShowCartButton(items)
        }
        setTrueFalse(!trueFalse)
    }

    useEffect(() => {
        if ((arrayMenu && arrayMenu[0] && arrayMenu[0].menu_id) != previewId) {
            localStorage.removeItem('menuItems');
        }
    }, [arrayMenu])

    useEffect(() => {
        let objData = {
            previewId: previewId,
            login: "Nologin"
        }
        dispatch(loadmenu(objData));
    }, [dispatch]);

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    useEffect(() => {
        if (menuData && menuData.length) {
            let result = groupBy(menuData, 'section_id')
            setResult(result)
            loadBgImage(menuData[0].background_img);
        }
        if (menuData && menuData.length && arrayMenu && arrayMenu.length) {
            let m = []
            menuData.map((menuItem) => {
                let temp = { ...menuItem, selectedItem: false }
                arrayMenu && arrayMenu.map((localItems, i) => {
                    if (menuItem.id == localItems.id) {
                        temp.selectedItem = true
                    }
                });
                m.push(temp)
            })
            let result = groupBy(m, 'section_id')
            setResult(result)
        }
    }, [menuData]);

    const loadBgImage = async (url) => {
        try {
            const response = await commonApi.getBase64Image({ imageUrl: url });
            if (response.success && response.data) {
                setBackgroundImageUrl(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const mapData1 = () => {
        if (menuData && menuData[0] && menuData[0].status === "Active" && menuData[0].userStatus === "ACTIVE") {
            return Object.keys(result).map((key, sectionIndex) => {
                let array = result[key];
                return array.map((item, i) => {
                    return (
                        <>
                            {!item.section_id ? null :
                                (item.section_id == "1" ? null : !i ? (<div className='mb-3'><p className="card-text">{item.section_name}</p></div>) : null)}
                            <input className='checked-item' type="checkbox" id={`myCheckbox1-${item.id}`} />
                            <label htmlFor={`myCheckbox1-${item.id}`} onClick={() => addItemsToCart(item)} className={`col-md-12 col-sm-12 col-xs-12 templete3  ${showchecked(item.id)}`}>
                                <div className="container shadow">
                                    <h4>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip(item.name)}
                                        >
                                            <b>{(item.name && item.name.slice(0, 40) + (item.name.length > 40 ? "..." : ""))}</b>
                                        </OverlayTrigger>
                                    </h4>
                                    <span> {item.code ? "Code:" : null}{item.code}</span>
                                    <div><span>{item.quantity} </span><span>{item.unit_name}</span></div>
                                    {menuData[0].template_id == "2" ? null :
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip(item.description)}
                                        >
                                            <span className="desc" >{item.description && item.description.slice(0, 80) + (item.description.length > 80 ? "..." : "")}</span>
                                        </OverlayTrigger>
                                    }
                                    <span className="price">{item.currency_symbol + ' ' + item.price}</span>
                                </div>
                            </label>
                        </>
                    )
                })
            })
        }
        else {
            return <h3>This E-Menu is not exist.</h3>
        }
    }

    const showchecked = (id) => {
        let itemsString = localStorage.getItem('menuItems')
        let arrayItems = JSON.parse(itemsString)
        if (arrayItems) {
            let findItemId = arrayItems.find((s) => s.id === id)
            if (findItemId) {
                return "mylabel";
            } else {
                return "checklabel";
            }
        } else {
            return "checklabel"
        }

    }

    const mapData = () => {
        if (menuData && menuData[0] && menuData[0].status === "Active" && menuData[0].userStatus === "ACTIVE") {
            return Object.keys(result).map((key, sectionIndex) => {
                let array = result[key];
                return array.map((item, i) => {
                    return (
                        <>
                            {!item.section_id ? null :
                                (item.section_id == "1" ? null : !i ? (<div className='mb-3'><p className="card-text">{item.section_name}</p></div>) : null)}
                            <input className='checked-item' type="checkbox" id={`myCheckbox1-${item.id}`} />
                            <label htmlFor={`myCheckbox1-${item.id}`} onClick={() => addItemsToCart(item)} className={`col-lg-4 col-md-6 col-sm-6 col-xs-12 justify-content-center d-flex mb-4 ${showchecked(item.id)}`}>
                                <div className={`${menuData[0].template_id == "3" ? "card temp-3" : "card"}`}>
                                    <div className='image_height'>
                                        {menuData[0].template_id == "3" ? null : item.image_url ? (<Base64Img imageUrl={item.image_url} alt="Item" />) : <img className="logo" src={restDummyItemImage} alt="Logo" style={{ height: "auto" }} />}
                                    </div>
                                    <div className="container">
                                        <h4> <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip(item.name)}
                                        >
                                            <b>{(item.name && item.name.slice(0, 18) + (item.name.length > 18 ? "..." : ""))}</b>
                                        </OverlayTrigger></h4>

                                        <span className="price">{item.currency_symbol + ' ' + item.price}</span>

                                        <span> {item.code ? "Code:" : null}{item.code}</span>
                                        <div><span>{item.quantity} </span><span>{item.unit_name}</span></div>
                                        {menuData[0].template_id == "2" ? null :
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip(item.description)}
                                            >
                                                <span className="desc">{item.description && item.description.slice(0, 38) + (item.description.length > 38 ? "..." : "")}</span>
                                            </OverlayTrigger>

                                        }
                                    </div>
                                </div>
                            </label>
                        </>
                    )
                })

            })
        }
        else {
            return <h3>This E-Menu is not exist.</h3>
        }
    }

    const pdfHTML = () => {
        return (
            <>
                {
                    Object.keys(result).length ?
                        (<div className="container">
                            <div className="card border-0">
                                <div className="card-body p-0">
                                    <div className="text-center menu-card">
                                        <div className="row justify-content-center d-flex">
                                            <div className="col-md-12 p-0">
                                                <div className="container-fluid pdf_button d-flex">
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="card">
                                                        {menuData && menuData.length > 0 ? (
                                                            <div className="card-img-overlay american_cuisine" style={{
                                                                backgroundImage: `url("${backgroundImageUrl}")`
                                                            }}>
                                                                <div className="d-block justify-content-center mb-3">
                                                                    {
                                                                        menuData[0].logo_url ?
                                                                            <Base64Img className="logo" imageUrl={menuData[0].logo_url} alt="Logo" />
                                                                            :
                                                                            <img className="logo" src={restDummyLogo} alt="Logo" />
                                                                    }
                                                                    <h2>{menuData[0].vendor_name}

                                                                    </h2>
                                                                </div>
                                                                <div className="row url d-flex">
                                                                    <div className="col-md-6">{menuData[0].cousine_name}</div>
                                                                    <div className=" col-md-6 ancertags">
                                                                        <a href={`https://${menuData[0].website}`} target="_blank"> {menuData[0].website}</a>     </div>
                                                                </div>
                                                                <div className="row mb-5">
                                                                    {
                                                                        menuData[0].template_id == "3" ? mapData1() : mapData()
                                                                    }
                                                                </div>
                                                                {menuData && menuData.length > 0 ? (
                                                                    <div className="row address">
                                                                        <div className="col-md-6 col-sm-12 col-xs-12 text-left">{menuData[0].address ? `${menuData[0].address}, ` : null}
                                                                            {menuData[0].city_name}, {menuData[0].country_name}</div>
                                                                        <div className="col-md-6 col-sm-12 col-xs-12 
                                                                        mobile_set
                                                                        text-right">{menuData[0].phone}</div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        :
                        null
                }
            </>
        )
    }
    return (
        <>
            {(menuData && menuData.length) ?
                <>
                    <div className='container'>
                        <div className="row pdf_button mb-0">
                            <div className="col-md-4 col-sm-12 col-xs-12 text-center mt-2">
                                <a href={menuData && menuData[0].pdf_url}>  <button className="btn btn-primary downloadPdf">  {t("download_pdf")}</button>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12 text-center">
                                <Link to="/shopping-cart" className='downloadPdf' disabled={showCartButton && showCartButton.length ? false : true} >
                                    <img src={cartImg} alt="" height={"55px"} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div id="previewPdf">
                        {pdfHTML()}
                    </div>
                </>
                :
                navigate("/home")
            }
        </>
    )
}
export default EMenuView;

