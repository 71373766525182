import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AdminApi from "../../api/adminApi";
import AuthApi from "../../api/authApi";
import SunEditor from "suneditor-react";
import { toast } from "react-toastify";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function Cms() {
  const { t } = useTranslation();
  const adminApi = new AdminApi();
  let authApi = new AuthApi();
  const [pages, setPages] = useState([]);
  const [LanguagesList, setlanguagesList] = useState([]);
  const [LanContent, setLanContent] = useState("");
  const [isPageActive, setIsPageActive] = useState("");
  const [isLangActive, setIsLangActive] = useState("");
  const [validated, setValidated] = useState(false);
  const [validated1, setValidated1] = useState(false);
  const [mt, setMt] = useState(false);
  const [mk, setMk] = useState(false);
  const [md, setMd] = useState(false);
  const [pageData, setPageData] = useState({
    id: "",
    pageId: "",
    lang: "",
    title: "",
    content: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
  });
  useEffect(() => {
    fetchPages();
    getAllLanguages();
  }, []);

  useEffect(() => {
    if (
      isPageActive &&
      isLangActive &&
      isPageActive != null &&
      isLangActive != null
    ) {
      getPageContent(isPageActive, isLangActive);
    }
  }, [isPageActive, isLangActive]);

  const fetchPages = async () => {
    adminApi
      .getAllPages()
      .then((res) => {
        if (res.success) {
          setPages(res.data);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getAllLanguages = async () => {
    await authApi
      .getAllLanguages()
      .then((response) => {
        setlanguagesList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "pageId":
        setIsPageActive(value);
        break;
      case "lang":
        setIsLangActive(value);
        break;
      default:
        break;
    }
    setPageData({
      ...pageData,
      [name]: value,
    });
  };

  const getPageContent = async (pid, lid) => {
    let data = { pid, lid };
    await adminApi
      .getPage(data)
      .then((res) => {
        if (res.success) {
          let d = { ...pageData }
          d.id = res.data.id
          d.pageId = res.data.page_id
          d.lang = res.data.language_code
          d.title = res.data.title
          d.content = res.data.content
          d.meta_title = res.data.meta_title
          d.meta_keywords = res.data.meta_keywords
          d.meta_description = res.data.meta_description
          setPageData(d);
          setLanContent(res.data.content);
        } else {
          let d = { ...pageData }
          d.id = ''
          // d.pageId = ''
          // d.lang = ''
          d.title = ''
          d.content = ''
          d.meta_title = ''
          d.meta_keywords = ''
          d.meta_description = ''
          setPageData(d);
          setLanContent('');
          toast.success('No Data found');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const HandleSubmit = (e) => {
    const form = e.currentTarget;
    // console.log(form);
    e.preventDefault();
    e.stopPropagation();
    setValidated1(true);
    let d = { ...pageData };
    d.content = LanContent;
    d["flag"] = "content"
    console.log("ddddddddd", d);
    setPageData(d);
    if (form.checkValidity()) {
      if (pageData.id) {
        adminApi
          .createPage(d)
          .then((res) => {
            if (res.success) {
              setValidated1(false);
              toast.success(t("Page Update Successfully"));
              clearPageData()
              setLanContent('');
            } else {
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
            clearPageData()
            setLanContent('');
          });
      } else {
        adminApi
          .createPage(d)
          .then((res) => {
            //console.log("res", res);
            if (res.success) {
              setValidated1(false);
              toast.success(t("Page Create successfully"));
              clearPageData()
              setLanContent('');
            } else {
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
            clearPageData()
            setLanContent('');
          });
      }
    }
  };

  const clearPageData = () => {
    let d = { ...pageData }
    d.id = ''
    d.pageId = ""
    d.lang = ""
    d.title = ""
    d.content = ""
    d.meta_title = ""
    d.meta_keywords = ""
    d.meta_description = ""
    setPageData(d)
    setIsPageActive("")
    setIsLangActive("")
  }

  const handleMetaChange = (e) => {
    let { name, value } = e.target;
    setPageData({
      ...pageData,
      [name]: value,
    });
  };

  const HandleMetaSubmit = (e) => {
    const form = e.currentTarget;
    // console.log(form);
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    let d = { ...pageData };
    d["flag"] = "meta"
    if (form.checkValidity()) {
      if (pageData.id) {
        adminApi
          .createPage(pageData)
          .then((res) => {
            if (res.success) {
              setValidated(false);
              toast.success(t("Meta Update successfully"));
              clearPageData()
              setLanContent('');
            } else {
              toast.error("please select page.");
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
            clearPageData()
            setLanContent('');
          });
      } else {
        adminApi
          .createPage(pageData)
          .then((res) => {
            //console.log("res", res);
            if (res.success) {
              setValidated(false);
              toast.success(t("Meta Create successfully"));
              clearPageData()
              setLanContent('');
            } else {
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
            clearPageData()
            setLanContent('');
          });
      }
    }
  };

  return (
    <>
      <div className="register-page cont-bg">
        <Container>
          <Row>
            <Col lg={12}>
              <h2>CMS</h2>
              <p>
                Admin can update the content by selecting the page from
                dropdown.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="register-page">
        <Container>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col md={12} lg={12} sm={12} xs={12}>
                  <h4 className="form-heading">Update Page Content</h4>
                </Col>
                <Form onSubmit={HandleSubmit} noValidate validated={validated1}>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <Row>
                      <Col md={4} lg={4} sm={6} xs={12} className="mb-3">
                        <Form.Select
                          className="form-select selection"
                          aria-label="Default select example"
                          name="pageId"
                          required
                          onChange={(e) => handleChange(e)}
                          value={pageData.pageId}
                        >
                          <option value="">--- Select Page ---</option>
                          {pages &&
                            pages.map((page) => (
                              <option key={page.id} value={page.id}>
                                {page.name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {pageData.pageId == "" ? "Page is required" : ""}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={4} lg={4} sm={6} xs={12} className="mb-3">
                        <Form.Select
                          required
                          aria-label="Default select example"
                          name="lang"
                          id="lang"
                          value={pageData.lang}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">--- Select Language ---</option>
                          {LanguagesList && LanguagesList.length
                            ? LanguagesList.map((item) => {
                              return (
                                <option
                                  key={`language-${item.id}`}
                                  value={item.language_code}
                                >{`${item.name} (${item.language_code})`}</option>
                              );
                            })
                            : " "}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {pageData.lang == "" ? "Language is required" : ""}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} lg={12} sm={12} xs={12}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Page Heading:</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="title"
                            placeholder="Enter title"
                            value={pageData.title}
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          {pageData.title == "" ? <span>Title is required</span> : ""}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <div className="form-group sunEditor">
                          <label htmlFor="exampleInputEmail1">
                            Page Content:
                          </label>
                          <SunEditor
                            //name="content"
                            //onChange={(e) => handleChange(e)}
                            autoFocus={false}
                            placeholder="Enter the content here"
                            onChange={(e) => {
                              setLanContent(e);
                            }}
                            setOptions={{
                              "buttonList": [
                                ['undo', 'redo'],
                                ['font', 'fontSize', 'formatBlock'],
                                ['paragraphStyle', 'blockquote'],
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                ['fontColor', 'hiliteColor', 'textStyle'],
                                ['removeFormat'],
                                '/', // Line break
                                ['outdent', 'indent'],
                                ['align', 'horizontalRule', 'list', 'lineHeight'],
                                ['table', 'link', 'image'/* , 'video', 'audio' */ /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                                /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                                ['fullScreen', 'showBlocks', 'codeView'],
                                ['preview', 'print'],
                                ['save', 'template'],
                                /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                              ],
                            }}
                            // setContents={defaultEditorContent}
                            //value={LanContent}
                            setContents={LanContent}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="text-center">
                        <button type="submit" className="btn btn-danger">
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Form>
                <Col md={12} lg={12} sm={12} xs={12} className="mt-2">
                  <div className=" register-page">
                    <Container>
                      <Form
                        className="forms-sample mt-3"
                        onSubmit={HandleMetaSubmit}
                        noValidate
                        validated={validated}
                      >
                        <Row>
                          <Col className="p-0">
                            <h4 className="form-heading">SEO Content</h4>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Meta Title:</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="meta_title"
                                value={pageData.meta_title}
                                placeholder="Enter Meta Title"
                                onChange={(e) => handleMetaChange(e)}
                                isInvalid={mt}
                              />
                              <Form.Control.Feedback type="invalid">
                                <span>field is required!</span>
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Meta Keywords:</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                name="meta_keywords"
                                value={pageData.meta_keywords}
                                placeholder="Enter Meta Keywords"
                                onChange={(e) => handleMetaChange(e)}
                                isInvalid={mk}
                              />
                              <Form.Control.Feedback type="invalid">
                                <span>field is required!</span>
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Meta Description:</Form.Label>
                              <Form.Control
                                required
                                as="textarea"
                                name="meta_description"
                                value={pageData.meta_description}
                                placeholder="Enter Meta Description"
                                onChange={(e) => handleMetaChange(e)}
                                isInvalid={md}
                              />
                              <Form.Control.Feedback type="invalid">
                                <span>field is required!</span>
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col className="text-center">
                            <button
                              type="submit"
                              className="btn btn-danger"
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </Container>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
}
export default Cms;
