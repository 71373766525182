import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";
import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-toastify/dist/ReactToastify.css";
import './i18nextInit'
import "./assets/css/bootstrap.css"
// import "./assets/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/swiper.css";
import "./assets/css/swiper.min.css";
import "./assets/css1/style.css";
// import "./assets/css/font-awesome.css";
// import "./assets/css/font-awesome.min.css";
import configureStore from "./redux/configure";
import {HelmetProvider} from 'react-helmet-async'

import { Provider } from 'react-redux';
// import store from './redux/store';
// console.log("store====",store);

const root = ReactDOM.createRoot(document.getElementById('root'));

const Index = () => {
const store = configureStore();
// console.log("store====",store);
  return (
    <Router>
      <HelmetProvider>
        <CookiesProvider>
          <Provider store={store}>
            <App />
            <ToastContainer position="top-right" autoClose={2000} />
          </Provider>
        </CookiesProvider>
      </HelmetProvider>
    </Router>
  );
};

root.render(
  // <React.StrictMode>
		<Index />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
