import BaseApi from "./baseApi";
class ServiceApi extends BaseApi {
  async getAllUserServices(data) {
    return await this.API({
      url: "/service/getAllUserServices",
      method: "post",
      data:data
    })
  }

  async deleteService(data) {
    return await this.API({
      url: "service/deleteService",
      data: data,
      method:'post'
    })
  }

  async changeStatus(data) {
    return this.API({
      url: '/service/changeStatus',
      data: data,
      method: 'post'
    })
  }

  async getServicesCount(data) {
    return await this.API ({
      url:'service/getServicesCount',
      data: data,
      method: 'post'
    })
  }
}

export default ServiceApi;