import { React } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const PayPal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal show={props.showPaypal} onHide={props.handleClose}  size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('paypal_model_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div>{t('paypal_popup_service')} <strong>{props.otherData.serviceFor}</strong></div>
        <div>{t('paypal_popup_paypable_amount')} <strong>€{props.otherData.eMenuPrice}</strong></div>
        <div>{t('paypal_popup_div')}</div>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "EUR", }}>
          <PayPalButtons
            createOrder={props.createOrder}
            onApprove={props.onApprove}
            onError={props.onError}
          />
        </PayPalScriptProvider>
        <div><a href='/termsAndCondition' target="__blank"><h4>{t('paypal_popup_terms_condition')}</h4></a></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {t('paypal_cancel_button')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PayPal