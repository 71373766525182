import React, { useEffect } from 'react';

const FixedViewport = ({ width }) => {
  useEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
      metaViewport.setAttribute('content', `width=${width}`);
    }
  }, [width]);

  return null;
};

export default FixedViewport;
