import React, { useRef } from 'react'
import { QRCodeCanvas } from "qrcode.react";
import { Modal, Button } from 'react-bootstrap';
const QR_URL = process.env.REACT_APP_QR_URL;

export const AdminQrModal = (props) => {
  const qrRef = useRef();
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const qrcode = (items) => {
    if (items.service === "EMENU") {
      return <QRCodeCanvas
        id="qrCode"
        value={`${QR_URL}/e-menu/${items.vendor_name.split(" ").join("")}-${items.menu_id}`}
        size={256}
        style={{ height: "auto", width: "200px" }}
        level={"H"}
      />
    }
    else {
      return <QRCodeCanvas
        id="qrCode"
        value={`${QR_URL}/e-product/${items.vendor_name.split(" ").join("")}-${items.list_id}`}
        size={256}
        style={{ height: "auto", width: "200px" }}
        level={"H"}
      />
    }
  }

  return (
    <Modal
      show={props.modalShow}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          QR-Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className='mb-4'>
          {
            props.qrData.service === "EPRODUCTS" ?
              <a href={`/product/${props.qrData.vendor_name.split(" ").join("")}-${props.qrData.list_id}`} target="_blank">
                {`${QR_URL}/product/${props.qrData.vendor_name.split(" ").join("")}-${props.qrData.list_id}`}
              </a>
              :
              <a href={`/menu/${props.qrData.vendor_name.split(" ").join("")}-${props.qrData.menu_id}`} target="_blank">
                {`${QR_URL}/menu/${props.qrData.vendor_name.split(" ").join("")}-${props.qrData.menu_id}`}
              </a>
          }
        </div>
        <h4> QR-Code:</h4>
        <div ref={qrRef}> {qrcode(props.qrData)}</div>
        <form onSubmit={downloadQRCode}>
          <input type="text" hidden />
          <button
            type="submit"
            className="btn btn-secondary mt-2"
          >
            Download Qrcode
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
