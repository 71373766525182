import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IndexRouter from "./routers";
import LanguagePrvider from "./context/languageProvider";
import { Cookies, useCookies } from "react-cookie";
const App = () => {
  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault()
    }
    // attach the event listener to 
    // the document object
    document.addEventListener("contextmenu", handleContextMenu)
    // clean up the event listener when 
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
    }
  }, [])

  const location = useLocation()
  let myUrl = location.pathname.split('/')[1];
  let cookies = new Cookies();
  let userData = cookies.get("AllMenuUser") || null;
  return (
    <>
      <LanguagePrvider>
        <div id="google_translate_element" className={`${(myUrl == "emenu" || myUrl == "eproducts" || myUrl == "shopping-cart") && userData == null ? "text-center" : "text-center showTranslate"}`}></div>
        <IndexRouter />
      </LanguagePrvider>
    </>

  );
};

export default App;




