import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../action/productPreviewAction";

const slice = createSlice({
    name: "product",
    initialState: {
        productList: [],
        loading: false,
    },

    reducers: {
        menuRequested: (product, action) => {
            product.loading = true;
        },
        menuReceived: (product, action) => {
            // console.log(" action.payload", action.payload)
            product.productList = action.payload;
            product.loading = false;
        },

        menuRequestFailed: (product, action) => {
            product.loading = false;
        },
    },

});


export default slice.reducer;

const { menuRequested, menuReceived, menuRequestFailed } = slice.actions;


export const loadproduct = (data) => (dispatch) => {
    if(data.login == "login"){
    return dispatch(
        apiCallBegan({
            url:"/eproduct/getAllProductPreview",
            method:"post",
            data:data,
            onStart: menuRequested.type,
            onSuccess: menuReceived.type,
            onError: menuRequestFailed.type,
        })
    );
}
else {
    return dispatch(
        apiCallBegan({
            url:"/enduser/getAllProductView",
            method:"post",
            data:data,
            onStart: menuRequested.type,
            onSuccess: menuReceived.type,
            onError: menuRequestFailed.type,
        })
    );
}
};

