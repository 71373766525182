import moment from 'moment';
import axios from "axios";
const Helpers = {
	convertUtcToTimezone: function(rows, format) {
		return moment(rows).format(format)
	},

	calculateVAT: function(data) {
		if (data.isVAT) {
			let newPrice = data.price +((data.price * 23) / 100)
	
			return newPrice
		}
	},


	// to get user country based on ip.
	getGeoInfo: () => {
		return new Promise((resolve, reject) => {
			axios
      .get("https://ipapi.co/json/")
      .then((response) => {
				let data = response.data;
				let sendObj = {
					ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
				}
				resolve(sendObj)
      })
      .catch((error) => {
        reject(error);
      });
		}) 
	},

	calculateVATforValue: function(amount) {
		let calculatedAmount = ((amount * 23) / 100) //where 23 is percent of vat
		return calculatedAmount;
	},

	currencyToWords: function (amount, language) {
        amount = Number(amount)
        const ones = {
            en: ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'],
            pl: ['', 'jeden', 'dwa', 'trzy', 'cztery', 'pięć', 'sześć', 'siedem', 'osiem', 'dziewięć']
        };
		const teens = {
            en: ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
            pl: ['dziesięć', 'jedenaście', 'dwanaście', 'trzynaście', 'czternaście', 'piętnaście', 'szesnaście', 'siedemnaście', 'osiemnaście', 'dziewiętnaście']
        };
		const tens = {
            en: ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'],
            pl: ['', '', 'dwadzieścia', 'trzydzieści', 'czterdzieści', 'pięćdziesiąt', 'sześćdziesiąt', 'siedemdziesiąt', 'osiemdziesiąt', 'dziewięćdziesiąt']
        };
		const hundreds = {
            en: ['', 'one hundred', 'two hundred', 'three hundred', 'four hundred', 'five hundred', 'six hundred', 'seven hundred', 'eight hundred', 'nine hundred'],
            pl: ['', 'sto', 'dwieście', 'trzysta', 'czterysta', 'pięćset', 'sześćset', 'siedemset', 'osiemset', 'dziewięćset']
        };
		const thousands = {
            en: ['', 'thousand', 'thousand', 'thousand', 'million', 'million', 'million', 'billion', 'billion', 'billion'],
            pl: ['', 'tysiąc', 'tysiące', 'tysięcy', 'milion', 'miliony', 'milionów', 'miliard', 'miliardy', 'miliardów']
        };
		const decimalNames = {
            en: 'euros',
            pl: 'złotych'
        };
		const [integer, decimal] = amount.toFixed(2).split('.');
		let integerWords = '';
        let decimalWords = '';
		const integerArr = integer.split('').reverse();
		for (let i = 0; i < integerArr.length; i++) {
            const digit = parseInt(integerArr[i]);
            if (i === 0 && integerArr[1] !== '1') {
                integerWords = ones[language][digit] + ' ' + integerWords;
            } else if (i === 1 && digit === 1) {
                integerWords = teens[language][parseInt(integerArr[0])] + ' ' + integerWords;
                break;
            } else if (i === 1 && digit !== 0) {
                integerWords = tens[language][digit] + ' ' + integerWords;
            } else if (i === 2 && digit !== 0) {
                integerWords = hundreds[language][digit] + ' ' + integerWords;
            } else if (i === 3 && digit !== 0) {
                integerWords = ones[language][digit] + ' ' + thousands[language][1] + ' ' + integerWords;
            } else if (i === 4 && digit !== 0) {
                if (integerArr.slice(0, 2).join('') < 20) {
                    const teenNum = parseInt(integerArr.slice(0, 2).reverse().join(''));
                    integerWords = teens[language][teenNum - 10] + ' ' + thousands[language][2] + ' ' + integerWords;
                    break;
                } else {
                    integerWords = tens[language][digit] + ' ' + integerWords;
                }
            } else if (i === 5 && digit !== 0) {
                integerWords = ones[language][digit] + ' ' + thousands[language][2] + ' ' + integerWords;
            } else if (i === 6 && digit !== 0) {
                if (integerArr.slice(3, 5).join('') < 20) {
                    const teenNum = parseInt(integerArr.slice(3, 5).reverse().join(''));
                    integerWords = teens[language][teenNum - 10] + ' ' + thousands[language][3] + ' ' + integerWords;
                    break;
                } else {
                    integerWords = tens[language][digit] + ' ' + integerWords;
                }
            } else if (i === 7 && digit !== 0) {
                integerWords = ones[language][digit] + ' ' + thousands[language][3] + ' ' + integerWords;
            } else if (i === 8 && digit !== 0) {
                integerWords = ones[language][digit] + ' ' + thousands[language][4] + ' ' + integerWords;
            } else if (i === 9 && digit !== 0) {
                integerWords = ones[language][digit] + ' ' + thousands[language][5] + ' ' + integerWords;
            }
        }
		if (decimal) {
            decimalWords = decimal + '/100';
        }

        return integerWords.trim() + ' ' + decimalNames[language] + ' ' + decimalWords;
    },

    fetchCurrencyExchangeRate: async function() {
        let exchange_rate = 0;
        await fetch('https://v6.exchangerate-api.com/v6/b25913834b0912f4b7cb7ee1/latest/EUR')
        .then(response => response.json())
        .then(data => {
          exchange_rate = data.conversion_rates.PLN;
        //   console.log(`Exchange rate for EUR to PLN: ${data}`);
        })
        .catch(error => console.error(error));
        return exchange_rate;
      }

}
export default Helpers;