import BaseApi from "./baseApi";

class CommonApi extends BaseApi {

  async getAllEMenu(data) {
    return await this.API({
      url: `/enduser/getallemenu`,
      data: data,
      method: "post"
    })
  }

  async getAllEProduct(data) {
    return await this.API({
      url: `/enduser/getalleproduct`,
      data: data,
      method: "post"
    })
  }

  async getBase64Image(data) {
    return this.API({
      url: '/enduser/getbase64image',
      data: data,
      method: 'post'
    })
  }

}

export default CommonApi;