import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation, useSearchParams} from "react-router-dom";
import AuthApi from "../../api/authApi";
import { useTranslation } from 'react-i18next';

const RegisEmailVerify = () => {
  const { t } = useTranslation();
	let api = new AuthApi();
    let navigate = new useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(()=>{
        const id = searchParams.get("code");
        if(id){
            handleVerification(id)
        } else {
          navigate('/login')
        }
    },[])

	const handleVerification = async (code) => {
        if (code) {           
            await api.emailVerify({code: code})
                .then((response) => {
                    if(response.success){
                        toast.success(t("email_verify_done"))
                        navigate('/login')
                    } else {
                        toast.error(response.msg);
                        navigate('/login')
                    }
                })
                .catch((error) => {
                    // console.log(error)
                })           
        }
    } 
  return (
    <div className="container-scroller">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
        <div className="row flex-grow">
        ` <div className="col-lg-12 login-half-bg d-flex flex-row">
            <p className="text-white font-weight-semibold text-center flex-grow align-self-end"></p>
          </div>
        </div>
      </div>
      {/* <!-- content-wrapper ends --> */}
    </div>
    {/* <!-- page-body-wrapper ends --> */}
  </div>
  )
}

export default RegisEmailVerify;
