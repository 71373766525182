
// import React, { useState, useEffect, useRef } from 'react'
// import { Link, useParams } from "react-router-dom";
// import { loadmenu } from "../../redux/reducer/menuReducer";
// import { useDispatch, useSelector } from "react-redux";
// import { useTranslation } from 'react-i18next';
// import { Tooltip } from 'react-bootstrap';

// const DownloadPdf = () => {
//     const anchorRef = useRef(null);
//     const { t } = useTranslation();
//     let { menuId } = useParams();
//     let previewId = menuId.split('-')[1];
//     console.log("previewId", previewId);

//     const dispatch = useDispatch();
//     const menuData = useSelector((state) => state && state.menuReducer.menuList && state.menuReducer.menuList.data);

//     // console.log("menuData1111", menuData);

//     useEffect(() => {
//         let objData = {
//             previewId: previewId,
//             login: "Nologin"
//         }
//         dispatch(loadmenu(objData));
//     }, [dispatch]);


//     useEffect(() => {
//         if (menuData && menuData.length) {
//             console.log("djsvvvvvvvvvvvvvvv");
//             anchorRef.current.click();
//         }
//     }, [menuData]);
//     console.log("menuData", menuData);

//     return (
//         <div>
//             {console.log("menuData11111111111111111111",menuData )}
//             <Link ref={anchorRef} to={menuData && menuData[0].pdf_url}>
//             </Link>

//         </div>
//     )
// }

// export default DownloadPdf