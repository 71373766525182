import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Cookies, useCookies } from 'react-cookie'
import restDummyLogo from "../../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../../assets/images/itemDummyImage.png";
import Template1 from "../../../assets/images/template1.png";
import Template2 from "../../../assets/images/template2.png";
import Template3 from "../../../assets/images/template3.png";
import DataTable from "react-data-table-component";
import DeleteModel from "../../../components/deleteModel";
import Preview from './../preview';
import EMenuApi from '../../../api/eMenuApi'
import UserApi from '../../../api/userApi'
import PaymentApi from '../../../api/paymentApi'
import moment from 'moment';
import Helpers from '../../../components/helpers';
import QrComponent from '../../../components/qrComponent';
import Loader from "../../../components/loader";
import PayPal from '../../../components/payPal';
import { vat } from '../../../config'
import AuthApi from '../../../api/authApi';


const CreateEMenu = () => {
  const location = useLocation()
  const checkNewRest = location.state && location.state.isNewRest ? location.state.isNewRest : false
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [validated1, setValidated1] = useState(false);
  const [isError, setIsError] = useState(false);
  const [savePreview, setSavePreview] = useState(false);
  const [itemId, setItemId] = useState("")
  const [loading, setLoading] = useState(false);
  const [menuPreview, setMenuPreview] = useState([])
  const [selectedTemplete, setSelectedTemplete] = useState([])
  const [result, setResult] = useState([])
  let [searchParams, setSearchParams] = useSearchParams();
  let serviceId = searchParams.get("serviceId");
  let cookies = new Cookies()
  let userData = cookies.get('AllMenuUser') || null;
  const [cookie, setCookie] = useCookies(['AllMenuUser']);
  const eMenuApi = new EMenuApi();
  const userApi = new UserApi();
  let authApi = new AuthApi()
  const paymentApi = new PaymentApi();
  const [data, setData] = useState({
    id: "",
    userServiceId: "",
    logoUrl: "",
    restaurantName: "",
    phone: "",
    email: "",
    currencyName: "", // store country table id according to currency
    website: "",
    address: "",
    cuisine_id: '',
    background_id: '',
    template_id: '1',
    menu_id: '',
    language_code: '',
    valid_till: '',
    country: "",
    city_id: "",
    otherCity: ""
  });
  const [cuisineBackground, setCuisineBackground] = useState([]);
  const [isTempUpload, setIsTempUpload] = useState(false)
  const [isTempUpload1, setIsTempUpload1] = useState(false)
  const [restMenuItem, setRestMenuItem] = useState([])
  const [show, setShow] = useState(false);
  const [itemData, setItemData] = useState([])
  const [userRestData, setUserRestData] = useState([])
  const [isEmailError, setIsEmailError] = useState(false);
  const [isWebsiteError, setIsWebsiteError] = useState(false);
  const [isRestauranError, setIsRestauranError] = useState(false);
  const [showPayPalComp, setShowPayPalComp] = useState(false);
  const [eMenuPrice, setEMenuPrice] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [menuId, setMenuId] = useState("");
  const [userEu, setUserEu] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0)
  const [allCurrency, setAllCurrency] = useState([])
  const selected = localStorage.getItem("i18nextLng");
  const [showPayPal, setShowPayPal] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null)
  const [loadingCity, setLoadingCity] = useState(false);
  const [cityList, setCityList] = useState([])
  const [countriesList, setcountriesList] = useState([])

  useEffect(() => {
    if (serviceId) {
      // edit case from my-service page.
      let sendObj = {
        language_code: selected,
        id: userData.id,
        userServiceId: serviceId,
      }
      getUserRestarants(sendObj)
    } else {
      getUserCurrency()
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getAllCurrency()
  }, []);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#f8f9fb',
        fontSize: '0.82rem !important',
        fontWeight: '700',
        innerHeight: "20px",
        height: "38px",
      }
    },
    head: {
      style: {
        height: "38px",
      }
    },
    rows: {
      style: {
        height: "auto !important",
        minHeight: "38px !important",
      }
    },
    cells: {
      style: {
        height: "auto !important",
        minHeight: "38px !important",
        verticalAlign: "middle",
        fontSize: "0.82rem",
        lineHeight: "1.5",
        // whiteSpace: "nowrap",
        fontWeight: "500",
      }
    },
    table: {
      style: {
        paddingBottom: "0",
      }
    }
  };

  useEffect(() => {
    getCuisineBackground()
    getServiceList()
    if (menuId) {
      let sendObj = {
        userId: userData.id,
        menu_id: menuId,
        language_code: selected
      }
      getItem(sendObj)
      getMenuPreview(sendObj)
    }
    if (serviceId) {
      let sendObj1 = {
        userServiceId: serviceId,
        language_code: selected
      }
      eMenuApi.saveRestBgTempId(sendObj1)
        .then(res => {
          if (res.success) {
          } else {
            toast.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [selected])

  useEffect(() => {
    getServicePrice(userData)
    getAllCountries()
  }, [])

  useEffect(() => {
    getAllCities(data.country)
  }, [data])

  const getAllCities = async (country) => {
    await authApi.getAllCities({ country_id: country }).then((response) => {
      setCityList(response.data);
      setLoadingCity(false)
    }).catch((error) => {
      setLoadingCity(false)
      toast.error(error.toString());
    });
  }

  const getCurrencyExchangeRate = async () => {
    const exchange_rate = await Helpers.fetchCurrencyExchangeRate();
    setExchangeRate(exchange_rate);
  }

  const handleClose1 = () => setShowPayPal(false);

  const [section2, setSection2] = useState({
    cuisine_id: "",
    bgId: '',
    templateId: '1',
    userServiceId: '',
    menu_id: '',
    language_code: "",
    currency_id: ''
  })


  const [cuisineItem, setCuisineItem] = useState({
    itemId: '',
    cuisineId: '',
    cuisineType: 1,
    itemName: '',
    itemPrice: '',
    itemDescription: '',
    itemPicUrl: '',
    menu_id: ''
  })

  const getServiceList = async () => {
    await eMenuApi.getServiceList({ language_code: selected })
      .then(res => {
        if (res.success) {
          setSectionList(res.data)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getServicePrice = async (data) => {
    await eMenuApi.getServicePrice({ userId: data.id })
      .then(res => {
        if (res.success) {
          if (res.isUserFromEurope) {
            setUserEu(true)
            let senObj = {
              price: res.data.price,
              isVAT: res.isUserFromEurope
            }
            let VAT = Helpers.calculateVAT(senObj)

            setEMenuPrice(VAT)
          } else {
            setEMenuPrice(res.data.price)
          }
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleClose = () => setShow(false);

  const getRestInfo = async (data) => {
    await eMenuApi.getRestInfo({ id: userData.id })
      .then(res => {
        if (res.success) {
          let d = { ...data }
          d.restaurantName = res.data.vendor_name
          d.email = res.data.email
          d.website = res.data.website
          d.phone = res.data.phone
          d.address = res.data.address
          d.logoUrl = res.data.logo_url
          d.currencyName = res.data.currency_name
          d.country = res.data.country
          d.city_id = res.data.city_id
          setData(d)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserCurrency = async () => {
    await userApi.getUserCurrency({ id: userData.id })
      .then(res => {
        if (res.success) {
          let d = { ...data }
          d.currencyName = res.data.id
          setData(d)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAllCurrency = async () => {
    await userApi.getAllCurrency()
      .then(res => {
        if (res.success) {
          setAllCurrency(res.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserRestarants = async (id) => {
    await eMenuApi.getUserRestarants(id)
      .then(res => {
        if (res.success) {
          setUserRestData(res.data)
          let d = { ...data }
          d.restaurantName = res.data.vendor_name
          d.email = res.data.email
          d.website = res.data.website
          d.phone = res.data.phone
          d.address = res.data.address
          d.logoUrl = res.data.logo_url
          d.currencyName = res.data.country_id
          d.country = res.data.country
          d.city_id = res.data.city_id
          d.userServiceId = res.data.id
          d.background_id = res.data.background_id
          d.cuisine_id = res.data.cuisine_id
          d.template_id = res.data.template_id == null ? '1' : res.data.template_id
          d.menu_id = res.data.menuId
          d.valid_till = res.data.valid_till
          setData(d)
          let d1 = { ...section2 }
          d1.templateId = res.data.template_id == null ? '1' : res.data.template_id
          d1.cuisine_id = res.data.cuisine_id
          d1.bgId = res.data.background_id
          d1.menu_id = res.data.menuId
          d1.userServiceId = res.data.id
          setSection2(d1)
          let sendObj = {
            userId: userData.id,
            menu_id: res.data.menuId,
            cuisine_id: res.data.cuisine_id,
            background_id: res.data.background_id,
            language_code: selected
          }
          getItem(sendObj)
          setMenuId(res.data.menuId)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCuisineBackground = async () => {
    await eMenuApi.getCuisineBackground({ language_code: selected })
      .then(res => {
        if (res.success) {
          setCuisineBackground(res.data)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAllCountries = async () => {
    await authApi.getAllCountries().then((response) => {
      setcountriesList(response.data);
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const handleChanges = async (e) => {
    let { name, value } = e.target
    let d = { ...data }
    d[name] = value;
    if (name == 'logoUrl') {
      d.logoUrl = e.target.files[0]
    }
    if (name == "restaurantName") {
      let regex = /[^a-zA-Z\d\s]/g
      const foundSpecialChar = value.match(regex);
      regex = /[~,\,<, ,]/g;
      if (
        foundSpecialChar?.length
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      if (value.length <= 50) {
        setIsRestauranError(false)
      } else {
        setIsRestauranError(true)
      }
    }

    if (name == "email") {
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const emailPatren = value.match(regex);
      if (
        emailPatren?.length
      ) {
        setIsEmailError(false);
      } else {
        setIsEmailError(true);
      }
    }

    if (name == 'website') {
      const regex = /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/i;
      const websitPattern = value.match(regex)
      if (websitPattern?.length) {
        setIsWebsiteError(false)
      } else {
        setIsWebsiteError(true)
      }

    }
    if (e.target.name === "country") {
      setLoadingCity(true);
      authApi.getAllCities({ country_id: e.target.value }).then((response) => {
        setCityList(response.data);
        let d = { ...data }
        d.country = e.target.value;
        setLoadingCity(false)
      }).catch((error) => {
        setLoadingCity(false)
        toast.error(error.toString());
      });
    }
    setData(d)
  }

  const upload = () => {
    document.getElementById("restaurantLogoUpload").click();
    setIsTempUpload(true)
  };

  const upload1 = () => {
    document.getElementById("restaurantMenuItemUpload").click();
    setIsTempUpload1(true)
  };

  const remove = () => {
    let d = { ...data }
    d.logoUrl = ''
    setData(d);
    setIsTempUpload(false)
  }

  const remove1 = () => {
    let d = { ...cuisineItem }
    d.itemPicUrl = ''
    setCuisineItem(d);
    setIsTempUpload1(false)
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() == true && isError == false && isRestauranError == false) {
      setLoading(true);
      let data1 = new FormData()
      data1.append('file', data.logoUrl)
      let stateRef = { ...data }
      stateRef.id = userData.id
      stateRef.country_id = data.currencyName
      stateRef.language_code = selected
      stateRef.userServiceId = data && data.userServiceId ? data.userServiceId : ''
      data1.append('formData', JSON.stringify(stateRef));
      eMenuApi.saveUserRestaurant(data1)
        .then((res) => {
          if (res.success) {
            toast.success(t('emenu_restaurant_info_saved'));
            setIsTempUpload(false)
            let d = { ...data }
            d.restaurantName = res.data.vendor_name
            d.email = res.data.email
            d.website = res.data.website
            d.phone = res.data.phone
            d.address = res.data.address
            d.logoUrl = res.data.logo_url
            d.userServiceId = res.data.id
            d.id = res.data.user_id
            d.language_code = res.data.language_code
            d.cuisine_id = res.menu_cuisine
            d.menu_id = res.menu_res_id
            d.currencyName = res.data.country_id
            d.country = res.data.country
            d.city_id = res.data.city_id
            setData(d)
            let sendObj = {
              userId: userData.id,
              menu_id: res.menu_res_id,
              language_code: selected
            }
            getMenuPreview(sendObj)
            let sendObj1 = {
              userId: userData.id,
              menu_id: res.menu_res_id,
              cuisine_id: data.cuisine_id,
              language_code: selected
            }
            getItem(sendObj1)
          } else if (res.error) {
            toast.error(res.msg);
          }
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          toast.error(error);
        });
    }
    setValidated(true);
  }

  // save cuisine bg and template
  const getResTmenuBg = (id, idFor) => {
    const obj = { ...section2 };
    if (idFor == 'bg') {
      obj["bgId"] = id
      obj["userServiceId"] = data.userServiceId
      obj["cuisine_id"] = data.cuisine_id
      obj["language_code"] = selected
      obj["scan_code"] = `/e-menu/${menuPreview &&
        menuPreview[0] &&
        menuPreview[0].vendor_name.split(" ").join("")
        }-${menuPreview &&
        menuPreview[0] &&
        menuPreview[0].id
        }`
      setSection2(prevState => ({
        ...prevState,
        bgId: id,
        userServiceId: data.userServiceId,
        cuisine_id: data.cuisine_id
      }));
    } else if (idFor == 'temp') {
      obj["templateId"] = id
      obj["userServiceId"] = data.userServiceId
      obj["cuisine_id"] = data.cuisine_id
      obj["language_code"] = selected
      obj["scan_code"] = `/e-menu/${menuPreview &&
        menuPreview[0] &&
        menuPreview[0].vendor_name.split(" ").join("")
        }-${menuPreview &&
        menuPreview[0] &&
        menuPreview[0].id
        }`
      setSection2(prevState => ({
        ...prevState,
        templateId: id,
        userServiceId: data.userServiceId,
        cuisine_id: data.cuisine_id
      }));

    }
    if (obj.bgId !== '' && obj.templateId !== '') {
      obj['currency_id'] = data.currencyName
      if (data.userServiceId && data.userServiceId !== null) {
        eMenuApi.saveRestBgTempId(obj)
          .then(res => {
            if (res.success) {
              toast.success(t('createEmenu_bgAndTemp_save_msg'))
              let d = { ...cuisineItem }
              d.menu_id = res.data.id
              setCuisineItem(d);
              let d3 = { ...section2 }
              d3.cuisine_id = data.cuisine_id
              d3.bgId = res.data.background_id
              d3.menu_id = res.data.id
              d3.templateId = res.data.template_id
              setSection2(d3)
              let sendObj = {
                id: userData.id,
                userServiceId: data.userServiceId,
                language_code: selected
              }
              getUserRestarants(sendObj)
              let sendObj1 = {
                userId: userData.id,
                menu_id: res.data.id,
                language_code: selected
              }
              getMenuPreview(sendObj1)
            } else {
              toast.error(res.msg)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
      else {
        toast.error(t("first_restaurant_details"))
      }
    }
  }

  const saveQrUrl = () => {
    const obj = { ...section2 };
    obj['currency_id'] = data.currencyName
    obj["language_code"] = selected
    obj["scan_code"] = `/e-menu/${menuPreview &&
      menuPreview[0] &&
      menuPreview[0].vendor_name.split(" ").join("")
      }-${menuPreview &&
      menuPreview[0] &&
      menuPreview[0].id
      }`
    eMenuApi.saveRestBgTempId(obj)
      .then(res => {
        if (res.success) {
          toast.success(t('link_genrated_emenu'))
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChanges1 = async (e) => {
    let { name, value } = e.target
    let d = { ...cuisineItem }
    d[name] = value;
    if (name == 'itemPicUrl') {
      d.itemPicUrl = e.target.files[0]
    }
    setCuisineItem(d)
  }

  const handleSubmit2 = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() == true) {
      setLoading(true)
      if (cuisineItem.itemId !== '') {
        // edit part
        let data1 = new FormData()
        data1.append('file', cuisineItem.itemPicUrl)
        let stateRef = { ...cuisineItem }
        stateRef.user_id = userData.id
        stateRef.cuisineId = data.cuisine_id
        stateRef.menu_id = data.menu_id
        delete stateRef.itemPicUrl
        stateRef.user_service_id = data.userServiceId
        stateRef.restaurantName = data.restaurantName
        data1.append('formData', JSON.stringify(stateRef));
        eMenuApi.saveRestaurantItem(data1)
          .then((res) => {
            if (res.success) {
              let d = { ...cuisineItem }
              d.itemId = ''
              d.cuisineType = 1
              d.itemName = ''
              d.itemPrice = ''
              d.itemDescription = ''
              d.itemPicUrl = ''
              setIsTempUpload(false)
              setCuisineItem(d)
              let sendObj = {
                userId: userData.id,
                menu_id: res.data.menu_id,
                cuisine_id: data.cuisine_id,
                language_code: selected
              }
              getItem(sendObj)

            } else if (res.error) {
              toast.error(res.msg);
            }
            setValidated1(false)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            toast.error(error);
          });
      } else {
        // add part
        let data1 = new FormData()
        data1.append('file', cuisineItem.itemPicUrl)
        let stateRef = { ...cuisineItem }
        stateRef.user_id = userData.id
        stateRef.cuisineId = data.cuisine_id
        stateRef.menu_id = data.menu_id
        delete stateRef.itemPicUrl
        stateRef.display_order = displayOrder
        stateRef.user_service_id = data.userServiceId
        stateRef.restaurantName = data.restaurantName
        data1.append('formData', JSON.stringify(stateRef));
        eMenuApi.saveRestaurantItem(data1)
          .then((res) => {
            if (res.success) {
              toast.success(t('createEmenu_itemAdd_save_msg'));
              setRestMenuItem(res.data)
              setValidated1(false)
              let d = { ...cuisineItem }
              d.itemId = ''
              d.cuisineType = 1
              d.itemName = ''
              d.itemPrice = ''
              d.itemDescription = ''
              d.itemPicUrl = ''
              setIsTempUpload(false)
              setCuisineItem(d)
              let sendObj = {
                userId: userData.id,
                menu_id: res.data.menu_id,
                cuisine_id: data.cuisine_id,
                language_code: selected
              }
              getItem(sendObj)
            } else if (res.error) {
              toast.error(res.msg);
            }
            setValidated1(false)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            toast.error(error);
          });
      }

    }
    setValidated1(true);
  }

  const changeOrder = (row, value) => {
    eMenuApi.changeOrder({
      row: row,
      value: value,
    })
      .then((res) => {
        let sendObj = {
          userId: userData.id,
          menu_id: row.menu_id,
          cuisine_id: data.cuisine_id,
          language_code: selected
        }
        getItem(sendObj)
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const renderTooltip = (props) => {
    return <Tooltip id="button-tooltip">{props}</Tooltip>
  };

  const columns = [
    {
      id: "item_type",
      name: t('create_emenu_item_grid_column_type'),
      selector: row => row.type_name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.type_name)}
        >
          <span>
            {row.type_name}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "item_name",
      name: t("create_emenu_item_grid_column_item"),
      selector: row => row.name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.name)}
        >
          <span>
            {row.name}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "price",
      name: t('create_emenu_item_grid_column_price'),
      selector: row => row.price,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.currency_symbol + ' ' + row.price)}
        >
          <span>
            {row.currency_symbol + ' ' + row.price}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "item_description",
      name: t('create_emenu_item_grid_column_description'),
      selector: row => row.description,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.description)}
        >
          <span>
            {row && row.description && row.description.slice(0, 20) + (row.description.length > 20 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "20%",
      style: {
        cursor: "text"
      },
    },
    {
      id: "item_url",
      name: t('create_emenu_item_grid_column_picture'),
      selector: row => row.image_url,
      cell: (row) => (
        row.image_url && row.image_url !== null ? <img style={{ width: "50px", height: 'auto', margin: '5px 0' }} src={row.image_url} /> :
          <img style={{ width: "50px", height: 'auto', margin: '5px 0' }} src={restDummyItemImage} />
      ),
      width: "15%",
      style: {
        cursor: "text"
      },
    },
    {
      id: 'item_action',
      name: t('create_emenu_item_grid_column_action'),
      button: true,
      cell: row => (
        <>
          <div className="d-flex">
            <span onClick={() => changeOrder(row, "moveUp")}><i class="fa fa-caret-up"></i></span>
            <span onClick={() => changeOrder(row, "moveDown")}><i class="fa fa-caret-down"></i></span>
            <span onClick={() => editItem(row)}><i className="fa fa-pencil-square" ></i></span>
            <span onClick={() => handleShow(row)}><i className="fa fa-times-circle"></i></span>
          </div>
        </>
      ),
      width: "20%",
    }
  ];

  const editItem = (item) => {
    let d = { ...cuisineItem }
    d.itemId = item.id
    d.cuisineId = item.cuisine_id
    d.cuisineType = item.section_id
    d.itemName = item.name
    d.itemPrice = item.price
    d.itemDescription = item.description
    d.itemPicUrl = item.image_url
    d.menu_id = item.menu_id
    setCuisineItem(d)
    setIsTempUpload1(false)
  }

  const handleShow = (rowValue) => {
    setItemData(rowValue)
    setShow(true);
  }

  const deleteData = () => {
    eMenuApi.deleteItemFromMenu(itemData)
      .then(res => {
        if (res.success) {
          setShow(false)
          let sendObj = {
            userId: userData.id,
            menu_id: res.data.menu_id,
            cuisine_id: data.cuisine_id,
            language_code: selected
          }
          getItem(sendObj)
        } else {
          toast.error(res.msg)
        }
      })
      .catch(error => {
        toast.error(error)
      })
  }

  const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const getItem = async (sendObj) => {
    await eMenuApi.getItem(sendObj)
      .then(res => {
        if (res.success) {
          let result = groupBy(res.data, 'section_id')
          setResult(result)
          setDisplayOrder(res.display_order)
          setRestMenuItem(res.data)
          // setMenu_id(res.data.menu_id)
          getMenuPreview(sendObj)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getMenuPreview = async (sendObj) => {
    await eMenuApi.getMenuPreview(sendObj)
      .then(res => {
        if (res.success) {
          setMenuPreview(res.data)
        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: eMenuPrice,
            //tax: 2 // add the VAT or tax amount here
          },
        },
      ],
    })
  }

  const onApprove = (data1, actions) => {
    return actions.order.capture().then(function (res) {
      // save transaction data in db
      let invoice_no = moment(new Date).format('DD/MM/YYYY')
      invoice_no = invoice_no + '/ALLM'
      invoice_no = invoice_no + (userEu ? '/EU' : '/OE')
      let full_name = userData.first_name + (userData.last_name ? ' ' + userData.last_name : '')
      if (res.status == 'COMPLETED') {
        let saveObj = {
          user_service_id: data.userServiceId,
          user_name: full_name,
          invoice_no: invoice_no,
          transaction_id: res.purchase_units[0].payments.captures[0].id,
          invoice_date: moment().format('YYYY-MM-DD HH:mm:ss '),
          amount: eMenuPrice,
          vat: userEu ? vat.VAT : 0,
          exchange_rate: exchangeRate,
          language_code: userData.language_code,
          invoice_status: 'PAID',
          user_email: userData.email,
          service_for: 'E-Menu'
        }
        savePaymentData(saveObj)
      }
    });
  }

  const onError = (err) => {
    toast.error(t('error_in_payment.'))
  }

  const savePaymentData = async (data) => {
    await paymentApi.savePaymentData(data)
      .then(res => {
        if (res.success) {
          toast.success(t('create_emenu_payment_done'))
          if (data.transaction_id) {
            navigate('/my-payment', {
              state: data
            });
          }

        } else {
          toast.error(res.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="register-page">
        <div className="container-fluid">
          <div className="row d-flex m-0">
            <div className="col-lg-12 pr-0 pl-0 page-header">
              <header className="entry-header">
                <h1>{t('home_EMenu_label')}</h1>
              </header>
            </div>
          </div>
        </div>
      </div>
      <div className="cont-bg register-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>
                {t('user_generate_eMenu_h2')}
              </h2>
              <p>{t('user_generate_eMenu_p')}</p>
            </div>
          </div>
          {/* <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('user_generate_Restaurant_Information')}
                  </h4>
                </div>
              </div>
              <Form
                onSubmit={handleSubmit}
                noValidate
                validated={validated}>
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-3">
                    <div className="input-group col-xs-12">
                      <div className="text-center col">
                        <Form.Group >
                          {data.logoUrl == "" || data.logoUrl == undefined ? (
                            <div className="edit-profile-img mb-2 text-center">
                              <img src={restDummyLogo} />
                            </div>
                          ) : (
                            <div className="edit-profile-img mb-2 text-center">
                              {
                                isTempUpload ?
                                  <><img src={
                                    URL.createObjectURL(data?.logoUrl)
                                  } /></>
                                  :
                                  <><img src={data?.logoUrl} /></>
                              }
                            </div>
                          )}
                          {" "}
                          <div className="text-center mt-3">
                            {data.logoUrl == "" || data.logoUrl == undefined ?
                              <>
                                <input name="logoUrl" className="pt-1" type="file" accept="image/*" style={{ display: "none" }} id="restaurantLogoUpload" onChange={(e) => handleChanges(e)}
                                />
                                <Button variant="primary" className="mb-1 me-3 btn btn-sm" onClick={(e) => { upload(); }}>{t('user_generate_upload_logo_image')}</Button>
                                {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                              </>
                              :
                              <Button variant="primary" className="mb-1 me-3 btn btn-sm" onClick={(e) => { remove(); }}>{t('createEmenu_removeCompanyLogo_btn')}</Button>}
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <Form.Group className="mb-3">
                      <Form.Control type="text" id="restaurantName"
                        required
                        isInvalid={isError ? isError : isRestauranError}
                        placeholder={t('createEMenu_placeHolder_restaurantName')}
                        name="restaurantName"
                        value={data.restaurantName}
                        onChange={(e) => handleChanges(e)} />
                      <Form.Control.Feedback type="invalid">
                        {data.restaurantName == "" ? (
                          t('createEMenu_restaurantName_error')
                        ) : isError ? <>
                          {t('createEmenu_specialChar_error')}
                        </> : isRestauranError ? t('character_length') : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <Form.Group >
                      <Form.Select className="form-select selection"
                        aria-label="Default select example"
                        name="cuisine_id"
                        onChange={(e) => handleChanges(e)}
                        required
                        value={data.cuisine_id}
                      >
                        <option value="">{t('select_cuisine')}</option>
                        {
                          cuisineBackground && cuisineBackground.map(item => (
                            <option
                              key={item.id}
                              selected={data.cuisine_id === item.id ? data.cuisine_id : ''}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {data.cuisine_id == '' ?
                          t('cuisine_required') : ''
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control type="text" id="website"
                        // required
                        placeholder={t('createEMenu_placeHolder_website')}
                        name="website"
                        value={data.website}
                        isInvalid={isWebsiteError}
                        onChange={(e) => handleChanges(e)} />
                      <Form.Control.Feedback type="invalid">
                        {/* {data.website == ' ' ? (
                                t('register_website_error')
                              ) : */}
                        {t('register_website_valid_error')}
                        {/* } */}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="phone" aria-describedby="phone" placeholder={t('register_phone_label')}
                        name="phone"
                        value={data.phone}
                        onChange={(e) => handleChanges(e)} />
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control type="e-mail" id="email" aria-describedby="e-mail"
                        // required
                        placeholder={t('register_email_label')}
                        value={data.email}
                        name="email"
                        onChange={(e) => handleChanges(e)}
                        isInvalid={isEmailError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {/* {" "}
                              {data.email == "" ? (
                                t('register_email_error')
                              ) : (
                                <> */}
                        {t('regiser_email_error_valid')}
                        {/* </>
                              )} */}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Select className="form-select selection"
                        id="currencyName"
                        aria-describedby="currencyName"
                        placeholder={t('createEmenu_placeHolder_currency')}
                        name="currencyName"
                        value={data.currencyName}
                        onChange={(e) => handleChanges(e)}
                      >
                        <option value="">{t('select_currency')}</option>
                        {
                          allCurrency && allCurrency.map(item => (
                            <option
                              key={item.id}
                              value={item.id}
                            >
                              {item.name + ' - ' + item.currency_name + ' (' + item.currency_symbol + ')'}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="Street"
                        aria-describedby="address" placeholder={t('createEmenu_placeHolder_address')}
                        name="address"
                        value={data.address}
                        onChange={(e) => handleChanges(e)} />
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control
                        required
                        as="select"
                        aria-label="Default select example" name="country"
                        id="country"
                        placeholder={t('register_Country_drop')}
                        value={data.country}
                        onChange={(e) => handleChanges(e)} >
                        <option value="">{t('register_Country_drop')}</option>
                        {
                          countriesList &&
                            countriesList.length ? countriesList.map(item => {
                              return (
                                <option key={`country-${item.id}`} value={item.id}>{item.name}</option>
                              )
                            }) : " "
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {t('register_country_error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control
                        aria-label="Default select example"
                        required={data.otherCity !== "" ? false : true}
                        name="city_id"
                        as="select"
                        id="city"
                        placeholder={t('register_City_drop')}
                        value={data.city_id}
                        onChange={(e) => handleChanges(e)} >
                        <option value="">{t('register_City_drop')}</option>
                        {
                          cityList && cityList.length ? cityList.map(item => {
                            return (
                              <option key={`city-${item.id}`} value={item.id}>{item.name}</option>
                            )
                          }) : " "
                        }
                      </Form.Control>
                      {loadingCity ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : null}
                      <Form.Control.Feedback type="invalid">
                        {t('register_city_error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <Form.Group className="mb-3">
                      <Form.Control type="text"
                        disabled={data && data.city_id && data.city_id.length && data.city_id.length !== "" ? true : false}
                        id="otherCity"
                        name="otherCity"
                        aria-describedby="otherCity" placeholder={t('register_Other_city')}
                        value={data.otherCity}
                        onChange={(e) => handleChanges(e)} />
                    </Form.Group>
                  </div>

                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-secondary">{t('createEmenu_save_btn')}</button>
                  </div>
                </div>
              </Form>
              {/* </Accordion.Body> */}
            </div>
          </div>
          {/* </Accordion.Item> */}

          {/* select background image part */}
          {/* <Accordion.Item eventKey="1"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('createEmenu_chooseBg')}
                  </h4>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12 col-xs-12">
                  <div className="row justify-content-center text-center cuisine d-flex">
                    {
                      cuisineBackground && cuisineBackground.map(item => {
                        return (
                          <div className="col" key={item.id} onClick={() => getResTmenuBg(item.id, 'bg')}>
                            <div className={`${section2.bgId === item.id ? 'card border-card' : 'card'}`}>
                              <img src={item.image_url} className="card-img" alt={item.name} />
                              <div className="card-body">
                                <p className="card-text">{item.name}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="col-md-6 col-lg-8 col-sm-12 col-xs-12 ">
                  <div className="card showbackPreview ">
                    {menuPreview && menuPreview[0] && menuPreview[0].background_img ? <img className="bgImage" src={`${menuPreview && menuPreview[0] && menuPreview[0].background_img}`} alt="" srcset="" /> : <h4 className='select_cuisine1'>{t("select_cuisine_img")}</h4>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('createEmenu_chooseDesign')}
                  </h4>
                </div>
              </div>
              <Form>
                <Form.Group controlId='tempId'>
                  <div className="row justify-content-center text-center design d-flex">
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                      <div className="card">
                        <img src={Template1} alt="..." />
                        <div className="card-body">
                          <ul>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_frame')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_picture')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_title')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_description')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_price')}</span></li>

                          </ul>
                          <div className="form-check">
                            <Form.Check
                              type="radio"
                              id={`default-${1}`}
                              value="1"
                              name="templateId"
                              checked={section2.templateId == 1 ? true : false}
                              onChange={(e) => getResTmenuBg(e.target.value, 'temp')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                      <div className="card">
                        <img src={Template2} alt="..." />
                        <div className="card-body">
                          <ul>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_frame')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_picture')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_title')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_price')}</span></li>
                          </ul>
                          <div className="form-check">
                            <Form.Check
                              type="radio"
                              id={`default-${2}`}
                              value="2"
                              name="templateId"
                              checked={section2.templateId == 2 ? true : false}
                              onChange={(e) => getResTmenuBg(e.target.value, 'temp')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                      <div className="card">
                        <img src={Template3} alt="..." />
                        <div className="card-body">
                          <ul>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_title')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_description')}</span></li>
                            <li><span className="text-black">{t('createEmenu_chooseDesign_price')}</span></li>
                          </ul>
                          <div className="form-check">
                            <Form.Check
                              type="radio"
                              id={`default-${3}`}
                              value="3"
                              checked={section2.templateId == 3 ? true : false}
                              name="templateId"
                              onChange={(e) => getResTmenuBg(e.target.value, 'temp')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
              {/* </Accordion.Body> */}
            </div>
          </div>
          {/* </Accordion.Item> */}

          {/* Add item part */}
          {/* <Accordion.Item eventKey="3"> */}
          <div className="card mb-3">
            <div className="card-body">
              {/* <Accordion.Header> */}
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h4 className="form-heading">
                    {t('create_emenu_section3_add_items')}
                  </h4>
                </div>
              </div>
              {/* </Accordion.Header>
                  <Accordion.Body> */}
              <Form
                onSubmit={handleSubmit2}
                noValidate
                validated={validated1}
              >
                <div className="row ml-0 mr-0">
                  <div className="col-md-12  col-sm-12 col-xs-12">
                    <div className="form-group">
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0">
                  <div className="col-md-3 mb-3 col-sm-12 col-xs-12">
                    <Form.Group >
                      <Form.Select className="form-select selection"
                        aria-label="Default select example"
                        name="cuisineType"
                        required
                        onChange={(e) => handleChanges1(e)}
                        value={cuisineItem.cuisineType}
                      >
                        {
                          sectionList && sectionList.map(item => (
                            <option value={item.id} key={`section_list${item.id}`}>{item.name}</option>
                          ))
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {cuisineItem.cuisineType == '' ?
                          t('createEMenu_restaurantItemType_error') : ''
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="itemName"
                        placeholder={t('create_emenu_item_name')}
                        name="itemName"
                        required
                        value={cuisineItem.itemName}
                        onChange={(e) => handleChanges1(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('createEMenu_restaurantItemName_error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="itemPrice"
                        aria-describedby="itemPrice"
                        placeholder={t('create_emenu_item_price')}
                        name="itemPrice"
                        required
                        value={cuisineItem.itemPrice}
                        onChange={(e) => handleChanges1(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('createEMenu_restaurantItemPrice_error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <Form.Group className="mb-3">
                      <Form.Control type="text"
                        id="itemDescription"
                        aria-describedby="itemDescription"
                        placeholder={t('create_emenu_item_description')}
                        name="itemDescription"
                        value={cuisineItem.itemDescription}
                        onChange={(e) => handleChanges1(e)}
                      />
                    </Form.Group>
                  </div>

                  {/* Item image upload */}
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <Form.Group >
                      {cuisineItem.itemPicUrl == "" || cuisineItem.itemPicUrl == undefined ? (
                        <div className="edit-profile-img mb-2 text-center">
                          <img src={restDummyItemImage} />
                        </div>
                      ) : (
                        <div className="edit-profile-img mb-2 text-center">
                          {
                            isTempUpload1 ?
                              <><img src={URL.createObjectURL(cuisineItem?.itemPicUrl)} /></>
                              :
                              <><img src={cuisineItem?.itemPicUrl} /></>
                          }
                        </div>
                      )}
                      {" "}
                      <div className="text-center mt-3">
                        {cuisineItem.itemPicUrl == "" || cuisineItem.itemPicUrl == undefined ?
                          <>
                            <input name="itemPicUrl" className="pt-1" type="file" accept="image/*" style={{ display: "none" }} id="restaurantMenuItemUpload" onChange={(e) => handleChanges1(e)}
                            />
                            <Button variant="primary" className="mb-1 btn btn-sm" onClick={(e) => { upload1(); }}>{t('create_emenu_item_upload_button')}</Button>
                            {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                          </>
                          :
                          <Button variant="primary" className="mb-1 me-3 btn btn-sm" onClick={(e) => { remove1(); }}>{t('createEmenu_removeCompanyLogo_btn')}</Button>}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-secondary">
                      {cuisineItem.itemId === '' || cuisineItem.itemId === null ? t('create_emenu_add_item') : t('create_update_item')}</button>
                  </div>
                </div>
              </Form>
              <div className="table-responsive">
                {restMenuItem && restMenuItem.length ? <DataTable
                  data={restMenuItem}
                  pagination={true}
                  columns={columns}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  customStyles={customStyles}
                  noDataComponent={t(`no_record_msg`)}
                />
                  : null}
              </div>
              {/* </Accordion.Body> */}
            </div>
          </div>
          {/* </Accordion.Item>
          </Accordion> */}

          {/* Preview part */}
          {restMenuItem && restMenuItem.length ?
            <div className="card mb-3">
              <div className="card-body">
                <div className="row ">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <h4 className="form-heading accordion-button">
                      {t("menu_preview")}
                    </h4>
                  </div>
                </div>
                <div className="row ml-0 mr-0">
                  <div className="col-md-6 col-sm-12 col-xs-12 selct-temp d-flex">
                    <div className="form-group" style={{ width: "300px" }}>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => getResTmenuBg(e.target.value, 'temp')}>
                        <option selected={section2.templateId === 1 ? true : false} value="1">{t("template_1")}</option>
                        <option selected={section2.templateId === 2 ? true : false} value="2">{t("template_2")}</option>
                        <option selected={section2.templateId === 3 ? true : false} value="3">{t("template_3")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 selct-back d-flex">
                    <div className="form-group" style={{ width: "300px" }}>
                      <div className="form-group">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => getResTmenuBg(e.target.value, 'bg')}>
                          {
                            cuisineBackground && cuisineBackground.map(item => (
                              <option
                                key={item.id}
                                selected={data.background_id === item.id ? data.background_id : ''}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center menu-card">
                  <div className="row justify-content-center d-flex">
                    <div className="col-md-12">
                      <div className="container">
                        <Preview result={result} previewKey="menuPreview" menuPreview={menuPreview} selectedTemplete={section2.templateId} />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button type="submit" onClick={() => {
                      getCurrencyExchangeRate()
                      setSavePreview(true)
                      saveQrUrl();
                    }} className="btn btn-danger mt-5">{t("save_menu")}</button>
                  </div>
                  {savePreview === true ?
                    <>
                      <QrComponent previewKey="menuPreview" menuPreview={menuPreview} restMenuItem={restMenuItem} />
                    </>
                    :
                    ""
                  }
                </div>
              </div>
            </div>
            : null}
          {savePreview && userRestData && userRestData.valid_till === null ? (
            <div className="text-center justify-content-center d-flex">
              <button type="button" onClick={() => setShowPayPal(true)} className="btn btn-secondary payment-btn">{t('create_emenu_make_payment')}</button></div>) : null}
          <div className="row ">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <Link to="/my-services"> <span>
                <i className="fa fa-angle-double-left"></i>
                {t("back")}
              </span> </Link>
            </div>
          </div>
        </div>
      </div>
      {show ? <DeleteModel show={show} handleClose={handleClose} DeleteData={deleteData}></DeleteModel> : null}
      {
        showPayPal ? <PayPal showPaypal={showPayPal} handleClose={handleClose1} createOrder={createOrder} onApprove={onApprove} onError={onError} otherData={{ eMenuPrice: eMenuPrice, serviceFor: 'E-Menu' }}></PayPal> : null
      }
      {loading ? <Loader /> : <></>}
    </>

  )
}

export default CreateEMenu;