import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import myLogo from "../../assets/images/LOGO_CHROMATYCZNE.png";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/languageContext"

const Login = () => {
  const authState = useContext(LanguageContext);
  const { t } = useTranslation();
  const [lgShow, setLgShow] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["AllMenuToken"]);
  const [loginContent, setLoginContent] = useState([]);
  let authApi = new AuthApi();
  // let loginContent
  const handleChanges = async (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    d[name] = value;
    setData(d);
  };


  const location = useLocation()
  let sp = (location.pathname).split("/")
  let lang = sp[1]
  const getLanguage = (async () => {
    await authApi.getAllLanguages().then((response) => {
      if (lang) {
        let checkLanguage_code = response && response.data && response.data.find((item) => item && item.language_code == lang)
        if (checkLanguage_code) {
          authState.updateLanguage(checkLanguage_code.language_code)
        }
        else {
          navigate("/login")
        }
      }
    })
  })

  useEffect(() => {
    getLanguage()
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      authApi
        .signIn(data)
        .then((response) => {
          if (response.success) {
            toast.success(
              `${t("welcome")} ${response.data.first_name && response.data.first_name !== ""
                ? response.data.first_name
                : ""
              }`
            );
            setCookie("AllMenuToken", response.data.jwtToken, {
              path: "/",
              maxAge: 60 * 60 * 100,
              expires: new Date(Date.now() + 60 * 60 * 100),
            });
            delete response.data.jwtToken;
            setCookie("AllMenuUser", response.data, {
              path: "/",
              maxAge: 60 * 60 * 100,
              expires: new Date(Date.now() + 60 * 60 * 100),
            });
            authState.updateLanguage(response.data.language_code)
            if (response.data.user_role === "ADMIN") {
              navigate("/manage-registrations");
            } else {
              navigate("/dashboard");
            }
          } else {
            if (response.flag == "3") {
              toast.error(t("mail_not_exist_msg"));
              setLoginContent(0);
            }
            else if (response.flag == "2") {
              toast.error(t("delete_msg"));
              setLoginContent(0);
            }
            else if (response.flag == "1") {
              toast.error(t("incorrect_password"));
              setLoginContent(0);
            }
            else {
              toast.error(t("incorrect_login_detail"));
              setLoginContent(1);
            }
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <div className="register-page">
        <Container fluid>
          <Row className="d-flex m-0">
            <Col lg={12} className="page-header login_image pr-0 pl-0">
              <header className="entry-header">
                <h1>{t("btn_login")}</h1>
              </header>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cont-bg register-page">
        <Container>
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Row>
              <Col lg={12} className="text-center">
                <h2>{t("login_h2_label")}</h2>
                {loginContent === 0 ? (
                  <p>{t("login_Para")}</p>
                ) : (
                  <p>
                    {t("login_page_label1")} <br /> {t("login_page_label2")}{" "}
                  </p>
                )}
              </Col>
            </Row>
            <div className="justify-content-center d-flex login">
              <div className="card mb-3">
                <div className="card-body">
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <h4 className="form-heading">{t("login_Header")}</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          required
                          type="text"
                          name="username"
                          id=""
                          placeholder={t("register_email_label")}
                          value={data.username}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register_email_error")}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder={t("password_login")}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          type="password"
                          required
                          name="password"
                          id="exampleInputPassword"
                          value={data.password}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register_passReqd_error")}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="login_link">

                        <div>
                          <a href="/register">
                            {t("new_user_register_now")}
                          </a>
                        </div>
                        <div>
                          <a href="/forgot-password">
                            {t("forgot_Password_label")}
                          </a>
                        </div>

                      </div>

                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <Row>
              <Col
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="justify-content-center d-flex"
              >
                <a href="dashboard.html">
                  <button type="submit" className="btn btn-danger">
                    {t("btn_login")}
                  </button>
                </a>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default Login;
