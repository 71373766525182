import React from 'react'

import { useTranslation } from "react-i18next";

const TermsAndCondition = () =>  {
  const { t } = useTranslation();
  return (
    <>
          <div className="register-page">
        <div className="container-fluid">
          <div className="row d-flex m-0">
            <div className="col-lg-12 pr-0 pl-0 page-header">
              <header className="entry-header">
                <h1>{t('terms_conditions')}</h1>
              </header>
            </div>
          </div>
        </div>
      </div>

      <div className="cont-bg register-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>
                {t('content_awaited')}
              </h2>
            </div>
          </div>

          </div>

</div>
    </>
  )
}

export default TermsAndCondition