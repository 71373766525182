import React, { useState, useContext } from "react";
import {
    Button,
    Modal,
    Form,
    InputGroup,
    Container,
    Row,
    Col,
    Dropdown,
} from "react-bootstrap";
import myLogo from "../../assets/images/LOGO_CHROMATYCZNE.png";
import AuthApi from "../../api/authApi";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/languageContext"

const NotFount = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="register-page">
                <Container fluid>
                    <Row className="d-flex m-0">
                        <Col lg={12} className="page-header login_image pr-0 pl-0">
                            <header className="entry-header">
                                <h1>404: Page not found</h1>
                            </header>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="cont-bg register-page">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <h2>We couldn't find any matches!</h2>

                            <p>Please check the spelling or try searching something else</p>

                        </Col>
                    </Row>
                    <Col lg={12} className="text-center mb-5">
                    <Link to="/emenu"> <button className="btn-menu-not"> {t('home_EMenu_label')}</button></Link>
                    <Link to="/eproduct"> <button className="btn-products-not mt-2">{t('home_MyProduct_btn')}</button></Link>
                    </Col>
                </Container>
            </div>
        </>
    );
};

export default NotFount;
