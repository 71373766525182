import React from 'react'
// import remove from "./../assets/images/remove.png";
import {Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function DeleteModel(props) {
  const { t } = useTranslation();
  return (     
   <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >{t("delete")}</Modal.Title>
        </Modal.Header>
        <div className='Delete_img'>
        {/* <img className='icon-box' src={remove} alt="logo"/> */}
        </div>
        <Modal.Body>{t("delete_confirmation")}
          {/* <h3 className='Delete_img'>This action cannot undone.</h3> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
          {t("paypal_cancel_button")}
          </Button>
          <Button variant="danger" onClick={props.DeleteData}>
            {t("delete_button")}
          </Button>
        </Modal.Footer>
      </Modal>




  )
}

export default DeleteModel


