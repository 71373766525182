import React from 'react'
// import remove from "./../assets/images/remove.png";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function PdfAlert(props) {
  const { t } = useTranslation();
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("alert_title")}</Modal.Title>
      </Modal.Header>
      <div className='Delete_img'>
      </div>
      <Modal.Body>{t("pdf_alert")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleClose}>
          {t("okay")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PdfAlert
