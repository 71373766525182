import BaseApi from "./baseApi";

class AdminApi extends BaseApi {
  async getAllUsersList(data) {
    return this.API({
      url: `/admin/getallusers`,
      method: "post",
      data: data
    });
  }

  async changeUserStatus(data) {
    return this.API({
      url: '/admin/changeuserstatus',
      data: data,
      method: 'post'
    })
  }

  async deleteAccount(data) {
    return await this.API({
      url: "admin/delete-account",
      data: data,
      method: 'post'
    })
  }

  async editProfile(data) {
    return await this.API({
      url: 'admin/update-profile',
      data: data,
      method: 'post'
    })
  }

  async getAllServices() {
    return await this.API({
      url: "/admin/get-services",
      method: "get"
    })
  }

  async updateServices(data) {
    return await this.API({
      url: "/admin/update-services",
      method: "post",
      data: data
    })
  }

  async servicesAndPayments(data) {
    return this.API({
      url: `/admin/servicesandpayments`,
      method: 'post',
      data: data,
    })
  }

  async passwordChangeAfterLogin(data) {
    return await this.API({
      url: 'admin/update-password',
      data: data,
      method: "post"
    })
  }

  async getAllCuisines() {
    return this.API({
      url: `/admin/getAllCuisines`,
      method: "post",
    });
  }

  async getAllCategory() {
    return this.API({
      url: `/admin/getAllcategories`,
      method: "post",
    });
  }

  async getAllcategories() {
    return this.API({
      url: `/admin/getAllcategories`,
      method: "post",
    });
  }

  async getAllPages() {
    return await this.API({
      url: "/admin/get-pages",
      method: "get"
    })
  }


  async createPage(data) {
    return await this.API({
      url: 'admin/create-page',
      data: data,
      method: "post"
    })
  }

  async getPage(data) {
    return await this.API({
      url: 'admin/get-page',
      data: data,
      method: "post"
    })
  }

  async createMetaData(data) {
    return await this.API({
      url: 'admin/create-meta-data',
      data: data,
      method: "post"
    })
  }

  async getMetaData(data) {
    return await this.API({
      url: 'admin/get-meta-data',
      data: data,
      method: "post"
    })
  }


  async saveCuisine(data) {
    //console.log("🚀 ~ file: adminApi.js:123 ~ AdminApi ~ saveCuisine ~ data:", data)

    return await this.API({
      url: 'admin/save-cuisine',
      data: data,
      method: "post"
    })
  }

  async saveCategory(data) {
    //console.log("🚀 ~ file: adminApi.js:123 ~ AdminApi ~ saveCuisine ~ data:", data)

    return await this.API({
      url: 'admin/save-category',
      data: data,
      method: "post"
    })
  }

  async getCuisineLangContent(data) {
    return await this.API({
      url: 'admin/get-cuisine-lang-content',
      data: data,
      method: "post"
    })
  }

  async getCategoryLangContent(data) {
    return await this.API({
      url: 'admin/get-category-lang-content',
      data: data,
      method: "post"
    })
  }


  async createCuisineLanguage(data) {
    return await this.API({
      url: 'admin/create-cuisine-language',
      data: data,
      method: "post"
    })
  }

  async createCategoryLanguage(data) {
    return await this.API({
      url: 'admin/create-category-language',
      data: data,
      method: "post"
    })
  }

  async getSingleCuisine(data) {
    return await this.API({
      url: 'admin/get-single-cuisine',
      data: data,
      method: "post"
    })
  }

  async getSingleCategory(data) {
    return await this.API({
      url: 'admin/get-single-category',
      data: data,
      method: "post"
    })
  }
  async deleteCuisine(data) {
    return this.API({
      url: '/admin/deleteCuisine',
      data: data,
      method: 'post'
    })
  }

  async deleteCategory(data) {
    return this.API({
      url: '/admin/deleteCategory',
      data: data,
      method: 'post'
    })
  }

  async getUnpaidUsers(data) {
    return this.API({
      url: "/admin/getUnpaidUsers",
      data: data,
      method: "post",
    });
  }

  async changeServiceStatus(data) {
    return this.API({
      url: "/admin/changeServiceStatus",
      data: data,
      method: "post",
    });
  }

  async allServices(data) {
    return await this.API(
      {
        url: "/admin/allServices",
        data: data,
        method: "post"
      }
    )
  }


}

export default AdminApi;
