import BaseApi from "./baseApi";

class PaymentApi extends BaseApi {
  async savePaymentData(data) {
    return this.API({
      url: "/payment/savePaymentData",
      data: data,
      method: "post",
    });
  }

  async getPayment(transaction_id) {
    return this.API({
      url: `/payment/getPayment/${transaction_id}`,
      method: "get",
    });
  }
  
  async getAllUserPayments(data) {
    return this.API({
      url: '/payment/getAllUserPayments',
      data: data,
      method: 'post'
    })
  } 

  async savePdf(data) {
    return this.API({
      url: '/payment/savePdf',
      data: data,
      method: 'post'
    })
  }

}

export default PaymentApi;
