import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Navbar,
  Nav
} from "react-bootstrap";
import myLogo from "../../assets/images/new_logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Cookies, useCookies } from "react-cookie";
import LogoafterLogin from "../../assets/images/All-Menu-Logo-InnerPage.png"


const Header = ({ logoutUser = null }) => {
  let cookies = new Cookies();
  let userData = cookies.get("AllMenuUser") || null;
  const [cookie, setCookie] = useCookies(["AllMenuUser"]);
  const [selected, setSelected] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const { t } = useTranslation();
  useEffect(() => {
    if (
      userData &&
      userData.language_code &&
      userData.language_code.length !== null
    ) {
      i18next.changeLanguage(userData.language_code);
      setSelected(userData.language_code);
    }
  }, [cookie]);

  return (
    <>

      <header className="site-header">
        <div className="nav-bar">
          <Container>
            <Row>
              <Col sm={6} lg={6} xs={12}>
                <h1 className="site-title inner-header">
                  {userData ? (<img src={LogoafterLogin} alt="" className="img-fluid login_image" />) : (<img src={myLogo} alt="" className="img-fluid" />)
                  }
                </h1>
              </Col>
              <Col
                sm={6}
                lg={6}
                xs={12}
                className="flex justify-content-end align-content-center"
              >
                <nav className="site-navigation justify-content-end align-items-center d-block topSpace">
                  <div className="text-right">WELCOME ADMIN!</div>
                  <div className="hamburger-menu d-lg-none">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  {userData ? (
                    <div
                      className="btn-group select-box"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className="btn btn-primary primaryButton btn-icon-text"
                        onClick={() => logoutUser()}
                      >
                        <i className="fa fa-unlock"></i>
                        {t("btn_logout")}
                      </button>
                    </div>
                  ) : (
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <Link to="/login">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-icon-text"
                        >
                          <i className="fa fa-user"></i>
                          {t("btn_login")}
                        </button>
                      </Link>
                      <Link to="/register">
                        <button
                          type="button"
                          className="btn btn-primary primaryButton btn-icon-text"
                        >
                          <i className="fa fa-lock"></i>
                          {t("register_regBtn_label")}
                        </button>
                      </Link>
                    </div>
                  )}
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
        <Navbar bg="danger" expand="lg" variant="dark" >
          <div className="container-fluid d-flex flex-row-reverse">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav>
                <Nav.Link href="/manage-registrations"><i className="fa fa-registered ml-5"></i>Registrations </Nav.Link>
                <Nav.Link href="/view-unpaid-users"><i className="fa fa-list ml-5"></i>Products</Nav.Link>
                <Nav.Link href="/view-services-and-payments"><i className="fa fa-cc-paypal ml-5"></i>Payments </Nav.Link>
                <Nav.Link href="/update-price-of-services"><i className="fa fa-eur ml-5"></i>Services Price </Nav.Link>
                <Nav.Link href="/manage-cuisines-&-categories"><i className="fa fa-th ml-5"></i>Cuisines & Categories </Nav.Link>
                <Nav.Link href="/cms"><i className="fa fa-file-text ml-5"></i>CMS </Nav.Link>
                <Nav.Link href="/change-password"><i className="fa fa-key ml-5"></i>Change Password </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
