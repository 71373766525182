import BaseApi from "./baseApi";
class AuthApi extends BaseApi {
  async signup(data) {
    return await this.API({
      url: "/auth/signup",
      data: data,
      method: "post"
    })
  }

  async signIn(data) {
    return await this.API({
      url: "/auth/signin",
      data: data,
      method: "post"
    })
  }

  async emailVerify(data) {
    return await this.API(
        {
            url: "/auth/emailVerify",
            data: data,
            method: "post"
        }
    )
}
async searchMail(data) {
  return await this.API(
      {
          url:`/auth/searchMail`,
          method: "post",
          data: data,
      }
  )
}
  async forgotPassword(data) {
    return await this.API({
      url: "auth/forgotPassword",
      data: data,
      method: 'post'
    })
  }

  async resetPassWord(data) {
    return await this.API({
      url: "auth/changePassword",
      data: data,
      method: 'post'
    })
  }

  async deleteAccount(data) {
    return await this.API({
      url: "auth/delete-account",
      data: data,
      method: 'delete'
    })
  }
  
  async getAllLanguages(data) {
    return await this.API(
      {
        url: "/auth/getAllLanguages",
        method: "get"
      }
    )
  }

  async getAllCountries(data) {
    return await this.API(
      {
        url: "/auth/getAllCountries",
        method: "get"
      }
    )
  }
  async getAllCities(data) {
    return await this.API(
      {
        url: "/auth/getAllCities",
        data: data,
        method: "post"
      }
    )
  }

  async getLanguage(data) {
    return await this.API(
      {
        url: "/auth/getLanguage",
        method: "post",
        data:data
      }
    )
  }

  async getAllServicePices(data) {
    return await this.API(
      {
        url: "/auth/getAllServicePices",
        method: "post",
        data:data
      }
    )
  }

  async getSinglePage(data) {
    return await this.API({
      url: "/auth/get-single-page",
      data: data,
      method: "post",
    });
  }

    async getAllCuisines() {
    return await this.API(
      {
        url: "/auth/getAllCuisines",
        method: "get"
      }
    )
  }

  async getAllCategory() {
    return await this.API(
      {
        url: "/auth/getAllCategory",
        method: "get"
      }
    )
  }

}
export default AuthApi;