import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Header from "../layouts/users/header";
import Footer from "../layouts/users/footer";
import DashBoard from "../pages/user/dashBoard";
import MyProfile from "../pages/user/myProfile";
import EMenu from "../pages/user/e-menu/createEMenu"
import Services from "../pages/user/services";
import Preview from "../pages/user/preview";
import Payments from "../pages/user/payments";
import EProduct from "../pages/user/e-product/createEProduct";
import Payment from "../pages/user/payment";
import Prices from "../pages/auth/prices";
import AboutUs from "../pages/auth/aboutUs";
import AboutOurServices from "../pages/auth/aboutOurServices";
import Regulations from "../pages/auth/regulations";
import ContactUs from "../pages/auth/contactUs";
import EMenuPreview from "../pages/user/e-menu/eMenuPreview";
import EProductPreview from "../pages/user/e-product/eProductPreview";
import FrontEndUser from '../pages/auth/index'
import TermsAndCondition from "../pages/common/termsAndCondition";
import AllEMenu from "../pages/common/allemenu";
import AllEProduct from "../pages/common/alleproduct";
import ShoppingCart from "../pages/common/shoppingCart";
import EMenuView from "../pages/common/eMenuView";
import EProductView from "../pages/common/eProductView";
import XmlGenerator from "../pages/common/xmlGenerator";
import AppleAppSiteAssociation from "../pages/common/appleAppSiteAssociation";

const UserRouter = ({ logoutUser = null }) => {
  const route = useRoutes([
    { path: '/dashBoard', element: <DashBoard /> },
    { path: "/prices", element: <Prices /> },
    { path: "/about-us", element: <AboutUs /> },
    { path: "/about-our-services", element: <AboutOurServices /> },
    { path: "/regulations", element: <Regulations /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: '/my-profile', element: <MyProfile logoutUser={logoutUser} /> },
    { path: '/modify-emenu', element: <EMenu /> },
    { path: '/my-services', element: <Services /> },
    { path: '/my-payments', element: <Payments /> },
    { path: '/preview', element: <Preview /> },
    { path: '/menu/:menuId', element: <EMenuPreview /> },
    { path: '/product/:productId', element: <EProductPreview /> },
    { path: '/modify-eproduct', element: <EProduct /> },
    { path: '/my-payment', element: <Payment /> },
    { path: '/my-payment/:transactionId', element: <Payment /> },
    { path: '/home', element: <FrontEndUser /> },
    { path: '/termsAndCondition', element: <TermsAndCondition /> },
    { path: '/emenu', element: <AllEMenu /> },
    { path: '/emenu/:language', element: <AllEMenu /> },
    { path: '/emenu/:language/:country', element: <AllEMenu /> },
    { path: '/emenu/:language/:country/:city', element: <AllEMenu /> },
    { path: '/emenu/:language/:country/:city/:restname', element: <AllEMenu /> },
    { path: '/eproduct', element: <AllEProduct /> },
    { path: '/eproduct/:language', element: <AllEProduct /> },
    { path: '/eproduct/:language/:country', element: <AllEProduct /> },
    { path: '/eproduct/:language/:country/:city', element: <AllEProduct /> },
    { path: '/eproduct/:language/:country/:city/:restname', element: <AllEProduct /> },
    { path: '/shopping-cart', element: <ShoppingCart /> },
    { path: '/e-menu/:menuId', element: <EMenuView /> },
    { path: '/e-product/:productId', element: <EProductView /> },
    { path: '/sitemap.xml', element: <XmlGenerator /> },
    { path: '/*', element: <Navigate to="/dashBoard" /> }
  ])

  let pathName = route
  if (pathName.props.match.route.path === "/menu/:menuId" || pathName.props.match.route.path === "/product/:productId" || pathName.props.match.route.path === "/my-payment" || pathName.props.match.route.path === "/e-menu/:menuId" || pathName.props.match.route.path === "/e-product/:productId" || pathName.props.match.route.path === "/sitemap.xml") {
    return (
      <>
        {route}
      </>
    );
  }
  return (
    <>
      <Header logoutUser={logoutUser} />
      {route}
      <Footer />
    </>
  );
};

export default UserRouter;
