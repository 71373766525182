import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import AdminApi from "../../api/adminApi";
import { toast } from "react-toastify";

const PriceofServices = () => {
  const [validated, setValidated] = useState(false);
  const [isEmenuError, setIsEmenuError] = useState(false);
  const [isEproductError, setIsEproductError] = useState(false);
  const [services, setServices] = useState([]);

  let adminApi = new AdminApi();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    adminApi
      .getAllServices()
      .then((res) => {
        if (res.success) {
          setServices(res.data);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  function isNumber(str) {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  }

  const handleChanges = (e) => {
    let a = [...services];
    let value = e.target.value.replace(/\D/g, "");

    a.map((data) => {
      if (data.type === e.target.name) {
        data.price = value;
        data.isUpdate = true;
      }
    });

    if (e.target.name === "EMENU") {
      if (value === "" || value === null || !isNumber(value)) {
        setIsEmenuError(true);
      } else {
        setIsEmenuError(false);
      }
    }
    if (e.target.name === "EPRODUCTS") {
      if (value === "" || value === null || !isNumber(value)) {
        setIsEproductError(true);
      } else {
        setIsEproductError(false);
      }
    }

    let serObj = services.filter(
      (item) => item.price !== "" || item.price === null || isNumber(item.price)
    );
    if (serObj.length && serObj.length > 0) {
      setValidated(true);
    } else {
      setValidated(false);
    }
    setServices(a);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (validated) {
      adminApi
        .updateServices(services)
        .then((response) => {
          if (response.success) {
            toast.success("Service update success!");
          } else {
            toast.error();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  return (
    <div id="updateServices" className="cont-bg register-page">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Manage Service Price</h2>
            <p>
              Admin can view all registrations, can activate/ deactivate the
              registered users.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="cont-bg register-page">
        <Container>
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <div className="justify-content-center d-flex login">
              <div className="card mb-3">
                <div className="card-body">
                  <Row className="d-flex justify-content-center">
                    {services &&
                      services.map((res, i) => (
                        <Col md={8} lg={8} sm={8} xs={8} key={i}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {res.type == "EMENU"
                                ? "Set E-Menu Price:"
                                : "Set E-Product Price:"}
                            </Form.Label>
                            <Form.Control
                              placeholder={res.type}
                              required
                              isInvalid={
                                res.type == "EMENU"
                                  ? isEmenuError
                                  : isEproductError
                              }
                              type="text"
                              name={res.type}
                              value={res.price}
                              onChange={(e) => handleChanges(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              {isEmenuError ? (
                                <span>not a valid E-menu price</span>
                              ) : (
                                <span>not a valid E-product price</span>
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
            <Row>
              <Col
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="justify-content-center d-flex"
              >
                <a href="dashboard.html">
                  <button type="submit" className="btn btn-danger">
                    Update
                  </button>
                </a>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default PriceofServices;
