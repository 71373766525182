import React, { useEffect, useState, useContext } from 'react'
import { Button, Modal, Form, InputGroup, Container, Row, Col, Dropdown } from 'react-bootstrap';
import myLogo from "../../assets/images/All-Menu-Logo-HP.png"
import LogoafterLogin from "../../assets/images/All-Menu-Logo-InnerPage.png"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AuthApi from '../../api/authApi';
import { languageMap } from '../../config'
import { Cookies, useCookies } from 'react-cookie'
import LanguageContext from "../../context/languageContext"
import ReactFlagsSelect from "react-flags-select";
import "./flags.css";
const Header = ({ logoutUser = null }) => {
  const authState = useContext(LanguageContext);
  let cookies = new Cookies()
  let userData = cookies.get('AllMenuUser') || null;
  const [cookie, setCookie] = useCookies(['AllMenuUser']);
  const [selected, setSelected] = useState(authState.language);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const countryCodes = Object.values(languageMap).map(lang => lang.code);
  const customLabels = Object.keys(languageMap).reduce((acc, key) => {
    const { code, label } = languageMap[key];
    acc[code] = label;
    return acc;
  }, {});

  useEffect(() => {
    i18next.changeLanguage(authState.language)
    setSelected(authState.language)
  }, [authState.language]);

  const getLangValue = (obj) => {
    i18next.changeLanguage(obj)
    setSelected(obj)
  }

  const loadSelectedCode = (ccode, type) => {
    let returnCode;
    switch (ccode) {
      case "en":
        returnCode = "US";
        break;
      case "ar":
        returnCode = "SA";
        break;
      case "en-US":
        returnCode = "US";
        break;
      case "US":
        returnCode = "en";
        break;
      case "SA":
        returnCode = "ar";
        break;
      default:
        returnCode = ccode;
        break;
    }
    returnCode = type == "upper" ? returnCode.toUpperCase() : returnCode.toLowerCase();
    return returnCode;
  }

  return (
    <header className="site-header">
      <div className="nav-bar">
        <Container>
          <Row>
            <Col
              sm={12}
              md={8}
              lg={6}
              xs={12}>
              <h1 className="site-title inner-header">
                <Link to={`/home`}>
                  {userData ? (<img src={LogoafterLogin} alt="" className="img-fluid login_image" />) : (<img src={myLogo} alt="" className="img-fluid" />)
                  }
                </Link>
              </h1>
            </Col>
            <Col
              sm={12}
              md={4}
              lg={6}
              xs={12}
              className="flex justify-content-end align-content-center set_select"
            >
              <div>
                <ReactFlagsSelect
                  selected={loadSelectedCode(selected, "upper")}
                  onSelect={(code) => {
                    let langCode = loadSelectedCode(code, "lower"); //since we need country code to work with while translator need 'en'
                    getLangValue(langCode);
                  }}
                  countries={countryCodes}
                  customLabels={customLabels}
                  placeholder="Select Language"
                  className="country-list flags-dropdown"
                />
                <nav className="site-navigation justify-content-end align-items-center d-block">
                  <div className="hamburger-menu d-lg-none">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  {userData ? (
                    <div
                      className="btn-group select-box"
                      role="group"
                      aria-label="Basic example"
                    >
                      <Dropdown className='heder_dropdown'>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='drp_button'>
                          {userData.first_name ? `${userData.first_name}` + ` ` + `${userData.last_name}` : ""}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='show_drop'>
                          <Dropdown.Item className='item_border' href="/dashboard">{t("dashboard")}</Dropdown.Item>
                          <Dropdown.Item className='item_border' href="/my-profile">{t("my_profile")}</Dropdown.Item>
                          <Dropdown.Item className='item_border' href="/my-services">{t("my_services")}</Dropdown.Item>
                          <Dropdown.Item className='item_border' href="/my-payments">{t("my_payments_h1")}</Dropdown.Item>
                          <Dropdown.Item className='item_border' href="/modify-emenu">{t("create_menu")}</Dropdown.Item>
                          <Dropdown.Item className='item_border' href="/modify-eproduct">{t("create_product")}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        type="button"
                        className="btn btn-primary primaryButton btn-icon-text"
                        onClick={() => logoutUser()}
                      >
                        <i className="fa fa-unlock"></i>
                        {t("btn_logout")}
                      </button>
                    </div>
                  ) : (
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <Link to="/login">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-icon-text"
                        >
                          <i className="fa fa-user"></i>
                          {t("btn_login")}
                        </button>
                      </Link>
                      <Link to="/register">
                        <button
                          type="button"
                          className="btn btn-primary primaryButton btn-icon-text"
                        >
                          <i className="fa fa-lock"></i>
                          {t("register_regBtn_label")}
                        </button>
                      </Link>
                    </div>
                  )}
                </nav></div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;
