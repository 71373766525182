
import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { loadproduct } from "../../redux/reducer/productReducer";
import { useDispatch, useSelector } from "react-redux";
import GanetatePdf from "../../components/generatePDF";
import restDummyLogo from "../../assets/images/restDummyLogo.png";
import restDummyItemImage from "../../assets/images/no-image.png";
import cartImg from "../../assets/images/show-my-cart.png";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Base64Img from '../../components/base64Img'
import circle from "../../assets/images/circle.png";
import CommonApi from '../../api/commonApi';

const EProductView = () => {
    const commonApi = new CommonApi();
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip">{props}</Tooltip>
    );
    const { t } = useTranslation();
    const [resultList, setListResult] = useState([])
    const [trueFalse, setTrueFalse] = useState(false)
    let { productId } = useParams();
    let previewId = productId.split('-')[1];
    const dispatch = useDispatch();
    const listData = useSelector((state) => state && state.productReducer.productList && state.productReducer.productList.data);
    let menu_Data = localStorage.getItem('menuItems')
    let arrayMenu = JSON.parse(menu_Data)
    let [showCartButton, setShowCartButton] = useState(arrayMenu)
    const navigate = useNavigate();

    const addItemsToCart = (item) => {
        let itemsString = localStorage.getItem('menuItems')
        let arrayItems = JSON.parse(itemsString)
        if (arrayItems && arrayItems.length > 0 && arrayItems !== null && arrayItems !== undefined && arrayItems !== '') {
            let findItemId = arrayItems.find((s) => s.id === item.id)
            if (findItemId) {
                let headId = arrayItems.findIndex((s) => s.id === item.id)
                let data = arrayItems.splice(headId, 1)
                localStorage.setItem('menuItems', JSON.stringify(arrayItems))
                setShowCartButton(arrayItems)
            }
            else {
                let temp = { ...item, selectedItem: true }
                let items = []
                if (itemsString) {
                    items = JSON.parse(itemsString)
                }
                items.push(item)
                localStorage.setItem('menuItems', JSON.stringify(items))
                setShowCartButton(items)
            }
        }
        else {
            let temp = { ...item, selectedItem: true }
            let items = []
            items.push(temp)
            localStorage.setItem('menuItems', JSON.stringify(items))
            setShowCartButton(items)

        }
        setTrueFalse(!trueFalse)
    }

    useEffect(() => {
        if ((arrayMenu && arrayMenu[0] && arrayMenu[0].list_id) != previewId) {
            localStorage.removeItem('menuItems');
        }
    }, [arrayMenu])

    useEffect(() => {
        let objData = {
            previewId: previewId,
            login: "Nologin"
        }
        dispatch(loadproduct(objData));
    }, [dispatch]);

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    useEffect(() => {
        if (listData && listData.length) {
            let result = groupBy(listData, 'id')
            setListResult(result);
            loadBgImage(listData[0].background_img);
        }
        if (listData && listData.length && arrayMenu && arrayMenu.length) {
            let m = []
            listData.map((menuItem) => {
                let temp = { ...menuItem, selectedItem: false }
                arrayMenu && arrayMenu.map((localItems, i) => {
                    if (menuItem.id == localItems.id) {
                        temp.selectedItem = true
                    }
                });
                m.push(temp)
            })
            let result = groupBy(m, 'id')
            setListResult(result)
        }
    }, [listData]);

    const loadBgImage = async (url) => {
        try {
            const response = await commonApi.getBase64Image({ imageUrl: url });
            if (response.success && response.data) {
                setBackgroundImageUrl(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const mapListData = () => {
        if (listData && listData[0] && listData[0].status === "Active" && listData[0].userStatus === "ACTIVE") {
            return Object.keys(resultList).map((key, sectionIndex) => {
                let array = resultList[key];
                return array.map((item, i) => {
                    return (
                        <>
                            <input className='checked-item' type="checkbox" id={`myCheckbox1-${item.id}`} />
                            <label for={`myCheckbox1-${item.id}`} onClick={() => addItemsToCart(item)} className={`col-lg-4 col-md-6 col-sm-6 col-xs-12 justify-content-center d-flex mb-4 ${showchecked(item.id)}`}>
                                <div className={`${listData[0].template_id == "3" ? "card temp-3" : "card cardHeight"}`}>
                                    <div className='image_height'>
                                        {listData[0].template_id == "3" ? null : item.image_url ? (<Base64Img imageUrl={item.image_url} alt="Item" />) : <img className="logo" src={restDummyItemImage} alt="Logo" style={{ height: "auto" }} />}
                                    </div>
                                    <div className="container-fluid">
                                        <div className='sec_code'>
                                            <span className='text-sm-12 text-left'>{item.sec_name}</span>
                                            <span className='text-sm-12 text-right'> {item.code ? "Code:" : null}{item.code}</span>
                                        </div>
                                        <h4>
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip(item.name)}
                                            >
                                                <b>{(item.name && item.name.slice(0, 15) + (item.name.length > 15 ? "..." : ""))}</b>
                                            </OverlayTrigger>
                                        </h4>
                                        <div className='justify-content-center d-flex text-center' ><span className="price1">{item.currency_symbol + ' ' + item.price}</span>
                                            <span className="quantity"> {item.quantity} {item.unit_name} </span>
                                        </div>
                                        {listData[0].template_id == "2" ? null :
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip(item.description)}
                                            >
                                                <span className="desc">{item.description && item.description.slice(0, 35) + (item.description.length > 35 ? "..." : "")}</span>
                                            </OverlayTrigger>
                                        }
                                    </div>
                                </div>
                            </label>
                        </>
                    )
                })

            })
        }
        else {
            return <h3>This E-Product is not exist.</h3>
        }
    }

    const mapListData1 = () => {
        if (listData && listData[0] && listData[0].status === "Active" && listData[0].userStatus === "ACTIVE") {
            return Object.keys(resultList).map((key, sectionIndex) => {
                let array = resultList[key];
                return array.map((item, i) => {
                    return (
                        <>
                            <input className='checked-item' type="checkbox" id={`myCheckbox1-${item.id}`} />
                            <label for={`myCheckbox1-${item.id}`} onClick={() => addItemsToCart(item)} className={`col-md-12 col-sm-12 col-xs-12 templete3 ${showchecked(item.id)}`}>
                                <div className="container shadow">
                                    <span className='text-sm-10'>{item.sec_name}</span>
                                    <h4>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip(item.name)}
                                        >
                                            <b>{(item.name && item.name.slice(0, 40) + (item.name.length > 40 ? "..." : ""))}</b>
                                        </OverlayTrigger>

                                        <div className='text-sm-12'> {item.code ? "Code:" : null}{item.code}</div>
                                    </h4>
                                    {listData[0].template_id == "2" ? null :
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip(item.description)}
                                        >
                                            <span className="desc">{(item.description && item.description.slice(0, 90) + (item.description.length > 90 ? "..." : ""))}</span>
                                        </OverlayTrigger>

                                    }
                                    <div className='price_unit' >
                                        <span className="price1">{item.currency_symbol + ' ' + item.price}</span>
                                        <span className="quantity1"> {item.quantity} {item.unit_name} </span>
                                    </div>

                                    {/* </div> */}
                                </div>
                            </label>
                        </>
                    )
                })
            })
        }
        else {
            return <h3>This E-Product is not exist.</h3>
        }
    }

    const showchecked = (id) => {
        let itemsString = localStorage.getItem('menuItems')
        let arrayItems = JSON.parse(itemsString)
        if (arrayItems) {
            let findItemId = arrayItems.find((s) => s.id === id)
            if (findItemId) {
                return "mylabel";
            } else {
                return "checklabel";
            }
        } else {
            return "checklabel"
        }
    }

    const pdfHTML = () => {
        return (
            <>
                {
                    Object.keys(resultList).length ?
                        (<div className="container">
                            <div className="card border-0">
                                <div className="card-body p-0">
                                    <div className="text-center menu-card">
                                        <div className="row justify-content-center d-flex">
                                            <div className="col-md-12 p-0">
                                                <div className="container-fluid pdf_button d-flex">
                                                </div>
                                                <div className="container-fluid">
                                                    <div className="card">
                                                        {listData && listData.length > 0 ? (
                                                            <div className="card-img-overlay american_cuisine" style={{
                                                                backgroundImage: `url("${backgroundImageUrl}")`
                                                            }}>
                                                                <div className="d-block justify-content-center mb-4">
                                                                    {
                                                                        listData[0].logo_url ?
                                                                            <Base64Img className="logo" imageUrl={listData[0].logo_url} alt="Logo" />
                                                                            :
                                                                            <img className="logo" src={restDummyLogo} alt="Logo" />
                                                                    }
                                                                    <h2>{listData[0].vendor_name}

                                                                    </h2>
                                                                    <div className="d-block"><a href={`https://${listData[0].website}`} target="_blank">{listData[0].website}</a></div>
                                                                </div>
                                                                <div className="row mb-5">
                                                                    {
                                                                        listData[0].template_id == "3" ? mapListData1() : mapListData()
                                                                    }

                                                                </div>
                                                                {listData && listData.length > 0 ? (
                                                                    <div className="row address">
                                                                        <div className="col-md-6 col-sm-12 col-xs-12 text-left">{listData[0].address ? `${listData[0].address}, ` : null}
                                                                            {listData[0].city_name}, {listData[0].country_name}</div>
                                                                        <div className="col-md-6 col-sm-12 col-xs-12 text-right mobile_set">{listData[0].phone}</div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        :
                        null
                }
            </>
        )
    }

    return (
        <>
            {(listData && listData.length) ?
                <>
                    <div className='container'>
                        <div className="row pdf_button mb-0">
                            <div className="col-md-4 col-sm-12 col-xs-12 text-center mt-2">
                                <a href={listData && listData[0].pdf_url}>  <button className="btn btn-primary downloadPdf">  {t("download_pdf")}</button>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12 mt-2">
                                <div className='text-center' id="google_translate_element"></div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12 text-center">
                                <Link to="/shopping-cart" className='downloadPdf' disabled={showCartButton && showCartButton.length ? false : true} >
                                    <img src={cartImg} alt="" height={"55px"} />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div id="previewPdf">
                        {pdfHTML()}
                    </div>
                </>
                :
                navigate("/home")
            }
        </>
    )
}

export default EProductView;

