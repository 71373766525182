import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from 'react-bootstrap';
import UserApi from "../../api/userApi";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { Cookies } from "react-cookie";

const ChangePassword = ({ userProfileData = {}, getProfileData = null }) => {
  const { t } = useTranslation();

  let cookies = new Cookies();
  let userData = cookies.get("AllMenuUser") || null;

  const api = new UserApi();
  const [pass, setPass] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPass: ""
  })

  const [cp, setCp] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChanges = (e) => {
    let { name, value } = e.target;
    const d = { ...pass };
    d[name] = value;
    if (name == "newPassword") {
      let regex = /[!@#\$%\^&\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
      const foundSpecialChar = value.match(regex);
      regex = /[0-9]/g;
      const foundNum = value.match(regex);
      regex = /[A-Z]/g;
      const foundUppercase = value.match(regex);
      regex = /[a-z]/g;
      const lowerCase = value.match(regex);
      regex = /[~,\,<, ,]/g;
      const isSpace = value.match(regex);
      if (
        value?.length > 8 &&
        foundUppercase?.length &&
        foundSpecialChar?.length &&
        foundNum?.length &&
        lowerCase?.length &&
        !isSpace?.length
      ) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
    if (name == "confirmPass") {
      if (d.confirmPass != d.newPassword) {
        setCp(true)
      } else {
        setCp(false)
      }
    } else if (d.confirmPass) {
      if (d.confirmPass != d.newPassword) {
        setCp(true)
      } else {
        setCp(false)
      }
    }
    setPass(d)
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const updatePassword = {
      id: userProfileData.id,
      oldPassword: pass.oldPassword,
      newPassword: pass.newPassword
    };
    if (form.checkValidity()) {
      if (userData.user_role !== "ADMIN") {
        api.passwordChangeAfterLogin(updatePassword)
          .then(res => {
            if (res.success) {
              setValidated(false);
              toast.success(t(t("pass_updated_successfully")))
              cleanfunction()
            } else {
              toast.error(t("invalid_password"))
            }
          })
          .catch(error => {
            toast.error(t("error_login_msg"))
          })
      } else {
        api.passwordChangeAdmin(updatePassword)
          .then(res => {
            if (res.success) {
              setValidated(false);
              toast.success(t(t("pass_updated_successfully")))
              cleanfunction()
            } else {
              toast.error(t("invalid_password"))
            }
          })
          .catch(error => {
            toast.error(t("error_login_msg"))
          })

      }
    }
  }

  const cleanfunction = () => {
    setPass({
      oldPassword: "",
      newPassword: "",
      confirmPass: ""
    })
    setValidated(false);
    setCp(false)
  }

  return (

    <Form className="forms-sample mt-3" onSubmit={handleSubmit} noValidate validated={validated}>
      <div className="card mb-3">
        <div className="card-body">
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <h4 className="form-heading">
                {t('update_password_information')}
              </h4>
            </Col>
            <Col md={6} lg={6} sm={12} xs={12}>
              {userData.user_role !== "ADMIN" && (
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="password"
                    name="oldPassword"
                    placeholder={t("old_password")}
                    value={pass.oldPassword}
                    onChange={(e) => handleChanges(e)} />
                  <Form.Control.Feedback type="invalid">{t("old_password_required")}!</Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  type="password"
                  name="newPassword"
                  placeholder={t("new_password")}
                  value={pass.newPassword}
                  isInvalid={isError}
                  onChange={(e) => handleChanges(e)} />
                <Form.Control.Feedback type="invalid">
                  {" "}
                  {pass.newPassword == "" ? (
                    t("New_password_required")
                  ) : (
                    <>
                      {t("register_passLength_error")}
                    </>
                  )}
                </Form.Control.Feedback>

              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  type="password"
                  name="confirmPass"
                  placeholder={t("password_login_confirm")}
                  value={pass.confirmPass}
                  onChange={(e) => handleChanges(e)}
                  isInvalid={cp}
                />
                <Form.Control.Feedback type="invalid">{t("register_ConfpassNotMatch_error")}!</Form.Control.Feedback>

              </Form.Group>
            </Col>
            <Col md={6} lg={6} sm={12} xs={12}>
            </Col>
          </Row>
        </div>
      </div>
      <Row className="mb-3">
        <Col className="text-center">
          <button type="submit" className="btn btn-danger">{t('change_password')}</button>
        </Col>
      </Row>
    </Form>

  )
}

export default ChangePassword;