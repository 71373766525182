import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Form, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PaymentApi from "../../api/paymentApi";
import { useTranslation } from 'react-i18next';
import CustomDataTable from '../../components/customDataTable';
import Helpers from "../../components/helpers";
import { Cookies } from 'react-cookie'
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';

const Payments = () => {
  const { t } = useTranslation();
  let paymentApi = new PaymentApi()
  let cookies = new Cookies()
  let userData = cookies.get('AllMenuUser') || null;

  const [userPaymentData, setUserPaymentData] = useState([])
	const customStyles = {
		headCells: {
			style: {
				backgroundColor: '#f8f9fb',
				fontSize: '0.82rem !important',
				fontWeight: '700',
				innerHeight: "20px",
				height: "38px",
			}
		},
		head: {
			style: {
				height: "38px",
			}
		},
		rows: {
			style: {
				height: "auto !important",
				minHeight: "38px !important",
			}
		},
		cells: {
			style: {
				height: "auto !important",
				minHeight: "38px !important",
				verticalAlign: "middle",
				fontSize: "0.82rem",
				lineHeight: "1.5",
				// whiteSpace: "nowrap",
				fontWeight: "500",
			}
		},
		table: {
			style: {
				paddingBottom: "0",
			}
		}
	};

  useEffect(() => {
    getAllUserPayments()
  }, [])

	// const getAllUserServices = async (searchKeyword = null) => {
	// 	let d = {}
	// 	if (searchKeyword !== "") {
	// 		d.searchKeyword = searchKeyword;
	// 	} else {
	// 		d.searchKeyword = '';
	// 	}
	// 	d.myId = userData.id;
	// 	await serviceApi.getAllUserServices(d).then
	// 		((response) => {
	// 			if (response.data) {
	// 				console.log("response.data",response.data);
	// 				setUserServiceData(response.data);
	// 			}
	// 		}).catch((error) => {
	// 			toast.error(error.toString());
	// 		});
	// }

  const getAllUserPayments = async (searchKeyword = null) => {
    let d = {}
		if (searchKeyword !== "") {
			d.searchKeyword = searchKeyword;
		} else {
			d.searchKeyword = '';
		}
    d.userId = userData.id;
    await paymentApi.getAllUserPayments(d)
    .then((response) => {
      if (response.data) {
        setUserPaymentData(response.data);
      }
    })
    .catch((error) => {
      toast.error(error.toString());
    });
  }

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      getAllUserPayments(key);
    } else {
      // setSearchKey("");
      getAllUserPayments();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
);

  const columns = [
    {
      id: "vendorName",
      name: t("vendor_name"),
      selector: row => row && row.vendor_name,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.vendor_name)}
        >
          <span>
          {row.vendor_name}
          </span>
        </OverlayTrigger>
      ),
      width: "15%",
      sortable:true,
      reorder:true,
      style: {
        cursor: "text"
      },
    },
    {
      id: 'invoiceid',
      name: t("invoice_id"),
      selector: row => row && row.invoice_no,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.invoice_no)}
        >
          <span>
            {row.invoice_no}
          </span>
        </OverlayTrigger>
      ),
      sortable:true,
      width: "15%",
      reorder:true
    },
    {
      id: "invoicetype",
      name: t('payment_table_col_invoice_type'),
      selector: row => row && row.id,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service_id == 1 ? t('mypayment_col_emenu'): t('mypayment_col_eproduct') )}
        >
          <span>
            {row.service_id == 1 ? t('mypayment_col_emenu'): t('mypayment_col_eproduct') }
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
      reorder: true,
      style: {
        cursor: "text"
      },
    },
    {
      id: "Period",
      name: t('payment_table_col_invoice_period'),
      cell: (row) => (
        // <OverlayTrigger
        //   placement="bottom"
        //   delay={{ show: 250, hide: 400 }}
        //   overlay={renderTooltip(row.vendor_name)}
        // >
          <span>
            365 {t('mypayment_col_days')}
          </span>
        // </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
    },
    {
      id: "PayDate",
      name: t('payment_table_col_invoice_paydate'),
      selector: row => row.invoice_date,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(Helpers.convertUtcToTimezone(row.invoice_date, "MM-DD-YYYY hh:mm:a"))}
        >
          <span>{Helpers.convertUtcToTimezone(row.invoice_date, 'MM-DD-YYYY')}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
      style: {
        cursor: "text"
      },
    },
    {
      name: t('payment_table_col_invoice_valid_date'),
      selector: row => row.valid_till,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(Helpers.convertUtcToTimezone(row.valid_till, "MM-DD-YYYY hh:mm:a"))}
        >
          <span>{Helpers.convertUtcToTimezone(row.valid_till, 'MM-DD-YYYY')}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      width: "10%",
      style: {
        cursor: "text"
      },
    },
    {
      name: t('payment_table_col_invoice_payment_value'),
      sortable: true,
      selector: row => row.amount,
      cell: row => (
       <span>€{row.amount}</span> 
      ),
      width: "10%"
    },
		{
			name: t("preview"),
			selector: row => row.preview,
			cell: row => (
				<>
{
						row.menu_id && row.menu_id !== null ?
							<a target="_blank" href={`/menu/${row.vendor_name}-${row.menu_id}`}>
								<i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
							</a>
							:
							<a target="_blank" href={`/product/${row.vendor_name}-${row.list_id}`}>
								<i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
							</a>
					}
				</>
			),
			width: "10%"
		},
    {
      name: t('payment_table_col_invoice_pdf'),
      // selector: row => row.preview,
      cell: row => (
        <> 
          <Link to={`/my-payment/${row.transaction_id}`} target="_blank"
          ><i className="fa fa-file-pdf-o" style={{ fontSize: "24px" }}></i></Link>
        {/* </a> */}
        </>
      ),
      width: "10%"
    }
  ];
  return (
    <>
      <div className="register-page">
        <Container fluid>
          <Row className="d-flex m-0">
            <Col lg={12} className="page-header inner-header-payment pr-0 pl-0">
              <header className="entry-header">
                <h1>{t('my_payments_h1')}</h1>
              </header>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="cont-bg register-page">
        <div className="container">
          {/* heading and content above table  */}
          <Row >
            <Col lg={12}>
              <h2>
                {t('my_payments_h1')}
              </h2>
              <p>{t('my_payments_content')}</p>
            </Col>
          </Row>

          {/* table part */}
          <div className="card mb-3">
            <div className="card-body">
              <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
									<label className="me-3 mb-0 searchSet">
										<input type="search" onChange={SearchData} className="form-control" placeholder={t("search_placeholder")} aria-controls="order-listing" />
									</label>
								</Col>
                <Col md={12} lg={12} sm={12} xs={12} className="mt-3">
                  <DataTable
                    data={userPaymentData}
										pagination={userPaymentData && userPaymentData.length > 30 ? true : false}
                    columns={columns}
                    pointerOnHover={true}
                    highlightOnHover={true}
										customStyles={customStyles}
										noDataComponent = {t(`no_record_msg`)}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payments