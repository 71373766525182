import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthApi from '../../api/authApi';
import CommonApi from '../../api/commonApi';
import { toast } from 'react-toastify';
import { QRCodeCanvas } from "qrcode.react";
import Helpers from "../../components/helpers";
import restDummyLogo from "../../assets/images/restDummyLogo.png";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet-async";
import LanguageContext from "../../context/languageContext"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
const QR_URL = process.env.REACT_APP_QR_URL;


const Allemenu = () => {
  const authApi = new AuthApi()
  const commonApi = new CommonApi();
  const authState = useContext(LanguageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [countriesList, setcountriesList] = useState([])
  const [LanguagesList, setlanguagesList] = useState([])
  const [searchedData, setSearchedData] = useState([])
  const [cityList, setCityList] = useState([])
  const [restaurantsData, setRestaurantsData] = useState([])
  const [loadingCity, setLoadingCity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const selected = localStorage.getItem("i18nextLng");

  // const location = useLocation()
  // let sp = (location.pathname).split("/")
  // let spDefaultRoute = sp[1]
  // let spLanguage = sp[2]
  // let spCountry = sp[3] && sp[3].charAt(0).toUpperCase() + sp[3].slice(1);
  // let spCity = sp[4] && sp[4].charAt(0).toUpperCase() + sp[4].slice(1);
  // let spRestName = sp[5]

  let { language, country, city, restname } = useParams();
  const [spLanguage, setSpLanguage] = useState(language)
  // let spLanguage = language")
  let sp = country && country.charAt(0).toUpperCase() + country.slice(1);
  let spCountry = sp && sp.split("_").join(" ")
  let c = city && city.charAt(0).toUpperCase() + city.slice(1);
  let spCity = c && c.split("_").join(" ")
  let spRestName = restname && restname.split("_").join(" ")

  const [data, setData] = useState({
    country: "",
    countryName: spCountry ? spCountry : "",
    city: "",
    cityName: spCity ? spCity : "",
    searchKeyword: spRestName ? spRestName : null,
    language_code: selected
  });

  useEffect(() => {
    authApi.getAllLanguages().then((response) => {
      if (response) {
        let checkLanguage_code = response && response.data.find((item) => item && item.language_code == spLanguage)
        if (checkLanguage_code) {
          authState.updateLanguage(checkLanguage_code && checkLanguage_code.language_code)
          setSpLanguage(checkLanguage_code && checkLanguage_code.language_code)
        }
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }, [spLanguage])

  useEffect(() => {
    if (data && selected && !data.countryName && !data.cityName && !data.searchKeyword) {
      navigate(`/emenu/${selected}`)
    }
    else if (data && selected && data.countryName && !data.cityName && !data.searchKeyword) {
      navigate(`/emenu/${selected}/${data && data.countryName}`)
    }
    else if (data && selected && data.countryName && data.cityName && !data.searchKeyword) {
      navigate(`/emenu/${selected}/${data && data.countryName}/${data && data.cityName}`)
    } else if (data && selected && data.countryName && data.cityName && data.searchKeyword) {
      navigate(`/emenu/${selected}/${data && data.countryName}/${data && data.cityName}/${data && data.searchKeyword.split(" ").join("_")}`)
    }
    getAllLanguages();
    getAllCountries();
    if (!spLanguage && !spCountry && !spCity && !spRestName) {
      byDefaultCountry();
    }
  }, [selected])

  const getAllCountries = async () => {
    setLoading(true)
    await authApi.getAllCountries().then((response) => {
      setcountriesList(response.data);
    }).catch((error) => {
      setLoading(false)
      toast.error(error.toString());
    });
  }

  const getAllLanguages = async () => {
    await authApi.getAllLanguages().then((response) => {
      if (response) {
        setlanguagesList(response.data);
        getSelectedCountry(response.data)
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getAllEMenu = async (data) => {
    await commonApi.getAllEMenu(data).then((response) => {
      if (response.data) {
        if (response.emptyData === "2") {
          setContent(true)
        }
        else {
          setContent(false)
        }
        setRestaurantsData(response.data);
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    }).catch((error) => {
      setLoading(false)
      toast.error(error.toString());
    });
  }

  const byDefaultCountry = async () => {
    let obj = {}
    setLoading(true)
    await Helpers.getGeoInfo().then(async (response) => {
      if (response && response.countryName) {
        let countryId = ""
        await authApi.getAllCountries().then((r) => {
          if (r) {
            r && r.data && r.data.map(country => {
              if (country.name == response.countryName) {
                countryId = country.id
                let d = { ...data }
                d.country = country.id;
                d.countryName = country.name;
                setData(d)
                obj.country = country.id

                if (selected === "en-GB") {
                  obj.language_code = "en"
                }
                else {
                  obj.language_code = selected
                }
                navigate(`/emenu/${selected}/${country && country.name}`)
              }
            })
            setLoadingCity(true)
            authApi.getAllCities({ country_id: countryId }).then((response) => {
              setCityList(response.data);
              getAllEMenu(obj)
              setLoadingCity(false)
              setLoading(false)
            }).catch((error) => {
              setLoadingCity(false)
              setLoading(false)
              toast.error(error.toString());
            });
          }

        })
      }
      else {
        if (selected === "en-GB") {
          obj.language_code = "en"
        }
        else {
          obj.language_code = selected
        }
        navigate(`/emenu/${selected}`)
        setLoading(false)
        getAllEMenu(obj)
      }
    }).catch((error) => {
      setLoadingCity(false)
      setLoading(false)
      toast.error(error.toString());
    });
  }

  const getSelectedCountry = async (responseData) => {
    let obj = {}
    let countryId = ""
    if (selected) {
      setLoading(true)
      let checkLanguage_code = responseData && responseData.find((item) => item && item.language_code == selected)
      if (checkLanguage_code) {
        let d = { ...data }
        d.language_code = checkLanguage_code && checkLanguage_code.language_code
        setData(d)
        obj.language_code = checkLanguage_code && checkLanguage_code.language_code
        getAllEMenu(obj)
        setLoadingCity(false)
      }
      else {
        let d = { ...data }
        d.language_code = "en";
        setData(d)
        obj.language_code = "en"
        getAllEMenu(obj)
        setLoadingCity(false)
      }
      if (spCountry) {
        setLoading(true)
        setLoadingCity(true)
        let checkCountry;
        await authApi.getAllCountries().then((response) => {
          setcountriesList(response.data);
          checkCountry = response && response.data.find((item) => item.name === spCountry)
        }).catch((error) => {
          toast.error(error.toString());
        });
        if (checkCountry) {
          countryId = checkCountry.id
          let d = { ...data }
          d.country = checkCountry.id;
          d.countryName = checkCountry.name;
          setData(d)
          obj.language_code = checkLanguage_code && checkLanguage_code.language_code;
          obj.country = checkCountry.id
          getAllEMenu(obj)
          setLoading(false)
          authApi.getAllCities({ country_id: countryId }).then((response) => {
            setCityList(response.data);
            setLoadingCity(false)
            if (spCity) {
              setLoading(true)
              let checkCity = response.data.find((item) => item.name === spCity)
              if (checkCity) {
                let d = { ...data }
                d.language_code = checkLanguage_code && checkLanguage_code.language_code;
                d.country = checkCountry.id;
                d.countryName = checkCountry.name;
                d.city = checkCity.id;
                d.cityName = checkCity.name;
                setData(d)
                obj.language_code = checkLanguage_code && checkLanguage_code.language_code;
                obj.country = checkCountry.id;
                obj.city = checkCity.id;
                getAllEMenu(obj)
                setLoading(false)
                setLoadingCity(false)
              }
              else {
                obj = {}
                let d = { ...data }
                d.language_code = "en";
                setData(d)
                obj.language_code = "en"
                getAllEMenu(obj)
              }
            }
            if (spRestName) {
              let d = { ...data }
              d.searchKeyword = spRestName;
              obj.searchKeyword = spRestName;
              setSearchedData(spRestName)
              getAllEMenu(obj)
              setLoadingCity(false)
            }
          }).catch((error) => {
            setLoadingCity(false)
            toast.error(error.toString());
          });
        }
        else {
          setLoadingCity(false)
        }
      }
    }
  }

  const qrcode = (items) => {
    return <QRCodeCanvas
      id="qrCode"
      value={`${QR_URL}/e-menu/${items.restaurantName.split(" ").join("")}-${items.id}`}
      size={256}
      style={{ height: "auto", width: "96px" }}
      level={"H"}
    />
  }

  const handleChanges = (e) => {
    let { name, value } = e.target
    let d = { ...data }
    if (e.target.name === "country") {
      let findCountry = countriesList && countriesList.find((item) => item.id == e.target.value)
      if (findCountry) {
        d.countryName = findCountry.name
        d.cityName = ""
        d.searchKeyword = ""
        setSearchedData("")
        spCountry = findCountry.name
        navigate(`/emenu/${selected}/${findCountry.name}`)
      }
      setLoadingCity(true);
      authApi.getAllCities({ country_id: e.target.value }).then((response) => {
        setCityList(response.data);
        setLoadingCity(false)
      }).catch((error) => {
        setLoadingCity(false)
        toast.error(error.toString());
      });
    }
    if (e.target.name === "city") {
      let findCity = cityList && cityList.find((item) => item.id == e.target.value)
      if (findCity) {
        d.cityName = findCity.name
        d.searchKeyword = ""
        setSearchedData("")
        navigate(`/emenu/${selected}/${spCountry}/${findCity && findCity.name}`)
      }
    }
    if (selected === "en-GB") {
      d.language_code = "en"
    }
    else {
      d.language_code = selected
    }
    d[name] = value;
    setData(d)
    getAllEMenu(d)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const SearchData = (event) => {
    if ((data && data.city !== "" && data.city.length) || spCity) {
      setCityError(false)
      const key = event.target.value;
      if (key !== "") {
        let d = { ...data }
        d.searchKeyword = key;
        setSearchedData(key)
        setData(d)
        getAllEMenu(d)
        navigate(`/emenu/${selected}/${data && data.countryName}/${data && data.cityName}/${key.split(" ").join("_")}`)
      } else {
        navigate(`/emenu/${selected}/${data && data.countryName}/${data && data.cityName}`)
        let d = { ...data }
        d.country = data && data.country;
        d.city = data && data.city;
        d.searchKeyword = "";
        setSearchKey("");
        getAllEMenu(d);
        setSearchedData(key)
      }
    }
    else {
      setCityError(true)
    }
  };

  // const SearchData = (event) => {
  //   if (data && data.city && data.city.length > 0 && data.city != "") {
  //     setCityError(false)
  //     const key = event.target.value;
  //     if (key !== "") {
  //       let d = { ...data }
  //       if (selected === "en-GB") {
  //         d.language_code = "en"
  //       }
  //       else {
  //         d.language_code = selected
  //       }
  //       d.searchKeyword = key;
  //       setSearchedData(key)
  //       setData(d)
  //       getAllEMenu(d)
  //       navigate(`/emenu/${selected}/${data && data.countryName}/${data && data.cityName}/${key.split(" ").join("-")}`)
  //     } else {
  //       setSearchKey("");
  //       setSearchedData(key)
  //       getAllEMenu();
  //       navigate(`/emenu/${selected}/${data && data.countryName}/${data && data.cityName}/${key.split(" ").join("-")}`)
  //     }
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>{`AllMenu` + ` - ${data && data.countryName ? ` ${data && data.countryName}` : ""}` + `${data && data.cityName ? ` ${data && data.cityName}` : ""}` + `${searchedData && searchedData.length ? ` ${searchedData}` : ``}`
        }</title>
        <meta
          name="description"
          content={`AllMenu.eu` + `${data && data.countryName ? ` - emenu - ${data && data.countryName}` : ""}` + `${data && data.cityName ? ` - ${data && data.cityName}` : ""}` + `${searchedData && searchedData.length ? ` - ${searchedData}` : ``}`
          }
        />
        <meta name="Keywords" content={`AllMenu` + `${data && data.countryName ? ` emenu, ${data && data.countryName}` : ""}` + `${data && data.cityName ? `, ${data && data.cityName}` : ""}` + `${searchedData && searchedData.length ? `, ${searchedData}` : ``}`
        } />
      </Helmet>
      <div className="register-page">
        <Container fluid>
          <Row className="d-flex m-0">
            <Col lg={12} className="page-header pr-0 pl-0">
              <header className="entry-header">
                <h1>{`${t("btn_restaurants")}` + ` ${t("home_EMenu_label")}`}</h1>
              </header>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-widgets">
        <div className="container">
          <div className="row filter justify-content-center">
            <div className="mb-2 col-md-6 col-lg-4 col-sm-12 col-xs-12 text-center">
              <div className="form-group mb-0">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="country"
                  id="country"
                  value={data.country}
                  onChange={(e) => handleChanges(e)}
                >
                  <option value="">{t("register_Country_drop")}</option>
                  {
                    countriesList &&
                      countriesList.length ? countriesList.map(item => {
                        return (
                          <option key={`country-${item.id}`} value={item.id}>{item.name}</option>
                        )
                      }) : " "
                  }
                </select>
              </div>
            </div>
            <div className="mb-2 col-md-6 col-lg-4 col-sm-12 col-xs-12 text-center">
              <div className="form-group mb-0">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="city"
                  as="select"
                  id="city"
                  placeholder={t('register_City_drop')}
                  value={data.city}
                  onChange={(e) => handleChanges(e)}
                  required
                >
                  <option value="">{t('register_City_drop')}</option>
                  {
                    cityList && cityList.length ? cityList.map(item => {
                      return (
                        <option key={`city-${item.id}`} value={item.id}>{item.name}</option>
                      )
                    }) : " "
                  }
                </select>
                {loadingCity ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : null}
              </div>
            </div>
            <div className="mb-2 col-md-6 col-lg-4 col-sm-12 col-xs-12 text-center">
              <div className="form-group mb-0">
                <input type="text" onChange={SearchData} className="form-control" id="companyName" aria-describedby="zipcode" placeholder={t("restaurantsSearch")} value={spRestName ? spRestName : null} />
                {cityError ? <p className="mt-1">{t("city_alert")}</p> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cont-bg register-page">
        <Container>
          <div className="row mt-2">
            <div>
              <div className="d-flex mb-3">
                <h5>{`${selected ? `${t("home_EMenu_label")}` : ""}`
                  +
                  `${selected ? ` - ${selected}` : ""}`
                  +
                  `${data && data.countryName ? ` - ${data && data.countryName}` : ""}` +
                  `${data && data.cityName ? ` - ${data && data.cityName}` : ""}` +
                  `${searchedData && searchedData.length ? ` - ${searchedData}` : ""}`}</h5>{content ? <span className=" ml-1"> {`(${t("no_result")})`}</span> : null}
              </div>
            </div>
            {
              restaurantsData.length > 0 ? restaurantsData.map(items => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="row service-ht">
                        <div className="col-2">
                          {
                            items.logo_url ?
                              <img src={items.logo_url} className="restaurantLogo pr-0" />
                              :
                              <img src={restDummyLogo} className="restaurantLogo pr-0" />
                          }
                        </div>
                        <div className="col-8">
                          <h4 className="card-text mb-0  ml-2">
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(items.restaurantName)}
                            >
                              <b>{(items.restaurantName && items.restaurantName.slice(0, 16) + (items.restaurantName.length > 16 ? "..." : ""))}</b>
                            </OverlayTrigger>
                          </h4>
                          <p className="card-text cuisinesName ml-2">({items.cuisineLanguage})</p>
                        </div>
                        <div className="col-2 text-right">
                          <img src={items.cuisineImg} className="cuisineImg" />
                        </div>
                      </div>
                      <hr className="linewithspace" />
                      <div className="row address-ht">
                        <div className="col-8">
                          <table>
                            <tr>
                              <td className="addressIcon"><i className="fa fa-map-marker"></i> </td>
                              <td></td>
                              <td className="address">
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip(items.address)}
                                >
                                  <span>{(items.address && items.address.slice(0, 70) + (items.address.length > 70 ? "..." : ""))}</span>
                                </OverlayTrigger>
                                <br />
                                <>{items.cityName}, {items.countryName}</>
                              </td>
                            </tr>
                            <tr>
                              <td><i className="fa fa-phone"></i></td>
                              <td></td>
                              <td className="address">{items.phone}</td>
                            </tr>
                            <tr>
                              <td><i className="fa fa-globe"></i></td>
                              <td></td>
                              <td className="address">{items.website}</td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-4 text-center pl-0">
                          <div>{qrcode(items)}</div>
                          <p className="emenulink">
                            {t("scantosee")}
                            <br />
                            {t("EMenu")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <a className="btn-secondary emenubtm" href={`/e-menu/${items.restaurantName.split(" ").join("")}-${items.id}`} target="_blank">
                      {t("ViewEMenu")}
                    </a>
                  </div>
                </div>
              )) : <div className="text-center">{t("no_data_found")}</div>}
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
};

export default Allemenu;