import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import UserApi from "../../api/userApi";
import ChangePassword from "./changePassword";
import ProfileDetail from "./profileDetail";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const MyProfile = ({ logoutUser = null }) => {
  let cookies = new Cookies();
  let userData = cookies.get("AllMenuUser") || null;
  const { t } = useTranslation();
  let userApi = new UserApi();
  const [userProfileData, setUserProfileData] = useState({});
  let { userId } = useParams();

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    gender: "",
    mobile: "",
    companyName: "",
    taxNumber: "",
    website: "",
    phone: "",
    country: "",
    city: "",
    otherCity: "",
    zipCode: "",
    street: "",
    houseNumber: "",
    language: "",
  });

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    let uid = "";
    if (userId && userId != null && userId != "") {
      uid = userId;
    } else {
      uid = userData.id;
    }
    await userApi
      .getProfile({ id: uid })
      .then((response) => {
        if (response.data) {
          setUserProfileData(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  return (
    <>
      <div className="register-page">
        {userData.user_role !== "ADMIN" && (
          <Container fluid>
            <Row className="d-flex m-0">
              <Col lg={12} className="page-header inner-header pr-0 pl-0">
                <header className="entry-header">
                  <h1>{t("my_profile")}</h1>
                </header>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <div className="cont-bg register-page">
        <div className="container">
          <ProfileDetail
            logoutUser={logoutUser}
            userProfileData={userProfileData}
            getProfileData={getProfileData}
          />
          <ChangePassword
            userProfileData={userProfileData}
            getProfileData={getProfileData}
          />
        </div>
      </div>
    </>
  );
};

export default MyProfile;
