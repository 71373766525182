import React, { useState, useEffect, useContext } from "react";
import { Form, Container, Row, Col, Spinner } from "react-bootstrap";
import UserApi from "../../api/userApi";
import AuthApi from "../../api/authApi";
import AdminApi from "../../api/adminApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import DeleteModel from "../../components/deleteModel";
import i18next from "i18next";
import LanguageContext from "../../context/languageContext"

const ProfileDetail = ({ logoutUser = null, userProfileData = {} }) => {
  const authState = useContext(LanguageContext);
  // console.log("authState", authState);
  let cookies = new Cookies();
  let userData = cookies.get("AllMenuUser") || null;
  const [cookie, setCookie] = useCookies(["AllMenuUser"]);
  const { t } = useTranslation();
  const [cp, setCp] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countriesList, setcountriesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const navigate = useNavigate();
  let userApi = new UserApi();
  let authApi = new AuthApi();
  let adminApi = new AdminApi();
  const [LanguagesList, setlanguagesList] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [loadingCity, setLoadingCity] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    first_name: "",
    last_name: "",
    gender: "",
    mobile: "",
    company_name: "",
    tax_number: "",
    website: "",
    phone: "",
    country: "",
    city: "",
    zipcode: "",
    street: "",
    house_no: "",
    language_code: "",
    otherCity: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };
  const [selected, setSelected] = useState(localStorage.getItem("i18nextLng") || "en");
	const selected1 = localStorage.getItem("i18nextLng");
	const selectedLanguage = selected1
  useEffect(() => {
    // console.log("in changed language");
    if (data && data.language_code && data.language_code.length !== null) {
      setSelected(data.language_code)

    }
  }, [data]);

  useEffect(() => {
    getAllCountries();
    setSectionData();
    getAllCities();
  }, [userProfileData]);


  useEffect(() => {
    getAllLanguages();
  }, [selectedLanguage]);

  const setSectionData = () => {
    if (userProfileData) {
      getCity();
      setData(userProfileData);
    }
  };
  const DeleteData = async () => {
    await userApi
      .deleteAccount({ accountId: data.id })
      .then((res) => {
        if (res.success) {
          setShow(false);
          logoutUser();
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const getCity = async () => {
    await userApi
      .getCity({ id: userProfileData.city })
      .then((response) => {
        let id = response.data.id;
        // let d = { ...data }
        // setData(d)
        setData((prevState) => ({
          ...prevState,
          city: id,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllLanguages = async () => {
    await authApi
      .getAllLanguages()
      .then((response) => {
        let langData = response.data.findIndex((item) => item.name === "English")
        if (langData > -1) {
          response.data[langData].name = t("english")
        }
        let langData1 = response.data.findIndex((item) => item.name === "Spanish")
        if (langData1 > -1) {
          response.data[langData1].name = t("spanish")
        }
        let langData3 = response.data.findIndex((item) => item.name === "German")
        if (langData3 > -1) {
          response.data[langData3].name = t("german")
        }
        let langData4 = response.data.findIndex((item) => item.name === "Turkish")
        if (langData4 > -1) {
          response.data[langData4].name = t("turkish")
        }
        let langData5 = response.data.findIndex((item) => item.name === "Portuguese")
        if (langData5 > -1) {
          response.data[langData5].name = t("portuguese")
        }
        let langData6 = response.data.findIndex((item) => item.name === "French")
        if (langData6 > -1) {
          response.data[langData6].name = t("french")
        }
        let langData7 = response.data.findIndex((item) => item.name === "Russian")
        if (langData7 > -1) {
          response.data[langData7].name = t("russian")
        }
        let langData8 = response.data.findIndex((item) => item.name === "Polish")
        if (langData8 > -1) {
          response.data[langData8].name = t("polish")
        }
        let langData9 = response.data.findIndex((item) => item.name === "Arabic")
        if (langData9 > -1) {
          response.data[langData9].name = t("arabic")
        }
        setlanguagesList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getAllCountries = async () => {
    await authApi
      .getAllCountries()
      .then((response) => {
        setcountriesList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getAllCities = async () => {
    await authApi
      .getAllCities({ country_id: userProfileData.country })
      .then((response) => {
        setCityList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleChanges = async (e) => {
    let { name, value } = e.target;
    if (e.target.name === "country") {
      setLoadingCity(true);
      await authApi
        .getAllCities({ country_id: e.target.value })
        .then((response) => {
          setCityList(response.data);
          setLoadingCity(false)
        })
        .catch((error) => {
          setLoadingCity(false)
          toast.error(error.toString());
        });
    }
    let d = { ...data };
    d[name] = value;
    if (name == "email") {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const emailPatren = value.match(regex);
      if (emailPatren?.length) {
        setIsEmailError(false);
      } else {
        setIsEmailError(true);
      }
    }
    setData(d);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (
      form.checkValidity() == true &&
      cp == false &&
      isEmailError == false &&
      isError === false
    ) {
      // console.log("userData.user_role", userData.user_role);
      if (userData.user_role !== "ADMIN") {
        // console.log("yyyyyyyyyyyyyyyyyyyyyyyyy");
        userApi
          .editProfile(data)
          .then((res) => {
            if (res.success) {
              if (res.data) {
                // console.log("collie daata", res.data.data);
                setCookie("AllMenuUser", res.data.data, {
                  path: "/",
                  maxAge: 60 * 60 * 100,
                  expires: new Date(Date.now() + 60 * 60 * 100),
                });
              }
              // console.log("res.data.data.language_code", res.data.data.language_code);
              // console.log("r LanguageProvider.updateLanguage", authState.updateLanguage);
              authState.updateLanguage(res.data.data.language_code)
              toast.success(t('updated_successfully'));
              navigate("/my-profile");
            } else {
              // console.log("err", res.error);
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            // console.log("error", error);
            toast.error(t("something_went_wrong"));
          });
        setValidated(true);
      } else {
        adminApi
          .editProfile(data)
          .then((res) => {
            // console.log("res", res);
            if (res.success) {
              toast.success(t('updated_successfully'));
            } else {
              toast.error(t("error_login_msg"));
            }
          })
          .catch((error) => {
            toast.error(t("something_went_wrong"));
          });
        setValidated(false);
      }
    }

  };

  // const UserAnswersContext = createContext();

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        noValidate
        validated={validated}
        className="forms-sample"
      >
        <Row>
          <Col lg={12} className="text-center">
            <h2>{t("change_profile")}</h2>
            <p>{t("update_belowTextPart1")}</p>
          </Col>
        </Row>
        {userData.user_role !== "ADMIN" && (
          <div className="card-tools text-right mb-2">
            <button
              id="createNewEmenu"
              className="btn btn-danger"
              type="button"
              onClick={() => handleShow()}
            >
              {t("delete_account")}
            </button>
          </div>
        )}

        <div className="card mb-3">
          <div className="card-body">
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <h4 className="form-heading">{t("my_information")}</h4>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="e-mail"
                    id="email"
                    aria-describedby="e-mail"
                    required
                    placeholder={t("register_email_label")}
                    value={data.email}
                    name="email"
                    isInvalid={isEmailError}
                    onChange={(e) => handleChanges(e)}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    {data.email == "" ? (
                      t("register_email_error")
                    ) : (
                      <>{t("regiser_email_error_valid")}</>
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="select"
                    aria-label="Default select example"
                    name="gender"
                    id="gender"
                    placeholder={t("register_PlaceHolder_GenderLabel")}
                    value={data.gender}
                    onChange={(e) => handleChanges(e)}
                  >
                    <option value="">
                      {t("register_PlaceHolder_GenderLabel")}
                    </option>
                    <option value="MALE">{t("register_dropdown_male")}</option>
                    <option value="FEMALE">
                      {t("register_dropdown_female")}
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {t("register_gender_error")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="first_name"
                    required
                    placeholder={t("register_placeHolder_firstName")}
                    name="first_name"
                    value={data.first_name}
                    onChange={(e) => handleChanges(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("register_firstName_error")}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="last_name"
                    aria-describedby="last_name"
                    placeholder={t("register_placeHolder_lastName")}
                    name="last_name"
                    value={data.last_name}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <h4 className="form-heading">
                  {t("register_Address_Information")}
                </h4>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    as="select"
                    aria-label="Default select example"
                    name="country"
                    id="country"
                    placeholder={t("register_Country_drop")}
                    value={data.country}
                    onChange={(e) => handleChanges(e)}
                  >
                    <option value="">{t("register_Country_drop")}</option>
                    {countriesList && countriesList.length
                      ? countriesList.map((item) => {
                        return (
                          <option key={`country-${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                      : " "}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {t("register_country_error")}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    aria-label="Default select example"
                    name="city"
                    as="select"
                    id="city"
                    placeholder={t("register_City_drop")}
                    value={data.city}
                    onChange={(e) => handleChanges(e)}
                  >
                    <option value={""}>{t("register_City_drop")}</option>
                    {cityList && cityList.length
                      ? cityList.map((item) => {
                        return (
                          <option key={`city-${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                      : " "}
                  </Form.Control>
                  {loadingCity ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  {/* {console.log("data.city", data.city)} */}
                  <Form.Control
                    type="text"
                    // disabled={data.city && data.city !== 0 && data.city.length !== "" ? true : false}
                    id="otherCity"
                    name="otherCity"
                    aria-describedby="otherCity"
                    placeholder={t("register_Other_city")}
                    value={data.otherCity}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="zipcode"
                    aria-describedby="zipcode"
                    placeholder={t("register_zip_code")}
                    name="zipcode"
                    value={data.zipcode}
                    onChange={(e) => handleChanges(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("register_zip_error")}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="Street"
                    aria-describedby="street"
                    placeholder={t("register_Street_Placeholder")}
                    name="street"
                    value={data.street}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="house_no"
                    aria-describedby="house_no"
                    placeholder={t("register_HouseNumber_Placeholder")}
                    name="house_no"
                    value={data.house_no}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <h4 className="form-heading">
                  {t("register_heading_contInfoLabel")}
                </h4>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    type="text"
                    id="mobile"
                    aria-describedby="mobile"
                    placeholder={t("register_mobile_lable")}
                    name="mobile"
                    value={data.mobile}
                    onChange={(e) => handleChanges(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("register_mobile_error")}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="phone"
                    aria-describedby="phone"
                    placeholder={t("register_phone_label")}
                    name="phone"
                    value={data.phone}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    required
                    aria-label="Default select example"
                    name="language_code"
                    id="language_code"
                    as="select"
                    value={data.language_code}
                    onChange={(e) => handleChanges(e)}
                  >
                    {LanguagesList && LanguagesList.length
                      ? LanguagesList.map((item) => {
                        return (
                          <option
                            key={`language-${item.id}`}
                            value={item.language_code}
                          >{`${item.name} (${item.language_code})`}</option>
                        );
                      })
                      : " "}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Language is required !
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={6} sm={12} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="company_name"
                    aria-describedby="company_name"
                    placeholder={t("register_placeHolder_companyName")}
                    name="company_name"
                    value={data.company_name}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="tax_number"
                    aria-describedby="tax_number"
                    placeholder={t("register_placeHolder_taxName")}
                    name="tax_number"
                    value={data.tax_number}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="website"
                    aria-describedby="website"
                    placeholder={t("register_website_label")}
                    name="website"
                    value={data.website}
                    onChange={(e) => handleChanges(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>

        <Row>
          <Col className="text-center">
            <button type="submit" className="btn btn-danger">
              {t("update_profile")}
            </button>
          </Col>
        </Row>
      </Form>

      {show ? (
        <DeleteModel
          show={show}
          handleClose={handleClose}
          DeleteData={DeleteData}
        ></DeleteModel>
      ) : null}
    </>
  );
};

export default ProfileDetail;
