import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const GeneratePdf = async (name = "allmenu.pdf", html, handlePdfFile) => {
  if (name.includes("Invoice_")) {

    const targetElement = html.querySelector(".card-img-overlay");
    let backgroundImageUrl;
    if (targetElement) {
      // Get the background image URL
      backgroundImageUrl = targetElement.style.backgroundImage;

      // Remove background image from the target element
      targetElement.style.backgroundImage = "none";
    }

    let pdf = new jsPDF("p", "pt", "a4");
    let pWidth = pdf.internal.pageSize.width;
    let srcWidth = html.scrollWidth;
    let margin = 10;
    let scale = (pWidth - margin * 2) / srcWidth;
    pdf.setFont('Arial', 'normal');
    pdf.html(html, {
      x: margin,
      y: margin,
      html2canvas: {
        scale: scale,
        allowTaint: true,
        backgroundColor: "white",
      },
      callback: async function (doc) {
        let pageInfo = doc.internal.getCurrentPageInfo();
        let totalPages = doc.internal.getNumberOfPages();
        if (totalPages > 0 && totalPages > pageInfo.pageNumber) {
          for (let p = totalPages; p > pageInfo.pageNumber; p--) {
            doc.deletePage(p);
          }
        }

        if (targetElement) {
          // Put the background image back
          targetElement.style.backgroundImage = backgroundImageUrl;
        }
        doc.save(name); //downloads the pdf

        if (typeof handlePdfFile === "function") {
          // Get the PDF data as a blob
          const blob = doc.output("blob");
          handlePdfFile(blob, name);
        }
      },
      margin: [20, 0, 20, 0],
    });

  } else {
    var pageWrappers = Array.from(document.getElementsByClassName('page-wrapper'));

    var doc = new jsPDF('p', 'mm');
    
    for (var i = 0; i < pageWrappers.length; i++) {
      var pageWrapper = pageWrappers[i];
      var canvas = await html2canvas(pageWrapper, {
        scale: 2, // Adjust the scale as needed
        backgroundColor: "white",
        proxy: true, // Enable proxy to handle external image URLs
      });
    
      var imgData = canvas.toDataURL('image/jpeg');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var position = 0;
    
      if (i !== 0) {
        doc.addPage();
      }
    
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
    }
    
    doc.save(name);
    if (typeof handlePdfFile === "function") {
      // Get the PDF data as a blob
      const blob = doc.output("blob");
      handlePdfFile(blob, name);
    }
    
  }
};

export default GeneratePdf;
