import { useEffect, useState } from "react";
import LanguageContext from "./languageContext";

const LanguageProvider = (props) => {
    const [language, setLanguage] = useState("en");
    let localData = localStorage.getItem("i18nextLng");
    useEffect(() => {
if (localData){
    if (localData && localData == "en-GB") {
        setLanguage("en");
    }
    else{
        setLanguage(localData);
    }
}
    }, [])

    const updateLanguage = (language_code) => {
        setLanguage(language_code)
    }
    return (
        <LanguageContext.Provider value={{ updateLanguage, language }} >
            {props.children}
        </LanguageContext.Provider>
    )
}

export default LanguageProvider;