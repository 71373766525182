import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import CustomDataTable from '../../components/customDataTable';
import restDummyItemImage from "../../assets/images/no-image.png";
import DataTable from "react-data-table-component";
import {
  useNavigate,
} from 'react-router-dom';

const ShoppingCart = () => {
  const navigate = useNavigate();
  let menuItems = localStorage.getItem('menuItems')
  const [itemsString, setItemsString] = useState([])
  const [totalPrice, setTotalPrice] = useState(0);
  let menuItems2 = JSON.parse(menuItems)

  useEffect(() => {
    let total = 0;
    menuItems && menuItems2.map((item, i) => {
      if (!item.totalQuantity && !item.total && !item.final_prcie) {
        item["totalQuantity"] = 1;
        item["final_prcie"] = parseInt(item.price) * parseInt(item.totalQuantity)
      }
      total += item.final_prcie
    })
    setTotalPrice(total);
    localStorage.setItem('menuItems', JSON.stringify(menuItems2))
    setItemsString(menuItems2)
  }, [])

  const deleteItemsFromCart = (item) => {
    let itemsString = localStorage.getItem('menuItems')
    let arrayItems = JSON.parse(itemsString)
    if (arrayItems && arrayItems.length > 0 && arrayItems !== null && arrayItems !== undefined && arrayItems !== '') {
      let findItemId = arrayItems.find((s) => s.id === item.id)
      if (findItemId) {
        let headId = arrayItems.findIndex((s) => s.id === item.id)
        arrayItems.map((item) => {
          let total = totalPrice - arrayItems[headId].final_prcie
          setTotalPrice(total)
        })
        let data = arrayItems.splice(headId, 1)
        localStorage.setItem('menuItems', JSON.stringify(arrayItems)) //
      }
    };
    let menuItems1 = localStorage.getItem('menuItems')
    let menuItems3 = JSON.parse(menuItems1)
    setItemsString(menuItems3)
  }

  let incNum = (row) => {
    if (menuItems2 && menuItems2.length > 0 && menuItems2 !== null && menuItems2 !== undefined && menuItems2 !== '') {
      let findItemId = menuItems2.find((s) => s.id === row.id)
      if (findItemId) {
        let headId = menuItems2.findIndex((s) => s.id === row.id)
        menuItems2[headId].totalQuantity += 1
        menuItems2[headId].final_prcie = menuItems2[headId].price * menuItems2[headId].totalQuantity
        let total
        menuItems2.map((item) => {
          total = totalPrice + menuItems2[headId].price
        })
        setTotalPrice(total)
        localStorage.setItem('menuItems', JSON.stringify(menuItems2))
      }
    }
    let menuItems1 = localStorage.getItem('menuItems')
    let menuItems3 = JSON.parse(menuItems1)
    setItemsString(menuItems3)
  }

  let decNum = (row) => {
    if (menuItems2 && menuItems2.length > 0 && menuItems2 !== null && menuItems2 !== undefined && menuItems2 !== '') {
      let findItemId = menuItems2.find((s) => s.id === row.id)
      if (findItemId) {
        let headId = menuItems2.findIndex((s) => s.id === row.id)
        if (menuItems2[headId].totalQuantity > 0) {
          menuItems2[headId].totalQuantity -= 1
          menuItems2[headId].final_prcie -= menuItems2[headId].price
          let total
          menuItems2.map((item) => {
            total = totalPrice - menuItems2[headId].price
          })
          setTotalPrice(total)
          localStorage.setItem('menuItems', JSON.stringify(menuItems2))
        }
      }
    }
    let menuItems1 = localStorage.getItem('menuItems')
    let menuItems3 = JSON.parse(menuItems1)
    setItemsString(menuItems3)
  }

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#f8f9fb',
        fontSize: '0.82rem !important',
        fontWeight: '700',
        innerHeight: "20px",
        height: "38px",
      }
    },
    head: {
      style: {
        height: "38px",
      }
    },
    rows: {
      style: {
        height: "auto !important",
        minHeight: "38px !important",
      }
    },
    cells: {
      style: {
        height: "auto !important",
        minHeight: "38px !important",
        verticalAlign: "middle",
        fontSize: "0.82rem",
        lineHeight: "1.5",
        // whiteSpace: "nowrap",
        fontWeight: "500",
      }
    },
    table: {
      style: {
        paddingBottom: "0",
      }
    }
  };
  const columns = [
    {
      name: 'ITEM',
      selector: row => row.image_url,
      cell: (row) => row.image_url && row.image_url !== null ? (
        <>
          <img
            style={{ width: "50px", height: 'auto', margin: '5px 0' }}
            src={row.image_url}
            alt={row.name}
          />
          &nbsp; &nbsp;
          <span> {row.name}</span>
        </>
      ) : (
        <>
          <img
            style={{ width: "50px", height: 'auto', margin: '5px 0' }}
            src={restDummyItemImage}
            alt={row.name}
          />
          &nbsp; &nbsp;
          <span> {row.name}</span>
        </>
      ),
      width: "30%",
    },
    {
      name: 'UNIT PRICE',
      selector: row => row.unitPrice,
      cell: (row) => (
        <span> {`${row.currency_symbol}` + `${row.price}`}</span>
      ),
      width: "20%",
    },
    {
      name: 'QUANTITY',
      selector: row => row.quantity,
      cell: (row) => (
        <>
          <a onClick={() => incNum(row)}><i className="fa fa-plus" style={{ fontSize: "12px" }}></i></a>
          &nbsp;
          <span />{row.totalQuantity}
          <span />
          &nbsp;&nbsp;
          <a onClick={() => decNum(row)}><i className="fa fa-minus" style={{ fontSize: "12px" }}></i></a>
        </>
      ),
      width: "20%",
    },
    {
      name: 'FINAL PRICE',
      selector: row => row.final_prcie,
      cell: (row) => (
        <span> {row.final_prcie}</span>
      ),
      width: "20%",
    },
    {
      name: "REMOVE",
      cell: row => (
        <>
          <a onClick={() => deleteItemsFromCart(row)}><i className="fa fa-remove" style={{ fontSize: "15px" }}></i></a>
        </>
      ),
      width: "10%",
    }
  ]

  return (
    <>
      <div className="cont-bg shopping-cart-page pt-1">
        <Container>
          <div className="row">
            <h3>Items In My Cart</h3>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <Row>
                <Col md={12} lg={12} sm={12} xs={12} className="mt-3">
                  {
                    itemsString ?
                      <DataTable
                        data={itemsString}
                        columns={columns}
                        pointerOnHover={true}
                        highlightOnHover={true}
                        customStyles={customStyles}
                      />
                      : null
                  }
                </Col>
              </Row>
            </div>
            <div className="card-footer bg-white">
              <div className="row">
                <div className="col-8 text-right">
                  <b>Total Price:</b>
                </div>
                <div className="col-4">
                  <b>{totalPrice}</b>
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => navigate(-1)} className='btn btn-primary primaryButton btn-icon-text'><i className="fa fa-arrow-left" aria-hidden="true"></i>Continue Shopping</button>
        </Container>
      </div>
    </>
  );
};

export default ShoppingCart;