import React from "react";
import {
  Col,
  Container,
  Row,
  Tab,
  Nav,
} from "react-bootstrap";
import EMenu from "./eMenu";
import EProduct from "./eProduct";

const ManageCuisinesCategories = () => {
 
  return (
    <div id="eMenuGrid" className="cont-bg register-page">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Manage Cuisines &amp; Categories</h2>
            <p>Admin can add/ edit Cuisines and Categories.</p>
          </Col>
        </Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={12}>
              {/* <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Manage Cuisines</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Manage Categories</Nav.Link>
                </Nav.Item>
              </Nav> */}
              <div className="mb-2 mr-2 text-right">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first" variant="success">Manage Cuisines</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" variant="success">Manage Categories</Nav.Link>
                </Nav.Item>
              </Nav> 
                {/* <Link variant="success" className="mr-2" eventKey="first">
                  Manage Cuisines
                </Link>
                <Link variant="success" className="mr-2" eventKey="second">
                  Manage Categories
                </Link> */}
              </div>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                <EMenu />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                 <EProduct />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default ManageCuisinesCategories;
