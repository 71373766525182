import React, { useState, useContext, useEffect } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import headerImg from "../../assets/images/header.png"
import AuthApi from '../../api/authApi';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next"
import LanguageContext from "../../context/languageContext"

const ForgotPassword = () => {
  const { t } = useTranslation();
  let authApi = new AuthApi()
  let history = new useNavigate();
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    email: ""
  })
  const authState = useContext(LanguageContext);
  const location = useLocation()
  let sp = (location.pathname).split("/")
  let lang = sp[1]
  console.log("lang", lang);
  const getLanguage = (async () => {
    await authApi.getAllLanguages().then((response) => {
      if (lang) {
        let checkLanguage_code = response && response.data && response.data.find((item) => item && item.language_code == lang)
        if (checkLanguage_code) {
          authState.updateLanguage(checkLanguage_code.language_code)
        }
        else {
          history("/forgot-password")
        }
      }
    })
  })

  useEffect(() => {
    getLanguage()
  }, []);

  const handleChanges = async (e) => {
    let { name, value } = e.target;
    let d = { ...data }
    d[name] = value;
    setData(d);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() == true) {
      authApi.forgotPassword(data)
        .then((res) => {
          if (res.success) {
            toast.success(t("otp_email"));
            history(`/change-password`, {
              state: {
                emailaddress: data.email
              }
            })
          } else {
            toast.error(t("email_not_exist"));
          }
        }).catch((error) => {
          toast.error(error);
        })
    }
    setValidated(true);
  };

  return (
    <>
      <div className="register-page">
        <Container fluid>
          <Row d-flex m-0>
            <Col lg={12} pr-0 pl-0 className="page-header ">
              <header className="entry-header">
                <h1>{t("forgot_password")}</h1>
              </header>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cont-bg register-page">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2>
                {t("forgot_password")}? 🔒
              </h2>
              <p>{t("forget_password_text")}</p>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={handleSubmit} className="pt-3">
            <div className="justify-content-center d-flex login">
              <div className="card mb-3">
                <div className="card-body">
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <h4 className="form-heading">
                        {t("Enter_your_mail")}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <Form.Group>
                        <Form.Control
                          required type="email" id='EmailinPut' placeholder={t('register_email_label')} name="email" value={data.email} onChange={(e) => handleChanges(e)} />
                        <Form.Control.Feedback type="invalid">
                          {t("Please_provide_valid_email")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} className="justify-content-center d-flex">
                <button type="submit" className="btn btn-danger">{t("SEND_OTP")}</button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}

export default ForgotPassword;