import BaseApi from "./baseApi";

class EMenuApi extends BaseApi { 
  async saveUserRestaurant(data) {
    return await this.API({
      url: "/emenu/saveUserRestaurant",
      data: data,
      method: "post"
    })
  }

  async getCuisineBackground(data) {
    return await this.API({
      url: "/emenu/getCuisineBackground",
      method: "post",
      data: data
    })
  }

  async getUserRestarants(id) {
    return await this.API({
      url: "/emenu/getUserRestarants",
      method: "post",
      data: id
    })    
  }

  async saveRestBgTempId(data) {
    return await this.API({
      url: "/emenu/saveRestBgTempId",
      method: "post",
      data: data
    })
  }

  async saveRestaurantItem(data) {
    return await this.API({
      url: "/emenu/saveRestaurantItem",
      data: data,
      method: "post"
    })
  }

  async getItem(data) {
    return await this.API({
      url: "/emenu/getItem",
      data: data,
      method: 'post'
    })
  }

  async deleteItemFromMenu(data) {
    return this.API({
      url: '/emenu/deleteItemFromMenu',
      data: data,
      method: 'post'
    })
  }

  async getMenuPreview(data) {
    return this.API({
      url: '/emenu/getMenuPreview',
      data: data,
      method: 'post'
    })
  }

  async getServicePrice(data) {
    return this.API({
      url: '/emenu/getServicePrice',
      method: 'post',
      data: data
    })
  }

  async savePaymentData(data) {
    return this.API({
      url: '/emenu/savePaymentData',
      data: data,
      method: 'post'
    })
  }

  async getMenuItem(data) {
    return this.API({
      url: '/emenu/getMenuItem',
      data: data,
      method: 'post'
    })
  }

  async getServiceList(data) {
    return this.API({
      url: '/emenu/getServiceList',
      data: data,
      method: 'post'
    })
  }

  async getRestInfo(data) {
    return this.API({
      url: '/emenu/getRestInfo',
      data: data,
      method: 'post'
    })
  }

  async changeOrder(data) {
    return this.API({
      url: '/emenu/changeOrder',
      data: data,
      method: 'post'
    })
  }

  async savePdf(data) {
    return this.API({
      url: '/emenu/savePdf',
      data: data,
      method: 'post'
    })
  }
  
}

export default EMenuApi;