import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import menuReducer from "./reducer/menuReducer";
import menuApi from "./middleware/menuPreviewMiddleware";

import productReducer from "./reducer/productReducer";
import productApi from "./middleware/productPreviewMiddleware";

let reducer = {
    menuReducer: menuReducer,
    productReducer:productReducer
}
export default function store() {
    return configureStore({
        reducer,
        middleware: [...getDefaultMiddleware(), menuApi,productApi],
    });
}