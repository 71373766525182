import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import {
	Form,
	Container,
	Row,
	Col,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import UserApi from "../../api/userApi";
import { useTranslation } from "react-i18next";
import CustomDataTable from "../../components/customDataTable";
import DeleteModel from "../../components/deleteModel";
import Helpers from "../../components/helpers";
import { Cookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import PayPal from "../../components/payPal";
import EMenuApi from '../../api/eMenuApi'
import ServiceApi from '../../api/serviceApi'
import PaymentApi from '../../api/paymentApi'
import moment from 'moment';
import { vat } from "../../config";
import DataTable from "react-data-table-component";

const Services = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [showPaypal, setShowPaypal] = useState(false);
	const [userServiceData, setUserServiceData] = useState([])
	const [serviceId, setServiceId] = useState([]) //ServiceId
	const [searchKey, setSearchKey] = useState(""); //for searching
	const [eMenuPrice, setEMenuPrice] = useState('');
	const [userEu, setUserEu] = useState(false);
	const [serviceFor, setServiceFor] = useState('');
	const handleClose = () => setShow(false);
	const handleClose1 = () => setShowPaypal(false);
	const handleShow = (myserviceId) => {
		setServiceId(myserviceId)
		setShow(true);
	}
	let userApi = new UserApi()
	const eMenuApi = new EMenuApi();
	const paymentApi = new PaymentApi();
	const serviceApi = new ServiceApi();
	let cookies = new Cookies()

	const selected = localStorage.getItem("i18nextLng");
	const selectedLanguage = selected

	let userData = cookies.get('AllMenuUser') || null;
	const customStyles = {
		headCells: {
			style: {
				backgroundColor: '#f8f9fb',
				fontSize: '0.82rem !important',
				fontWeight: '700',
				innerHeight: "20px",
				height: "38px",
			}
		},
		head: {
			style: {
				height: "38px",
			}
		},
		rows: {
			style: {
				height: "auto !important",
				minHeight: "38px !important",
			}
		},
		cells: {
			style: {
				height: "auto !important",
				minHeight: "38px !important",
				verticalAlign: "middle",
				fontSize: "0.82rem",
				lineHeight: "1.5",
				// whiteSpace: "nowrap",
				fontWeight: "500",
			}
		},
		table: {
			style: {
				paddingBottom: "0",
			}
		}
	};

	useEffect(() => {
		getAllUserServices()
		getServicePrice(userData)
	}, [])

	const getServicePrice = async (data) => {
		await eMenuApi.getServicePrice({ userId: data.id })
			.then(res => {
				if (res.success) {
					// setEMenuPrice(res.data.price)
					if (res.isUserFromEurope) {
						setUserEu(true)
						let senObj = {
							price: res.data.price,
							isVAT: res.isUserFromEurope
						}
						let VAT = Helpers.calculateVAT(senObj)
						setEMenuPrice(VAT)
					} else {
						setEMenuPrice(res.data.price)
					}
				} else {
					toast.error(res.msg)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleShowPaypal = (data) => {
		setServiceId(data.id)
		setShowPaypal(true)
		if (data.type == 'EMENU') {
			setServiceFor('E-Menu')
		} else {
			setServiceFor('E-Product')
		}
	}

	const getAllUserServices = async (searchKeyword = null) => {
		let d = {}
		if (searchKeyword !== "") {
			d.searchKeyword = searchKeyword;
		} else {
			d.searchKeyword = '';
		}
		d.myId = userData.id;
		await serviceApi.getAllUserServices(d).then
			((response) => {
				if (response.data) {
					// console.log("response.data",response.data);
					setUserServiceData(response.data);
				}
			}).catch((error) => {
				toast.error(error.toString());
			});
	}

	const SearchData = (event) => {
		const key = event.target.value;
		if (key !== "") {
			getAllUserServices(key);
		} else {
			setSearchKey("");
			getAllUserServices();
		}
	};
	const DeleteData = () => {
		serviceApi.deleteService({ serviceId: serviceId, user_id: userData.id })
			.then(res => {
				if (res.success) {
					setShow(false)
					toast.success(t("service_delete"));
					getAllUserServices()
				} else {
					toast.error(t("something_went_wrong."))
				}
			})
			.catch(error => {
				toast.error(t("something_went_wrong."))
			})
	}
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip">{props}</Tooltip>
	);
	const changeStatus = (id, value, vendor_name, valid_till) => {
		if (valid_till !== null) {
			serviceApi.changeStatus({ id: id, value: value, vendor_name: vendor_name })
				.then(res => {
					if (value === "Inactive") {
						toast.success(`Your ${vendor_name} has been temporary stopped, the QR code and Link will not be active till you activate your ${vendor_name}  again.`)
					}
					else {
						toast.success(`Your  ${vendor_name} has been activated`)
					}
					getAllUserServices()
				})
				.catch(error => {
					toast.error(error)
				})
		}
		else {
			toast.success(t("activate_status_alert"))
		}
	}
	const createOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: eMenuPrice,
					},
				},
			],
		});
	};
	const onApprove = (data1, actions) => {
		return actions.order.capture().then(function (res) {
			// save transaction data in db
			let invoice_no = moment(new Date).format('DD/MM/YYYY')
			invoice_no = invoice_no + '/ALLM'
			invoice_no = invoice_no + (userEu ? '/UE' : '/OE')
			let full_name = userData.first_name + (userData.last_name ? ' ' + userData.last_name : '')
			if (res.status == 'COMPLETED') {
				let saveObj = {
					user_service_id: serviceId,
					user_name: full_name,
					invoice_no: invoice_no,
					transaction_id: res.purchase_units[0].payments.captures[0].id,
					invoice_date: moment().format('YYYY-MM-DD HH:mm:ss '),
					amount: eMenuPrice,
					vat: vat.VAT,
					language_code: userData.language_code,
					invoice_status: 'PAID',
				}
				savePaymentData(saveObj)
			}
		});
	}
	const onError = (err) => {
		toast.error(t('error_in_payment.'))
	}

	const savePaymentData = async (data) => {
		await paymentApi.savePaymentData(data)
			.then(res => {
				if (res.success) {
					toast.success(t('create_emenu_payment_done'))
					if (data.transaction_id) {
						navigate('/my-payment', {
							state: data
						});
					}
				} else {
					toast.error(res.msg)
				}
			})
			.catch((error) => {
				// console.log(error)
			})
	}
	const columns = [
		{
			id: 'service_type',
			name: t("service_type"),
			selector: row => row && row.type,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.type)}
				>
					<span>
						{row.type}
					</span>
				</OverlayTrigger>
			),
			sortable: true,
			width: "10%",
			reorder: true,
			style: {
				cursor: "text"
			},
		},
		{
			id: "restaurant",
			name: t("restaurant_supplier"),
			selector: row => row.vendor_name,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.vendor_name)}
				>
					<span>
						{row.vendor_name}
					</span>
				</OverlayTrigger>
			),
			sortable: true,
			width: "15%",
			style: {
				cursor: "text"
			},
		},
		{
			id: "City",
			name: t("register_City_drop"),
			selector: row => row.city_name,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.city_name)}
				>
					<span>
						{row.city_name}
					</span>
				</OverlayTrigger>
			),
			sortable: true,
			width: "10%",
			style: {
				cursor: "text"
			},
		},
		{
			id: "Country",
			name: t("register_Country_drop"),
			selector: row => row.country_name,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.country_name)}
				>
					<span>
						{row.country_name}
					</span>
				</OverlayTrigger>
			),
			sortable: true,
			width: "10%",
			style: {
				cursor: "text"
			},
		},
		{
			id: "create",
			name: t("created_at"),
			selector: row => row.createdAt,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a"))}
				>
					<span>{Helpers.convertUtcToTimezone(row.createdAt, 'MM-DD-YYYY')}</span>
				</OverlayTrigger>
			),
			reorder: true,
			sortable: true,
			width: "15%",
			style: {
				cursor: "text"
			},
		},
		{
			name: t("payment_table_col_invoice_valid_date"),
			selector: row => row.valid_till,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(Helpers.convertUtcToTimezone(row.valid_till, "MM-DD-YYYY hh:mm:a"))}
				>
					<span> {row.valid_till && row.valid_till !== null ? Helpers.convertUtcToTimezone(row.valid_till, 'MM-DD-YYYY') : "---"}</span>
				</OverlayTrigger>
			),
			reorder: true,
			width: "10%",
			style: {
				cursor: "text"
			},
		},
		{
			name: t("paypal_model_title"),
			cell: row => (
				<>
					{row.valid_till && row.valid_till !== null ? (<span onClick={() => handleShowPaypal(row)}>{t("paypal_model_Extend")}</span>) : '--'}
				</>
			),
			width: "10%",
			reorder: true,
		},
		{
			id: "create",
			name: "Activated By Admin",
			selector: row => row.active_date,
			cell: (row) => (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip(row.active_date && row.service_active === "Active" ? Helpers.convertUtcToTimezone(row.active_date, "MM-DD-YYYY hh:mm:a") : null)}
				>
					<span>{row.service_active === "Active" && row.active_date ? Helpers.convertUtcToTimezone(row.active_date, 'MM-DD-YYYY') : "---"}</span>
				</OverlayTrigger>
			),
			reorder: true,
			sortable: true,
			width: "150px",
			style: {
				cursor: "text"
			},
		},
		{
			id: 'status',
			name: t("status"),
			selector: row => row.status.charAt(0).toUpperCase() + row.status.slice(1),
			cell: row => (
				<Form.Select size="sm" value={row.status} onChange={e => changeStatus(row.id, e.target.value, row.vendor_name, row.valid_till)}>
					<option value="Active">{t("active")}</option>
					<option value="Inactive">{t("inactive")}</option>
				</Form.Select>
			),
			reorder: true,
			sortable: true,
			width: "15%"
		},
		{
			name: t("preview"),
			selector: row => row.preview,
			cell: row => (
				<>
					{
						row.menu_id && row.menu_id !== null ?
							<a target="_blank" href={`/menu/${row.vendor_name}-${row.menu_id}`}>
								<i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
							</a>
							:
							<a target="_blank" href={`/product/${row.vendor_name}-${row.list_id}`}>
								<i className="fa fa-eye" style={{ fontSize: "24px" }}> </i>
							</a>
					}
				</>
			),
			width: "10%"
		},
		{
			id: 'action',
			name: t("create_emenu_item_grid_column_action"),
			button: true,
			cell: row => (
				<>
					<div className="d-flex">
						<Link to=
							{row.service_id == "1" ? `/modify-emenu?serviceId=${row.id}` :
								`/modify-eproduct?serviceId=${row.id}`}> <span>
								<i className="fa fa-pencil-square" ></i>
							</span> </Link>

						<span onClick={() => handleShow(row && row.id)}>
							<i className="fa fa-times-circle"></i></span>
					</div>
				</>
			),
			width: "10%"
		},
	];
	return (
		<>
			<div className="register-page">
				<Container fluid>
					<Row className="d-flex m-0">
						<Col lg={12} className="page-header inner-header pr-0 pl-0">
							<header className="entry-header">
								<h1>{t('my_services')}</h1>
							</header>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="cont-bg register-page">
				<div className="container">
					<Row >
						<Col lg={12}>
							<h2>
								{t('my_services')}
							</h2>
							<p>{t('serviece_content')}</p>
						</Col>
					</Row>
					<div className="card-tools mb-2 mr-2 text-right">
						<Link to='/modify-emenu'> <span>
							<button id="createNewEmenu" className="btn btn-success mr-2 mb-2">{t("create_e-menu")} <i className="fa fa-plus fa-fw"></i></button>
						</span> </Link>
						<Link to='/modify-eproduct'> <span>
							<button id="createNewEmenu" className="btn btn-success mr-2 mb-2">{t("create_service")} <i className="fa fa-plus fa-fw"></i></button>
						</span> </Link>
						{/* 
                        <a href=""><button id="createNewEmenu" className="btn btn-success mr-2">{t("create_service")} <i className="fa fa-plus fa-fw"></i></button></a> */}
					</div>
					<div className="card mb-3">
						<div className="card-body">
							<Row>
								<Col md={12} lg={12} sm={12} xs={12}>
									<label className="me-3 mb-0 searchSet">
										<input type="search" onChange={SearchData} className="form-control" placeholder={t("search_placeholder")} aria-controls="order-listing" />
									</label>
								</Col>
								<Col md={12} lg={12} sm={12} xs={12} className="mt-3">
									<DataTable
										data={userServiceData}
										pagination={userServiceData && userServiceData.length > 30 ? true : false}
										columns={columns}
										pointerOnHover={true}
										highlightOnHover={true}
										customStyles={customStyles}
										noDataComponent={t(`no_record_msg`)}
									/>
								</Col>
							</Row>
							{show ? <DeleteModel show={show} handleClose={handleClose} DeleteData={DeleteData}></DeleteModel> : null}
							{showPaypal ? <PayPal showPaypal={showPaypal} handleClose={handleClose1} createOrder={createOrder} onApprove={onApprove} onError={onError} otherData={{ eMenuPrice: eMenuPrice, serviceFor: serviceFor }}  ></PayPal> : null}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Services
