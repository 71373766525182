import React from 'react'
import { Button, Modal } from "react-bootstrap";
function AlertModal(props) {
  let { show, handleClose, message, actionFunction} = props
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{color: 'red'}}closeButton>
          <Modal.Title >ALERT!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>message</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={actionFunction}>
            OKAY
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AlertModal