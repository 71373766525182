import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locals/en/translationEn.json";
import translationDe from "./assets/locals/de/translationDe.json";
import translationEs from "./assets/locals/es/translationEs.json";
import translationTr from "./assets/locals/tr/translationTr.json";
import translationFR from "./assets/locals/fr/translationFr.json";
import translationRU from "./assets/locals/ru/translationRu.json";
import translationPt from "./assets/locals/pt/translationPt.json";
import translationAr from "./assets/locals/ar/translationAr.json";
import translationPl from "./assets/locals/pl/translationPl.json";
const fallbackLng = ["en"];
const availableLanguages = ["en","es","de","tr","pt","ar","fr","ru","pl"];

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDe
  },
  es: {
    translation: translationEs
  },
  tr: {
    translation: translationTr
  },
  fr: {
    translation: translationFR
  },
  ru: {
    translation: translationRU
  },
  pt: {
    translation: translationPt
  },
  ar: {
    translation: translationAr
  },
  pl: {
    translation: translationPl
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
