import React, { useState, useEffect, useContext } from 'react'
import { Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import AuthApi from '../../api/authApi';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom";
import { Cookies, useCookies } from 'react-cookie'
import Loader from "../../components/loader";
import LanguageContext from "../../context/languageContext"

const Registration = () => {
	let cookies = new Cookies()
	let userData = cookies.get('AllMenuUser') || null;
	const [cookie, setCookie] = useCookies(['AllMenuUser']);
	const { t } = useTranslation();
	const [cp, setCp] = useState(false);
	const [isEmailError, setIsEmailError] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isWebsiteError, setIsWebsiteError] = useState(false);
	const [isCompanyError, setIsCompanyError] = useState(false);
	const [LanguagesList, setlanguagesList] = useState([])
	const [countriesList, setcountriesList] = useState([])
	const [cityList, setCityList] = useState([])
	const [loading, setLoading] = useState(false);
	const [loadingCity, setLoadingCity] = useState(false);
	const [mailError, setMailError] = useState(false);
	const navigate = useNavigate();
	let authApi = new AuthApi()
	const selected = localStorage.getItem("i18nextLng");
	const selectedLanguage = selected
	const authState = useContext(LanguageContext);
	const [data, setData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		first_name: "",
		last_name: "",
		gender: '',
		mobile: "",
		company_name: "",
		tax_number: "",
		website: "",
		phone: "",
		country: "",
		city: "",
		otherCity: "",
		zipcode: "",
		street: "",
		house_no: "",
		language_code: "en"
	});
	const location = useLocation()
	let sp = (location.pathname).split("/")
	let lang = sp[1]
	const getLanguage = (async () => {
		await authApi.getAllLanguages().then((response) => {
			if (lang) {
				let checkLanguage_code = response && response.data && response.data.find((item) => item && item.language_code == lang)
				if (checkLanguage_code) {
					authState.updateLanguage(checkLanguage_code.language_code)
				}
				else {
					navigate("/register")
				}
			}
		})
	})

	useEffect(() => {
		getLanguage()
		getAllCountries();
	}, []);

	useEffect(() => {
		getAllLanguages();
	}, [selectedLanguage]);

	const getAllCountries = async () => {
		await authApi.getAllCountries().then((response) => {
			setcountriesList(response.data);
		}).catch((error) => {
			toast.error(error.toString());
		});
	}

	const getAllLanguages = async () => {
		await authApi.getAllLanguages().then((response) => {
			let langData = response && response.data.findIndex((item) => item.name === "English")
			if (langData > -1) {
				response.data[langData].name = t("english")
			}
			let langData1 = response && response.data.findIndex((item) => item.name === "Spanish")
			if (langData1 > -1) {
				response.data[langData1].name = t("spanish")
			}
			let langData3 = response && response.data.findIndex((item) => item.name === "German")
			if (langData3 > -1) {
				response.data[langData3].name = t("german")
			}
			let langData4 = response && response.data.findIndex((item) => item.name === "Turkish")
			if (langData4 > -1) {
				response.data[langData4].name = t("turkish")
			}
			let langData5 = response && response.data.findIndex((item) => item.name === "Portuguese")
			if (langData5 > -1) {
				response.data[langData5].name = t("portuguese")
			}
			let langData6 = response && response.data.findIndex((item) => item.name === "French")
			if (langData6 > -1) {
				response.data[langData6].name = t("french")
			}
			let langData7 = response && response.data.findIndex((item) => item.name === "Russian")
			if (langData7 > -1) {
				response.data[langData7].name = t("russian")
			}
			let langData8 = response && response.data.findIndex((item) => item.name === "Polish")
			if (langData8 > -1) {
				response.data[langData8].name = t("polish")
			}
			let langData9 = response && response.data.findIndex((item) => item.name === "Arabic")
			if (langData9 > -1) {
				response.data[langData9].name = t("arabic")
			}
			setlanguagesList(response.data);
		}).catch((error) => {
			toast.error(error.toString());
		});
	}

	const searchMail = (e) => {
		let event = e.target.value
		if (event) {
			authApi.searchMail({ email: event }).then(async (response) => {
				if (response.success) {
					setMailError(true)
				}
				else {
					setMailError(false)
				}
			}).catch((error) => {
				setMailError(false)
				toast.error(error.toString());
			});
		}
	}

	const handleChanges = (e) => {
		let { name, value } = e.target
		if (e.target.name === "country") {
			setLoadingCity(true);
			authApi.getAllCities({ country_id: e.target.value }).then((response) => {
				setCityList(response.data);
				setLoadingCity(false)
			}).catch((error) => {
				setLoadingCity(false)
				toast.error(error.toString());
			});
			authApi.getLanguage({ country_id: e.target.value }).then((response) => {
				let d = { ...data }
				d.language_code = response.data.language_code;
				d.country = e.target.value;
				setData(d)
			}).catch((error) => {
				console.log("country error", error);
			});
		}
		let d = { ...data }
		d[name] = value;
		if (name == "password") {
			let regex = /[!@#\$%\^&\*,_ -. +, =,!, %, *, &, ”, :, .,]/g;
			const foundSpecialChar = value.match(regex);
			regex = /[0-9]/g;
			const foundNum = value.match(regex);
			regex = /[A-Z]/g;
			const foundUppercase = value.match(regex);
			regex = /[a-z]/g;
			const lowerCase = value.match(regex);
			regex = /[~,\,<, ,]/g;
			const isSpace = value.match(regex);
			if (
				value?.length > 8 &&
				foundUppercase?.length &&
				foundSpecialChar?.length &&
				foundNum?.length &&
				lowerCase?.length &&
				!isSpace?.length
			) {
				setIsError(false);
			} else {
				setIsError(true);
			}
		}
		if (name == "confirmPassword") {
			if (d.confirmPassword != d.password) {
				setCp(true)
			} else {
				setCp(false)
			}
		} else if (d.confirmPassword) {
			if (d.confirmPassword != d.password) {
				setCp(true)
			} else {
				setCp(false)
			}
		}
		if (name == "email") {
			const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			const emailPatren = value.match(regex);
			if (
				emailPatren?.length
			) {
				setIsEmailError(false);
			} else {
				setIsEmailError(true);
			}
			setMailError(false)
		}
		if (name == 'website') {
			const regex = /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/i;
			const websitPattern = value.match(regex)
			if (websitPattern?.length) {
				setIsWebsiteError(false)
			} else {
				setIsWebsiteError(true)
			}

		}
		if (name == 'company_name') {
			if (value.length <= 50) {
				setIsCompanyError(false)
			} else {
				setIsCompanyError(true)
			}
		}
		setData(d)
	}

	const handleSubmit = (e) => {
		const form = e.currentTarget;
		e.preventDefault();
		e.stopPropagation();
		if (form.checkValidity() == true && cp == false && isEmailError == false && isError === false && mailError == false) {
			authApi.signup(data)
				.then((res) => {
					if (res.success) {
						toast.success(t('success_login_msg'));
						navigate('/login');
					} else {
						toast.error(t("error_login_msg"));
					}
				})
				.catch((error) => {
					toast.error(t("error_login_msg"));
				});
		}
		setValidated(true);
	}
	return (
		<>
			<div className="register-page">
				<Container fluid>
					<Row className="d-flex m-0">
						<Col lg={12} className="page-header register_image pr-0 pl-0">
							<header className="entry-header">
								<h1>{t('register_heading')}</h1>
							</header>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="cont-bg register-page">
				<div className="container">
					<Form
						onSubmit={handleSubmit}
						noValidate
						validated={validated}
						className="forms-sample">
						<Row >
							<Col lg={12} className="text-center">
								<h2>
									{t('register_heading_reg_info_label')}
								</h2>
								<p>{t('register_belowTextPart1')}<br /> {t('register_belowTextPart2')}</p>

							</Col>
						</Row>
						<div className="card mb-3">
							<div className="card-body">
								<Row>
									<Col md={12} lg={12} sm={12} xs={12}>
										<h4 className="form-heading">
											{t('register_heading_reg_info_label')}
										</h4>
									</Col>
									<Col md={6} lg={6} sm={12} xs={12}>
										<Form.Group className="mb-3">
											<Form.Control type="e-mail" id="email" aria-describedby="e-mail"
												required
												placeholder={t('register_email_label')}
												value={data.email}
												name="email"
												isInvalid={isEmailError ? isEmailError : mailError}
												onChange={(e) => handleChanges(e)}
												onFocus={(e) => { searchMail(e) }}
												onBlur={(e) => searchMail(e)}
											/>										<Form.Control.Feedback type="invalid">
												{" "}
												{data.email == "" ? (
													t('register_email_error')
												) : isEmailError ? (
													t('regiser_email_error_valid')
												) : (
													mailError ? ("Email already Exist.")
														: null
												)}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												required
												type="password"
												id="password"
												aria-describedby="password" placeholder={t('password_login')}
												name="password"
												isInvalid={isError}
												value={data.password}
												onChange={(e) => handleChanges(e)} />
											<Form.Control.Feedback type="invalid">
												{" "}
												{data.password == "" ? (
													t('register_passReqd_error')
												) : (
													<>
														{t('register_passLength_error')}
													</>
												)}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="password"
												required
												id="confirmPassword" aria-describedby="confirmPassword" placeholder={t('password_login_confirm')}
												name="confirmPassword"
												isInvalid={cp}
												value={data.confirmPassword}
												onChange={(e) => handleChanges(e)} />
											<Form.Control.Feedback type="invalid">{t('register_ConfpassNotMatch_error')}</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6} lg={6} sm={12} xs={12}>
										<Form.Group className="mb-3">
											<Form.Control type="text" id="first_name"
												required
												placeholder={t('register_placeHolder_firstName')}
												name="first_name"
												value={data.first_name}
												onChange={(e) => handleChanges(e)} />
											<Form.Control.Feedback type="invalid">
												{t('register_firstName_error')}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												type="text"
												id="last_name"
												aria-describedby="last_name" placeholder={t('register_placeHolder_lastName')}
												name="last_name"
												value={data.last_name}
												onChange={(e) => handleChanges(e)} />
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												as="select"
												aria-label="Default select example"
												name="gender"
												id="gender"
												placeholder={t('register_PlaceHolder_GenderLabel')}
												value={data.gender}
												onChange={(e) => handleChanges(e)} >
												<option value="">{t('register_PlaceHolder_GenderLabel')}</option>
												<option value="MALE">{t('register_dropdown_male')}</option>
												<option value="FEMALE">{t('register_dropdown_female')}</option>
											</Form.Control>
											<Form.Control.Feedback type="invalid">
												{t('register_gender_error')}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<Row>
									<Col md={12} lg={12} sm={12} xs={12}>
										<h4 className="form-heading">
											{t('register_Address_Information')}
										</h4>
									</Col>
									<Col md={6} lg={6} sm={12} xs={12}>
										<Form.Group className="mb-3">
											<Form.Control
												required
												as="select"
												aria-label="Default select example" name="country"
												id="country"
												placeholder={t('register_Country_drop')}
												value={data.country}
												onChange={(e) => handleChanges(e)} >
												<option value="">{t('register_Country_drop')}</option>
												{
													countriesList &&
														countriesList.length ? countriesList.map(item => {
															return (
																<option key={`country-${item.id}`} value={item.id}>{item.name}</option>
															)
														}) : " "
												}
											</Form.Control>
											<Form.Control.Feedback type="invalid">
												{t('register_country_error')}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												aria-label="Default select example"
												required={data.otherCity !== "" ? false : true}
												name="city"
												as="select"
												id="city"
												placeholder={t('register_City_drop')}
												value={data.city}
												onChange={(e) => handleChanges(e)} >
												<option value="">{t('register_City_drop')}</option>
												{
													cityList && cityList.length ? cityList.map(item => {
														return (
															<option key={`city-${item.id}`} value={item.id}>{item.name}</option>
														)
													}) : " "
												}
											</Form.Control>
											{loadingCity ? <Spinner size="sm" variant="danger" className="spinner-border spinner-position" /> : null}
											<Form.Control.Feedback type="invalid">
												{t('register_city_error')}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												disabled={data.city.length && data.city.length !== "" ? true : false}
												id="otherCity"
												name="otherCity"
												aria-describedby="otherCity" placeholder={t('register_Other_city')}
												value={data.otherCity}
												onChange={(e) => handleChanges(e)} />
										</Form.Group>
									</Col>
									<Col md={6} lg={6} sm={12} xs={12}>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												id="zipcode"
												aria-describedby="zipcode"
												placeholder={t('register_zip_code')}
												name="zipcode"
												value={data.zipcode}
												onChange={(e) => handleChanges(e)}
											/>
											<Form.Control.Feedback type="invalid">
												{t('register_zip_error')}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												id="Street"
												aria-describedby="street" placeholder={t('register_Street_Placeholder')}
												name="street"
												value={data.street}
												onChange={(e) => handleChanges(e)} />
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												id="house_no" aria-describedby="house_no" placeholder={t('register_HouseNumber_Placeholder')}
												name="house_no"
												value={data.house_no}
												onChange={(e) => handleChanges(e)} />
										</Form.Group>
									</Col>
								</Row>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<Row>
									<Col md={12} lg={12} sm={12} xs={12}>
										<h4 className="form-heading">
											{t('register_heading_contInfoLabel')}
										</h4>
									</Col>
									<Col md={6} lg={6} sm={12} xs={12}>
										<Form.Group className="mb-3">
											<Form.Control
												required
												type="text"
												id="mobile"
												aria-describedby="mobile" placeholder={t('register_mobile_lable')}
												name="mobile"
												value={data.mobile}
												onChange={(e) => handleChanges(e)} />
											<Form.Control.Feedback type="invalid">
												{t('register_mobile_error')}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												id="phone" aria-describedby="phone" placeholder={t('register_phone_label')}
												name="phone"
												value={data.phone}
												onChange={(e) => handleChanges(e)} />
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												required
												aria-label="Default select example"
												name="language_code"
												id="language_code"
												as="select"
												value={data.language_code}
												onChange={(e) => handleChanges(e)} >
												{
													LanguagesList &&
														LanguagesList.length ? LanguagesList.map(item => {
															return (
																<option key={`language-${item.id}`} value={item.language_code}>
																	{`${item.name} (${item.language_code})`}</option>
															)
														}) : " "
												}
											</Form.Control>
											<Form.Control.Feedback type="invalid">
												{t("language_required")}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6} lg={6} sm={12} xs={12}>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												required
												isInvalid={isCompanyError}
												id="company_name" aria-describedby="company_name" placeholder={t('register_placeHolder_companyName')}
												name="company_name"
												value={data.company_name}
												onChange={(e) => handleChanges(e)} />
											<Form.Control.Feedback type="invalid">
												{
													data.company_name === '' ? (
														t('company_name_required')
													) : isCompanyError ? (
														t('character_length')
													) : (
														null

													)
												}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												id="tax_number"
												aria-describedby="tax_number" placeholder={t('register_placeHolder_taxName')}
												name="tax_number"
												value={data.tax_number}
												onChange={(e) => handleChanges(e)} />
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control type="text"
												required
												id="website"
												aria-describedby="website" placeholder={t('register_website_label')}
												name="website"
												value={data.website}
												isInvalid={isWebsiteError}
												onChange={(e) => handleChanges(e)} />
											<Form.Control.Feedback type="invalid">
												{t('register_website_valid_error')}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
							</div>
						</div>
						<Row>
							<Col>
								<button type="submit" className="btn btn-danger btn-block">{t('register_regBtn_label')}</button>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
			{loading ? <Loader /> : <></>}
		</>
	)
}

export default Registration;